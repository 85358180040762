import React from "react";
import FormTextInput from "./FormTextInput";

const FormNumericInput = (props) => {

    const {
        id,
        label,
        onChange,
        value,
        required = false,
        disabled = false,
        maxValue = null,
        minValue = null,
    } = props;

    const handleChange = (e) => {
        const numbersOnly = e.target.value.replace(/[^0-9]/g, "");
        if (numbersOnly.length > 0) {
            let intval = parseInt(numbersOnly);
            if (maxValue && intval > maxValue) {
                intval = maxValue;
            }
            if (minValue && intval < minValue) {
                intval = minValue;
            }
            onChange({target: {id: id, value: intval}});
        }
        else {
            onChange({target: {id: id, value: null}});
        }
    }

    return (
        <FormTextInput
            id={id}
            label={label}
            required={required}
            disabled={disabled}
            onChange={onChange}
            value={value}
            type="number"
            inputProps={{inputProps: {min: minValue, max: maxValue}}}
            onBlur={handleChange}
        />
    );
}

export default FormNumericInput;
