import { axiosDelete, axiosGet, axiosPost, axiosPut } from "./instance";

export const apiTagList = (
    {
        pageNumber,
        pageSize,
        sortCol,
        sortDir,
        search
    },
    callback
) => {
    axiosGet(
        "tag/list",
        {
            page_number: pageNumber,
            page_size: pageSize,
            sort_col: sortCol,
            sort_dir: sortDir,
            search: search
        },
        callback
    );
}

export const apiTagCreate = ({ name, description }, callback) => {
    axiosPost("tag/create", { name, description }, callback);
}

export const apiTagGet = ({ id }, callback) => {
    axiosGet("tag/" + id, {}, callback);
}

export const apiTagUpdate = ({ id, name, description }, callback) => {
    axiosPut("tag/" + id, { name, description }, callback);
}

export const apiTagDelete = ({ id }, callback) => {
    axiosDelete("tag/" + id, callback);
}
