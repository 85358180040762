import React from "react";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import CancelButton from "../input/button/CancelButton";
import SubmitButton from "../input/button/SubmitButton";


const GenericDialog = (props) => {

    const {
        open,
        title,
        text,
        children,
        actionMethod,
        cancelLabel,
        submitLabel,
        onClose = undefined,
        fullScreen = false,
    } = props;

    return (
        <Dialog open={open} onClose={onClose} fullWidth fullScreen={fullScreen}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {text ? <DialogContentText>{text}</DialogContentText> : null}
                {children}
            </DialogContent>
            {
                actionMethod ? (
                    <DialogActions>
                        {cancelLabel ? <CancelButton onClick={(e) => {actionMethod("cancel")}}>{cancelLabel}</CancelButton> : null}
                        {submitLabel ? <SubmitButton onClick={(e) => {actionMethod("submit")}}>{submitLabel}</SubmitButton> : null}
                    </DialogActions>
                ) : null
            }
        </Dialog>
    );
}

export default GenericDialog;
