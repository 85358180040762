import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const NoAccountInfo = (props) => {

    const { hasAccounts, hasDefaultAccount, hasGroups } = props;
    const theme = useTheme();
    const { t } = useTranslation(["home"]);

    return (
        <Paper sx={{ margin: theme.spacing(4), padding: theme.spacing(2), textAlign: "center" }} elevation={0}>
            <Typography variant="h1">{t("Action Required")}</Typography>
            {
                hasAccounts ? null : (
                    <Box sx={{ marginTop: theme.spacing(2) }}>
                        <Typography variant="overline">
                            {t("Create an account by opening the menu, selecting Account and clicking on create button")}
                        </Typography>
                    </Box>
                )
            }
            {
                hasDefaultAccount ? null : (
                    <Box sx={{ marginTop: theme.spacing(2) }}>
                        <Typography variant="overline">
                            {t("Select an account as default by opening the menu, selecting Account and clicking on the corresponding action buttons")}
                        </Typography>
                    </Box>
                )
            }
            {
                hasGroups ? null : (
                    <Box sx={{ marginTop: theme.spacing(2) }}>
                        <Typography variant="overline">
                            {t("Create Groups and Products to start using the app")}
                        </Typography>
                    </Box>
                )
            }
        </Paper>
    )
}

export default NoAccountInfo;
