import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import { PieChart } from "recharts";
import { Cell } from "recharts";
import { Pie } from "recharts";
import { Tooltip } from "recharts";
import { ResponsiveContainer } from "recharts";
import { decodeSingleDimensionToPlural } from "../../helpers/api";

import { apiTotals } from "../../api/aggregations";
import { formatNumberAmount } from "../../helpers/format";
import { sequentialPallete } from "../../helpers/colors";


const PieWidget = (props) => {

    const { t } = useTranslation(["widget"]);

    const [widgetData, setWidgetData] = React.useState([]);

    const {filter, dimension, transactionType, items, height} = props;

    React.useEffect(() => {
        apiTotals({...filter}, (data, successFlag) => {
            if (successFlag > 0) {
                const dimensionData = data[dimension].map(element => {
                    return {
                        id: element[`${dimension}Id`],
                        name: element[`${dimension}Name`] ?? t("Undefined"),
                        value: element[transactionType] * (transactionType.toLowerCase() === "cre" ? 1 : -1),
                    }
                });
                const compareData = (a, b) => { return (a.value < b.value) ? 1 : ((a.value > b.value) ? -1 : 0); }
                dimensionData.sort(compareData);
                const topData = dimensionData.slice(0, items);
                const otherData = dimensionData.slice(items).reduce((a, b) => a + (b.value || 0), 0);
                if (otherData > 0) {
                    topData.push({id: "_OTHERS_", name: "Others", value: otherData});
                }
                setWidgetData(topData);
            }
        });
    }, [
        filter,
        dimension,
        transactionType,
        items,
    ]);

    return (
        <ResponsiveContainer width="100%" height={height}>
            <PieChart>
                <Pie data={widgetData} fill="#8884d8" dataKey="value">
                    {
                        widgetData.map((entry, index) => {
                            return (
                                <Cell key={`cell-${index}`} fill={sequentialPallete(index)} />
                            );
                        })
                    }
                </Pie>
                <Tooltip formatter={(value, name, props) => formatNumberAmount(value, undefined, undefined, undefined, undefined, undefined, false)} />
            </PieChart>
        </ResponsiveContainer>
    );
}

export default PieWidget;
