import React from "react";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";

const EditIconButton = (props) => {
    const { onClick, disabled = false } = props;
    return (
        <IconButton disabled={disabled} color="primary" onClick={onClick}>
            <EditIcon />
        </IconButton>
    )
}

export default EditIconButton;
