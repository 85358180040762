import { React } from "react";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";

export const TooltipCell = (props) => {
    return <Tooltip title={props.text ? props.text : ""} arrow enterTouchDelay={0}>
        <Box component="span" sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        }}>
            {props.text}
        </Box>
    </Tooltip>
};
