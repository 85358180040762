import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useTheme } from "@mui/material/styles";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import { apiWidgetList } from "../../api/widget";
import { apiReportGet, apiReportItemCreate, apiReportItemUpdate, apiReportItemDelete, apiReportItemMoveUp, apiReportItemMoveDown } from "../../api/report";

import ActionButtonMenuAdd from "../../components/input/button/ActionButtonMenuAdd";
import PageTitle from "../../components/typography/PageTitle";
import SectionTitle from "../../components/typography/SectionTitle";
import WidgetContainer from "../../components/widget/WidgetContainer";
import ReportItemFormDialog from "../../components/dialog/ReportItemFormDialog";
import { getIndexInArrayByAttr } from "../../helpers/utils";

const initState = {
    open: false,
    formTitle: null,
    formText: null,
    formType: null,
    id: null,
    title: null,
    layoutWidth: 4,
    layoutHeight: 500,
    widgetId: null,
}

const ReportItems = (props) => {

    const theme = useTheme();
    const { t } = useTranslation(["report"]);

    const { reportId, editMode = false, canUpdateAccount, isMobile } = props;
    
    const [reportState, setReportState] = React.useState(initState);
    const [widgets, setWidgets] = React.useState([]);
    const [name, setName] = React.useState(null);
    const [items, setItems] = React.useState([]);

    useEffect(() => {
        if (reportId) {
            apiWidgetList((widgetData, widgetSuccess) => {
                if (widgetSuccess > 0) {
                    setWidgets(widgetData.widgets);
                    apiReportGet({id: reportId}, (reportData, reportSuccess) => {
                        if (reportSuccess > 0) {
                            setName(reportData.report.name);
                            setItems(reportData.report.items ?? [])
                        }
                    })
                }
            });
        }
    }, [reportId]);

    const handleAdd = () => {
        setReportState({
            open: true,
            formTitle: "Add Item",
            formText: null,
            formType: "create",
            id: null,
            title: null,
            layoutWidth: 4,
            layoutHeight: 500,
            widgetId: null,
        });
    }

    const handleDelete = (item) => {
        setReportState({
            open: true,
            formTitle: "Delete Item",
            formText: t("Are you sure you want to remove this item?"),
            formType: "delete",
            id: item.id,
            title: item.title,
            layoutWidth: item.layoutWidth,
            layoutHeight: item.layoutHeight,
            widgetId: item.widgetId,
        });
    }

    const handleEdit = (item) => {
        setReportState({
            open: true,
            formTitle: "Update Item",
            formText: null,
            formType: "edit",
            id: item.id,
            title: item.title,
            layoutWidth: item.layoutWidth,
            layoutHeight: item.layoutHeight,
            widgetId: item.widgetId,
        });
    }

    const handleFormAction = (action, newState) => {
        if (action === "submit") {
            if (reportState.formType === "create") {
                apiReportItemCreate(
                    {
                        title: newState.title,
                        reportPageId: reportId,
                        layoutHeight: newState.layoutHeight,
                        layoutWidth: newState.layoutWidth,
                        widgetId: newState.widgetId,
                    },
                    (data, success) => {
                        if (success > 0) {
                            toast.info(t("Item added successfuly"));
                            const newItems = items.slice();
                            newItems.push(data.reportItem);
                            setItems(newItems);
                        }
                    }
                );
            }
            if (reportState.formType === "edit") {
                apiReportItemUpdate(
                    {
                        id: reportState.id,
                        title: newState.title,
                        layoutHeight: newState.layoutHeight,
                        layoutWidth: newState.layoutWidth,
                        widgetId: newState.widgetId,
                    },
                    (data, success) => {
                        if (success > 0) {
                            toast.info(t("Item updated successfuly"));
                            const updatedItems = items.map(element => {
                                if (element.id === reportState.id) {
                                    return data.reportItem;
                                }
                                return element;
                            });
                            setItems(updatedItems);
                        }
                    }
                );
            }
            if (reportState.formType === "delete") {
                apiReportItemDelete({id: reportState.id}, (data, success) => {
                    if (success > 0) {
                        toast.info(t("Item deleted successfuly"));
                        const deletedItems = items.filter(element => element.id !== reportState.id);
                        setItems(deletedItems);
                    }
                });
            }
        }

        setReportState(initState);
    }

    const handleChangeOrder = (id, incr) => {
        const method = incr > 0 ? apiReportItemMoveDown : (incr < 0 ? apiReportItemMoveUp : null);
        if (method) {
            method({ id }, (data, success) => {
                if (success) {
                    const idxToMove = getIndexInArrayByAttr(items, "id", id);
                    let newRows = items.slice();
                    newRows[idxToMove].order = newRows[idxToMove].order + incr;
                    newRows[idxToMove + incr].order = newRows[idxToMove + incr].order - incr;
                    newRows.sort((a, b) => (a.order - b.order));
                    setItems(newRows);
                }
            });
        }
    }

    return (
        <Box margin={theme.spacing(1)}>
            <Box sx={{display: "flex"}}>
                <Box sx={{flexGrow: 1}}><PageTitle text={name} /></Box>
                {editMode ? <Box><ActionButtonMenuAdd onClick={handleAdd} /></Box> : null}
            </Box>
            <Grid container marginTop={theme.spacing(1)} spacing={theme.spacing(2)}>
                {
                    items.map(element => {
                        const canMoveUp = canUpdateAccount && element.order > 1;
                        const canMoveDown = canUpdateAccount && element.order < items.length;
                        return (
                            <Grid item key={element.id} xs={element.layoutWidth}>
                                <Card>
                                    <CardHeader title={<SectionTitle text={element.title} />} />
                                    <CardContent>
                                        <WidgetContainer type={element.widgetType} height={element.layoutHeight}
                                            primaryFilter={element.primaryFilter.data}
                                            secondaryFilter={element.secondaryFilter.data}
                                            params={element.widgetParams}  />
                                    </CardContent>
                                    {
                                        editMode ? (
                                            <CardActions disableSpacing={true}>
                                                <Grid container justifyContent="space-between" alignItems="center">
                                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                                        <IconButton disabled={!canMoveUp} onClick={() => handleChangeOrder(element.id, -1)}>{isMobile ? <ArrowUpwardIcon /> : <WestIcon />}</IconButton>
                                                </Box>
                                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                                    <IconButton disabled={!canUpdateAccount} onClick={() => handleDelete(element)}><DeleteIcon /></IconButton>
                                                    <IconButton disabled={!canUpdateAccount} onClick={() => handleEdit(element)} sx={{marginX: theme.spacing(2)}}><EditIcon /></IconButton>
                                                </Box>
                                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                                    <IconButton disabled={!canMoveDown} onClick={() => handleChangeOrder(element.id, 1)}>{isMobile ? <ArrowDownwardIcon /> : <EastIcon />}</IconButton>
                                                </Box>
                                            </Grid>
                                        </CardActions>
                                        ) : null
                                    }
                                </Card>
                            </Grid>
                        )
                    })
                }
            </Grid>
            <ReportItemFormDialog {...reportState} actionMethod={handleFormAction} widgets={widgets} />
        </Box>
    )
}

const mapStateToProps = (state) => {
    return {
        isMobile: state.appSettings.isMobile,
        canUpdateAccount: state.user.canUpdateAccount,
    }
}

export default connect(mapStateToProps)(ReportItems);
