import React from "react";
import { usePromiseTracker } from "react-promise-tracker";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";


const Loading = (props) => {
    const { promiseInProgress } = usePromiseTracker();
    return (
        promiseInProgress
        ? (
            <Backdrop sx={{
                zIndex: 999, // theme.zIndex.drawer + 1,
                color: "#fff",
            }} open={true}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )
        : null
    );
}

export default Loading;
