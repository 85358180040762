import { USER_AUTH_INFO, USER_DEFAULT_ACCOUNT, USER_UPDATE } from "./types";
import { setUserDefaultAccountService } from "../../service/user"

export const setUserAuthInfo = ({ userAuth, userData }, callback) => {
    return (dispatch, getState) => {
        dispatch({ type: USER_AUTH_INFO, payload: { userAuth, userData } });
        callback();
    }
};

export const setUserDefaultAccount = ({ defaultAccountId }, callback) => {
    return (dispatch, getState) => {
        setUserDefaultAccountService({ defaultAccountId }, (data, successFlag) => {
            if (successFlag > 0) {
                dispatch({ type: USER_DEFAULT_ACCOUNT, payload: data });
            }
            callback(data, successFlag);
        });
    }
};

export const updateUserStore = ({ displayName }, callback) => {
    return (dispatch, getState) => {
        dispatch({ type: USER_UPDATE, payload: { displayName }});
        callback();
    }
}