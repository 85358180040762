import React from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { translateApiErrorType } from "../../helpers/error";

const ErrorMessageToast = (props) => {

    const { errorType, fieldName, translationPath } = props;
    const transactionList = translationPath ? ["errors", translationPath] : ["errors"];
    const { t } = useTranslation(transactionList);

    const errorMessage = translateApiErrorType(errorType);
    const errorField = fieldName ? " - " + t(fieldName, { ns: translationPath ?? "errors" }) : "";
    return <Box component="span">{t(errorMessage)}{errorField}</Box>
}

export default ErrorMessageToast;
