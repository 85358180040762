import React from "react";
import DimensionChip from "./DimensionChip";

const AmountTypeChip = (props) => {

    const { label } = props;

    return (
        <DimensionChip
            variant="outlined"
            chipColor="info"
            label={label}
        />
    );
}

export default AmountTypeChip;
