import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { apiTagGet } from "../../api/tag";
import { updateTag } from "../../store/actions/tag";
import DimensionPage from "../../layout/DimensionPage";

const TagView = (props) => {
    const { t } = useTranslation(["dimension"]);
    const { updateMethod } = props;
    return (
        <DimensionPage dimension="tag" title={t("Tag")} instanceId={props.match.params.id}
            getMethod={apiTagGet} dataKey="tags" updateMethod={updateMethod}
            spec={
                [
                    {id: "name", required: true, label: t("Name"), type: "text", value: null},
                    {id: "description", required: false, label: t("Description"), type: "area", value: null},
                ]
            }
        />
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateMethod: ({ id, name, description }, callback) => dispatch(updateTag({ id, name, description }, callback)),
    }
}

export default connect(null, mapDispatchToProps)(TagView);
