import React from "react";
import FormTextInput from "./FormTextInput";
import { formatNumberAmount } from "../../../helpers/format";

const FormAmountInput = (props) => {

    const {
        id,
        label,
        onChange,
        value,
        required = false,
        disabled = false,
    } = props;

    const handleChange = (e) => {
        const numbersOnly = e.target.value.replace(/[^0-9]/g, "");
        if (numbersOnly.length > 0) {
            const leadingZeros = numbersOnly.length === 1 ? `00${numbersOnly}` : (
                numbersOnly.length === 2 ? `0${numbersOnly}` : numbersOnly
            );
            const intPart = parseInt(leadingZeros.substring(0, leadingZeros.length - 2));
            const decPart = leadingZeros.substring(leadingZeros.length - 2);
            const floatVal = parseFloat(`${intPart}.${decPart}`);
            const formatted = formatNumberAmount(floatVal, "", undefined, undefined, undefined, undefined, false);
            onChange({target: {id: id, value: formatted}});
        }
        else {
            onChange({target: {id: id, value: null}});
        }
    }

    return (
        <FormTextInput
            id={id}
            label={label}
            required={required}
            disabled={disabled}
            onChange={handleChange}
            value={value}
            type="text"
        />
    );
}

export default FormAmountInput;
