export const APP_RESIZE = "APP_RESIZE";

export const USER_AUTH_INFO = "USER_AUTH_INFO";
export const USER_DEFAULT_ACCOUNT = "USER_DEFAULT_ACCOUNT";
export const USER_UPDATE = "USER_UPDATE";

export const ACCOUNT_CREATE = "ACCOUNT_CREATE";
export const ACCOUNT_UPDATE = "ACCOUNT_UPDATE";
export const ACCOUNT_DELETE = "ACCOUNT_DELETE";

export const DIM_CREATE = "DIM_CREATE";
export const DIM_UPDATE = "DIM_UPDATE";
export const DIM_DELETE = "DIM_DELETE";
export const DIM_MOVE = "DIM_MOVE";
