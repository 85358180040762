import { cyan } from "@mui/material/colors";
import { teal } from "@mui/material/colors";
import { indigo } from "@mui/material/colors";
import { purple } from "@mui/material/colors";
import { lime } from "@mui/material/colors";
import { pink } from "@mui/material/colors";
import { brown } from "@mui/material/colors";

export const CHART_MAIN_COLORS = ["#0061ff", "#0c73ff", "#1885ff", "#2497ff", "#30a8ff", "#48ccff", "#60efff"];
export const CHART_SECONDARY_COLORS = ["#ff930f", "#ffad22", "#ffb125", "#ffb427", "#ffba2c", "#ffc635", "#fff95b"];
export const CHART_MAIN_COLORS_ADDITIONAL = ["#809b0e", "#8aa126", "#8fa431", "#9aab49", "#9fae55", "#a4b160", "#aeb778"];
export const CHART_SECONDARY_COLORS_ADDITIONAL = ["#5e3719", "#694529", "#735238", "#886e58", "#9d8977", "#a89787", "#b2a496"];

const SEQUENTIAL_PALETTE = [
    "#F94144",
    "#F3722C",
    "#F8961E",
    "#F9844A",
    "#F9C74F",
    "#90BE6D",
    "#43AA8B",
    "#4D908E",
    "#577590",
    "#277DA1",
];

export const sequentialPallete = (idx, secondary = false) => {
    const main = SEQUENTIAL_PALETTE[idx % SEQUENTIAL_PALETTE.length];
    if (secondary) {
        return `${main}AF`;
    }
    return main;
}

export class GroupChipColor {
    static regularColor = brown[500];
    static onHoverColor = brown[700];
    static textColor = "#ffffff";
}

export class ProductChipColor {
    static regularColor = cyan[500];
    static onHoverColor = cyan[300];
    static textColor = "#000000";
}

export class CategoryChipColor {
    static regularColor = teal[500];
    static onHoverColor = teal[700];
    static textColor = "#ffffff";
}

export class ItemChipColor {
    static regularColor = purple[500];
    static onHoverColor = purple[700];
    static textColor = "#ffffff";
}

export class EntityChipColor {
    static regularColor = lime[500];
    static onHoverColor = lime[300];
    static textColor = "#000000";
}

export class TagChipColor {
    static regularColor = pink[500];
    static onHoverColor = pink[700];
    static textColor = "#ffffff";
}

export class FileChipColor {
    static regularColor = indigo[500];
    static onHoverColor = indigo[700];
    static textColor = "#ffffff";
}
