import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { apiTotals } from "../../api/aggregations";
import AmountCardItem from "./../card/AmountCardItem";
import AmountCardHeader from "./../card/AmountCardHeader";
import { Grid } from "@mui/material";

const AmountCardCollection = (props) => {

    const theme = useTheme();

    // settings to show on the list
    const { filter, dimension, parcel, labelTotal, columns = null, sortByValue = false, height = null } = props;

    const [rows, setRows] = React.useState([]);
    const [total, setTotal] = React.useState(0);

    const gridWidth = columns === 2 ? 6 : (columns === 3 ? 4 : (columns === 4 ? 3 : 12))

    React.useEffect(() => {
        apiTotals({
            ...filter,
            dimension,
        }, (data, successFlag) => {
            if (successFlag > 0) {

                const mapDimensionField = {
                    group: "productGroup",
                    product: "product",
                    category: "category",
                    item: "item",
                    entity: "entity",
                    tag: "tag",
                }
                const idField = `${mapDimensionField[dimension]}Id`;
                const nameField = `${mapDimensionField[dimension]}Name`;

                let resData = data[dimension].map(element => {
                    return {
                        id: element[idField],
                        name: element[nameField],
                        value: element[parcel],
                    }
                });

                const compareData = (a, b) => {
                    if (sortByValue) {
                        if (a.value > b.value) return -1;
                        if (a.value < b.value) return 1;
                    }
                    return (a.name > b.name) ? 1 : ((a.name < b.name) ? -1 : 0);
                }
            
                resData.sort(compareData);
                resData = resData.filter(element => Math.round(element.value * 100) !== 0);
                setRows(resData);

                const total = resData.map(element => element.value).reduce((a, b) => a + b, 0);
                setTotal(total);
            }
        });
    }, [
        filter,
        sortByValue,
        dimension,
        parcel,
        labelTotal,
    ]);

    const sxHeight = height ? {height: height, overflow: "auto", padding: theme.spacing(1)} : null;

    return (
        <Box sx={sxHeight}>
            {
                labelTotal ? (
                    <Box sx={{marginBottom: theme.spacing(1)}}>
                        <AmountCardHeader label={labelTotal} value={total} />
                    </Box>
                ) : null
            }
            <Grid container spacing={theme.spacing(1)}>
            {
                rows.map(element => {
                    return (
                        <Grid item xs={gridWidth} key={element.id}>
                            <AmountCardItem
                                key={element.id}
                                href={`/${dimension}/view/${element.id}`}
                                label={element.name}
                                value={element.value} />
                        </Grid>
                    )
                })
            }
            </Grid>
        </Box>
    );
}

export default AmountCardCollection;
