import React from "react";
import Typography from "@mui/material/Typography";

const PageTitle = (props) => {
    return (
        <Typography component="h1" variant="h1">{props.text}</Typography>
    );
}

export default PageTitle;
