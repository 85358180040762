import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";

import { apiReportCreate, apiReportUpdate, apiReportDelete, apiReportMoveUp, apiReportMoveDown, apiReportList } from "../../api/report";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import ActionButtonMenuAdd from "../../components/input/button/ActionButtonMenuAdd";
import PageTitle from "../../components/typography/PageTitle";
import { getIndexInArrayByAttr } from "../../helpers/utils";
import DimensionFormDialog from "../../components/dialog/DimensionFormDialog";
import ErrorMessageToast from "../../components/layout/ErrorMessageToast";
import ReportLayoutCard from "../../components/card/ReportLayoutCard";

const ReportList = (props) => {

    const theme = useTheme();
    const { t } = useTranslation(["report"]);

    const [rows, setRows] = React.useState([]);

    const [formReport, setFormReport] = React.useState({
        open: false,
        type: null,
        id: null,
        name: "",
    });

    const fetchReports = () => {
        apiReportList((data, successFlag) => {
            if (successFlag > 0) {
                setRows(data.reports.map(element => {
                    return {
                        id: element.id,
                        name: element.name,
                        order: element.order,
                        numWidgets: element.items ? element.items.length : 0,
                    }
                }));
            }
        });
    };

    const handleAdd = () => {
        setFormReport({
            open: true,
            type: "create",
            id: null,
            name: "",
        });
    }

    const handleEdit = (id, name) => {
        setFormReport({
            open: true,
            type: "edit",
            id: id,
            name: name,
        });
    }

    const handleDelete = (id, name) => {
        setFormReport({
            open: true,
            type: "delete",
            id: id,
            name: name,
        });
    }

    const handleErrors = (data, successFlag) => {
        if (successFlag === 0) {
            data.forEach(element => {
                toast.error(<ErrorMessageToast errorType={element.errorType} fieldName={element.fieldName} />);
            });
        }
        else {
            toast.error(data);
        }
    }

    const handleFormDialog = (action, newState) => {
        if (action === "submit") {
            if (formReport.type === "create") {
                apiReportCreate(
                    {name: newState.name},
                    (data, successFlag) => {
                        if (successFlag > 0) {
                            toast.success(t("Report updated successfuly"));
                            const createdReports = rows.slice();
                            createdReports.push({
                                id: data.report.id,
                                name: data.report.name,
                                order: data.report.order,
                                numWidgets: 0,
                            });
                            setRows(createdReports);
                        }
                        else {
                            handleErrors(data, successFlag);
                        }
                    }
                );
            }
            
            if (formReport.type === "edit") {
                apiReportUpdate(
                    {id: formReport.id, name: newState.name},
                    (data, successFlag) => {
                        if (successFlag > 0) {
                            toast.success(t("Report created successfuly"));
                            const updatedReports = rows.map(element => {
                                if (element.id === newState.id) {
                                    return {
                                        ...element,
                                        name: newState.name,
                                    }
                                }
                                return element;
                            });
                            setRows(updatedReports);
                        }
                        else {
                            handleErrors(data, successFlag);
                        }
                    }
                );
            }

            if (formReport.type === "delete") {
                apiReportDelete(
                    {id: formReport.id},
                    (data, success) => {
                        if (success > 0) {
                            const deletedReport = rows.filter(element => element.id !== formReport.id);
                            setRows(deletedReport);
                        }
                        else {
                            handleErrors(data, success);
                        }
                    }
                );
            }
        }
        
        setFormReport({
            open: false,
            type: null,
            id: null,
            name: "",
        });
    }

    const handleChangeOrder = ({ id, incr }) => {
        const method = incr > 0 ? apiReportMoveDown : (incr < 0 ? apiReportMoveUp : null);
        if (method) {
            method({ id }, (data, success) => {
                if (success) {
                    const idxToMove = getIndexInArrayByAttr(rows, "id", id);
                    let newRows = rows.slice();
                    newRows[idxToMove].order = newRows[idxToMove].order + incr;
                    newRows[idxToMove + incr].order = newRows[idxToMove + incr].order - incr;
                    newRows.sort((a, b) => (a.order - b.order));
                    setRows(newRows);
                }
            });
        }
    }

    useEffect(() => {
        fetchReports();
    }, []);

    return (
        <Box margin={theme.spacing(1)}>
            <Box sx={{display: "flex"}}>
                <Box sx={{flexGrow: 1}}><PageTitle text={t("Reports")} /></Box>
                <Box><ActionButtonMenuAdd onClick={handleAdd} /></Box>
            </Box>
            <Box sx={{marginTop: theme.spacing(1)}}>
                <Grid container spacing={theme.spacing(1)}>
                    {
                        rows.map(element => {
                            return (
                                <Grid key={element.id} item xs={12} sm={3}>
                                    <ReportLayoutCard
                                        id={element.id}
                                        name={element.name}
                                        order={element.order}
                                        numWidgets={element.numWidgets}
                                        handleEdit={() => handleEdit(element.id, element.name)}
                                        handleDelete={() => handleDelete(element.id, element.name)}
                                        handleUp={element.order > 1 ? () => handleChangeOrder({id: element.id, incr: -1}) : null}
                                        handleDown={element.order < rows.length ? () => handleChangeOrder({id: element.id, incr: 1}) : null} />
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Box>
            <DimensionFormDialog
                open={formReport.open}
                title={formReport.id ? t("Update") : t("Create")}
                type={formReport.type}
                spec={[{id: "name", required: true, label: t("Name"), type: "text", value: formReport.name}]}
                actionMethod={handleFormDialog}
                fullScreen={false} />
        </Box>
    )
}

export default ReportList;
