import { axiosDelete, axiosGet, axiosPost, axiosPut } from "./service";

export const getAccountsService = ({ pageNumber, pageSize, sortCol, sortDir, search }, callback) => {
    axiosGet("account/list", { page_number: pageNumber, page_size: pageSize, sort_col: sortCol, sort_dir: sortDir, search: search }, callback);
}

export const createAccountService = ({ name, description }, callback) => {
    axiosPost("account/create", { name, description }, callback);
}

export const getAccountService = ({ id }, callback) => {
    axiosGet("account/" + id, {}, callback);
}

export const updateAccountService = ({ id, name, description }, callback) => {
    axiosPut("account/" + id, { name, description }, callback);
}

export const deleteAccountService = ({ id }, callback) => {
    axiosDelete("account/" + id, callback);
}

export const addMemberService = ({ id, email, isAdmin, canUpdate }, callback) => {
    axiosPost("account/" + id + "/add_member", { email, is_admin: isAdmin, can_update: canUpdate}, callback);
}

export const updateMemberService = ({ memberId, isAdmin, canUpdate }, callback) => {
    axiosPut("account/member/" + memberId, { is_admin: isAdmin, can_update: canUpdate}, callback);
}

export const deleteMemberService = ({ memberId }, callback) => {
    axiosDelete("account/member/" + memberId, callback);
}
