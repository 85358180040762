import React from "react";
import { faUniversity } from "@fortawesome/free-solid-svg-icons";
import DimensionChip from "./DimensionChip";
import { EntityChipColor } from "../../helpers/colors";

const EntityChip = (props) => {

    const { label, entityId } = props;

    return (
        <DimensionChip
            faIcon={faUniversity}
            textColor={EntityChipColor.textColor}
            regularColor={EntityChipColor.regularColor}
            hoverColor={EntityChipColor.onHoverColor}
            label={label}
            href={`/entity/view/${entityId}`}
        />
    );
}

export default EntityChip;
