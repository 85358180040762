// Button component
import React from "react";
import { Button as MuiButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const Button = (props) => {

    const theme = useTheme();

    const {
        variant = "outlined",
        color = "primary",
        size = "medium",
        type = "button",
        marginTop = 0,
        marginLeft = 0,
        marginBottom = 0,
        marginRight = 0,
        fullWidth = false,
        fullHeight = false,
        startIcon = null,
        endIcon = null,
        disabled = false,
        onClick,
        children,
    } = props;

    return (
        <MuiButton
            type={type}
            size={size}
            variant={variant}
            color={color}
            onClick={onClick}
            sx={{
                marginTop: theme.spacing(marginTop),
                marginLeft: theme.spacing(marginLeft),
                marginBottom: theme.spacing(marginBottom),
                marginRight: theme.spacing(marginRight),
                height: fullHeight ? "100%" : undefined,
            }}
            fullWidth={fullWidth}
            startIcon={startIcon}
            endIcon={endIcon}
            disabled={disabled}
        >
            {children}
        </MuiButton>
    )
}

export default Button;
