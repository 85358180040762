import React from "react";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";

import { formatNumberAmount } from "../../helpers/format";

const AmountCardItem = (props) => {
    const { label, href, value } = props;
    const theme = useTheme();
    const { t } = useTranslation(["dimension"]);

    return (
        <Link href={href} underline="none">
            <Paper sx={{
                padding: theme.spacing(1),
                ":hover": {
                    backgroundColor: theme.palette.grey[200],
                }
            }}>
                <Typography sx={{fontWeight: 800, fontSize: "110%"}}>{label ?? t("Undefined")}</Typography>
                <Typography sx={{fontWeight: 600, color: theme.palette.grey[700], fontSize: "110%"}}>{formatNumberAmount(value)}</Typography>
            </Paper>
        </Link>
    )
}

export default AmountCardItem;
