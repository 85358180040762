import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import moment from "moment";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import PageTitle from "../components/typography/PageTitle";
import PageMainItem from "../components/typography/PageMainItem";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";

import PageSecondaryItem from "../components/typography/PageSecondaryItem";
import TotalsCard from "../components/card/TotalsCard";
import SectionTitle from "../components/typography/SectionTitle";
import TransactionCollection from "../components/collection/TransactionCollection";

const DimensionTopLayout = (props) => {
    
    const theme = useTheme();
    const { t } = useTranslation(["dimension"]);

    const { isMobile, canUpdateAccount } = props;
    const { title, name, description, onEditClick, transactionFilter, secondaryElement = null } = props;

    const totalsMainFilter = {...transactionFilter};
    const totalsSecondaryFilter = {...totalsMainFilter, endDate: moment().add(-1, "month").format("YYYY-MM-DD")};
    const totalsParcels = [
        {key: "bal", label: t("Balance"), isCurrency: true},
        {key: "cap", label: t("Capital"), isCurrency: true},
        {key: "int", label: t("Interests"), isCurrency: true},
        {key: "fee", label: t("Fees and Commissions"), isCurrency: true},
        {key: "upd", label: t("Updates"), isCurrency: true},
        {key: "cnt", label: t("# Transactions"), isCurrency: false},
    ];

    return (
        <Box sx={{margin: theme.spacing(1)}}>
            <PageTitle text={title} />
            <Paper sx={{padding: theme.spacing(1), marginTop: theme.spacing(1)}}>
                <Box>
                    <Grid container>
                        <Grid item xs>
                            <PageMainItem text={name} />
                        </Grid>
                        <Grid item xs={1} textAlign="right">
                            <IconButton onClick={onEditClick} disabled={!canUpdateAccount}>
                                <EditIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <PageSecondaryItem text={description} />
                </Box>
                {
                    secondaryElement ? <Box sx={{marginTop: theme.spacing(1)}}>{secondaryElement}</Box> : null
                }
            </Paper>
            <Box sx={{marginTop: theme.spacing(1)}}>
                <TotalsCard parcels={totalsParcels} mainFilter={totalsMainFilter}
                    secondaryFilter={totalsSecondaryFilter} itemSize={isMobile ? 12 : 4} />
            </Box>
            <Paper sx={{padding: theme.spacing(1), marginTop: theme.spacing(1)}}>
                <SectionTitle text={t("Transactions")} />
                <Box sx={{marginTop: theme.spacing(1)}}>
                    <TransactionCollection {...transactionFilter} />
                </Box>
            </Paper>
        </Box>
    )
}

const mapStateToProps = (state) => {
    return {
        isMobile: state.appSettings.isMobile,
        canUpdateAccount: state.user.canUpdateAccount,
    }
}

export default connect(mapStateToProps)(DimensionTopLayout);
