import { combineReducers } from "redux";

import appSettingsReducer from "./app";
import userReducer from "./user";

const rootReducer = combineReducers({
    appSettings: appSettingsReducer,
    user: userReducer,
});

export default rootReducer;
