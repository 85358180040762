import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { createItem, updateItem, deleteItem } from "../../store/actions/item";
import { apiItemList } from "../../api/item";
import DimensionCollection from "../../components/collection/DimensionCollection";


const ItemList = (props) => {

    const { t } = useTranslation(["dim"]);
    const { createMethod, updateMethod, deleteMethod } = props;

    return (
        <DimensionCollection
            createMethod={createMethod}
            updateMethod={updateMethod}
            deleteMethod={deleteMethod}
            listMethod={apiItemList}
            dimensionTitle={t("Item")}
            listResultAttribute="items"
            urlMainPath="item"
            spec={
                [
                    {id: "name", required: true, label: t("Name"), type: "text"},
                    {id: "description", required: false, label: t("Description"), type: "area"}
                ]
            } />
    );
}

const mapStateToProps = (state) => {
    return {
        isMobile: state.appSettings.isMobile,
        canUpdateAccount: state.user.canUpdateAccount,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createMethod: ({ name, description }, callback) => dispatch(createItem({ name, description }, callback)),
        updateMethod: ({ id, name, description }, callback) => dispatch(updateItem({ id, name, description }, callback)),
        deleteMethod: ({ id }, callback) => dispatch(deleteItem({ id }, callback)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemList);
