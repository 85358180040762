import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";

import Box from "@mui/material/Box";
import TransactionCollection from "../../components/collection/TransactionCollection";
import PageMainItem from "../../components/typography/PageMainItem";
import PageTitle from "../../components/typography/PageTitle";
import ActionButtonMenuFilter from "../../components/input/button/ActionButtonMenuFilter";
import ActionButtonMenuFilterOptions from "../../components/input/button/ActionButtonMenuFilterOptions";
import FilterFormDialog from "../../components/dialog/FilterFormDialog";
import DimensionFormDialog from "../../components/dialog/DimensionFormDialog";
import { apiFilterCreate, apiFilterDelete, apiFilterList, apiFilterUpdate } from "../../api/filter";
import { toast } from "react-toastify";
import ListDialog from "../../components/dialog/ListDialog";
import DeleteDialog from "../../components/dialog/DeleteDialog";

const initState = {
    startDate: null,
    endDate: null,
    amountMin: undefined,
    amountMax: undefined,
    amountTypes: [],
    transactionTypes: [],
    groups: [],
    products: [],
    categories: [],
    items: [],
    entities: [],
    tags: [],
    productTypes: [],
    dateRange: "",
}

const Search = () => {

    const theme = useTheme();
    const { t } = useTranslation(["search", "amount_type", "transaction_type"]);

    const [filterId, setFilterId] = useState(null);
    const [filterName, setFilterName] = useState(null);
    const [transactionFilter, setTransactionFilter] = useState(initState);

    const [filters, setFilters] = useState([]);

    const [filterFormDialogOpen, setFilterFormDialogOpen] = useState(false);
    const [nameFormOpen, setNameFormOpen] = useState(false);
    const [filterListOpen, setFilterListOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);

    const onFilterClick = () => {
        setFilterFormDialogOpen(true);
    }

    const onOptionClick = (action) => {
        
        if (action === "new") {
            setFilterId(null);
            setFilterName(null);
            setTransactionFilter(initState);
        }

        if (action === "save") {
            setNameFormOpen(true);
        }

        if (action === "open") {
            setFilterListOpen(true);
        }

        if (action === "copy") {
            if (filterId) {
                setFilterId(null);
                const newName = `${t("COPY OF")} ${filterName}`;
                setFilterName(newName);
                toast.info(t("Filter copied but not saved"))
            }
            else {
                toast.warning(t("Please, open a filter first"));
            }
        }

        if (action === "delete") {
            setDeleteOpen(true);
        }
    }

    const handleDelete = (action) => {
        if (action === "submit") {
            if (filterId) {
                apiFilterDelete({id: filterId}, (data, success) => {
                    if (success > 0) {
                        const deletedFilter = filters.filter(element => element.id !== filterId);
                        setFilters(deletedFilter);
                        setFilterId(null);
                        setFilterName(null);
                        setTransactionFilter(initState);
                    }
                })
            }
            else {
                toast.warning(t("Please, open a filter first"));
            }
        }

        setDeleteOpen(false);
    }

    const onFilterSubmit = (action, newState) => {

        if (action === "submit") {
            setTransactionFilter(newState);
        }

        setFilterFormDialogOpen(false);
    }

    const onListSubmit = (filter) => {
        if (filter) {
            setFilterId(filter.id);
            setFilterName(filter.name);
            setTransactionFilter(filter.data);
        }
        setFilterListOpen(false);
    }

    const onEditSubmit = (action, newState) => {

        if (action === "submit") {
            if (filterId) {
                apiFilterUpdate(
                    {id: filterId, name: newState.name, ...transactionFilter},
                    (data, success) => {
                        if (success > 0) {
                            toast.success(t("Filter updated successfully"));
                            setFilterName(newState.name);
                            let updatedFilter = filters.map(element => {
                                if (element.id === filterId) {
                                    return {
                                        id: filterId,
                                        name: newState.name,
                                        data: transactionFilter,
                                    }
                                }
                                return element;
                            })
                            updatedFilter.sort((a, b) => (a.name > b.name) ? 1 : ((a.name < b.name) ? -1 : 0));
                            setFilters(updatedFilter);
                        }
                    }
                )
            }
            else {
                apiFilterCreate(
                    {name: newState.name, ...transactionFilter},
                    (data, success) => {
                        if (success > 0) {
                            toast.success(t("Filter created successfully"));
                            setFilterId(data.filter.id);
                            setFilterName(newState.name);
                            let createdFilter = filters.slice();
                            createdFilter.push(data.filter);
                            createdFilter.sort((a, b) => (a.name > b.name) ? 1 : ((a.name < b.name) ? -1 : 0));
                            setFilters(createdFilter);
                        }
                    }
                )
            }
        }

        setNameFormOpen(false);
    }

    useEffect(() => {
        apiFilterList((data, success) => {
            if (success > 0) {
                setFilters(data.filters);
            }
        })
    }, []);

    return (
        <React.Fragment>
            <Box sx={{margin: theme.spacing(1), padding: theme.spacing(1)}}>
                <Box sx={{display: "flex"}}>
                    <Box sx={{flexGrow: 1}}><PageTitle text={t("Search")} /></Box>
                    <Box>
                        <ActionButtonMenuFilter onClick={onFilterClick} />
                        <ActionButtonMenuFilterOptions handleMenuClick={onOptionClick} />
                    </Box>
                </Box>
                { filterName ? <Box sx={{marginTop: theme.spacing(1)}}><PageMainItem text={filterName} /></Box> : null }
                <Box sx={{marginTop: theme.spacing(1)}}>
                    <TransactionCollection {...transactionFilter} />
                </Box>
            </Box>
            <FilterFormDialog
                open={filterFormDialogOpen}
                editState={transactionFilter}
                actionMethod={onFilterSubmit} />
            <DimensionFormDialog
                open={nameFormOpen}
                title={filterId ? t("Update") : t("Create")}
                type="edit"
                spec={[{id: "name", required: true, label: t("Name"), type: "text", value: filterName}]}
                actionMethod={onEditSubmit}
                fullScreen={false} />
            <ListDialog
                open={filterListOpen}
                title={t("Filters")}
                text={t("Select a filter to open")}
                attrName={"name"}
                selected={filterId}
                items={filters}
                onClose={onListSubmit} />
            <DeleteDialog
                open={deleteOpen}
                actionMethod={handleDelete}
                text={t("Are you sure you want to delete this filter?")} />
        </React.Fragment>
    )
}

export default Search;
