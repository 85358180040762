import { styled } from "@mui/material/styles";
import Box from "@mui/material//Box";
import Avatar from "@mui/material/Avatar";

const AnonymousUserBox = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
}));

const UserAvatar = styled(Avatar)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.main,
    margin: theme.spacing(1),
}));

export { AnonymousUserBox, UserAvatar };
