import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";

const ActionButtonMenuAdd = (props) => {

    const history = useHistory();
    const { t } = useTranslation(["collection"]);

    const { isMobile, href, onClick } = props;

    const handleClick = () => {
        if (href) {
            history.push(href);
        }
        else {
            onClick();
        }
    }

    const buttonCommonParams = {
        onClick: handleClick,
    }

    const icon = <AddIcon />;

    return (
        isMobile ? <IconButton {...buttonCommonParams}>{icon}</IconButton> : (
            <Button startIcon={icon} {...buttonCommonParams}>{t("Add")}</Button>
        )
    );
}

const mapStateToProps = (state) => {
    return {
        isMobile: state.appSettings.isMobile,
    }
}

export default connect(mapStateToProps)(ActionButtonMenuAdd);
