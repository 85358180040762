import React from "react";
import Typography from "@mui/material/Typography";
import { formatNumberAmount } from "../../helpers/format";

const ParcelCardItem = (props) => {
    const {
        label,
        amount,
        labelVariant = "small-caps",
        fontSize = "120%",
        fontWeight = 800,
    } = props;

    return (
        <React.Fragment>
            <Typography sx={{fontVariant: labelVariant, fontSize: fontSize, fontWeight: fontWeight}}>{label}</Typography>
            <Typography sx={{ fontSize: fontSize, fontWeight: fontWeight }}>{formatNumberAmount(amount)}</Typography>
        </React.Fragment>
    )
}

export default ParcelCardItem;
