import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import FormTextInput from "../input/text/FormTextInput";
import GenericDialog from "./GenericDialog";
import { Grid } from "@mui/material";
import FormNumericInput from "../input/text/FormNumericInput";
import Select from "../input/select/Select";

const ReportItemFormDialog = (props) => {

    const { t } = useTranslation(["dimension"]);
    const theme = useTheme();
    const [state, setState] = React.useState({})
    const { open, formTitle, formText, formType, actionMethod, fullScreen = true } = props;
    const { title, layoutWidth, layoutHeight, widgetId, widgets } = props;

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.id]: e.target.value,
        });
    }

    const handleAction = (action) => {
        actionMethod(action, state);
    }

    useEffect(() => {
        setState({title, layoutHeight, layoutWidth, widgetId});
    }, [title, layoutWidth, layoutHeight, widgetId])

    return (
        <GenericDialog
            open={open}
            title={formTitle}
            text={formText}
            actionMethod={handleAction}
            cancelLabel={t("Cancel")}
            submitLabel={t("Sumbit")}
            fullScreen={fullScreen}
        >
            <Grid container spacing={theme.spacing(2)} marginTop={theme.spacing(1)}>
                <Grid item xs={12}>
                    <FormTextInput margin="none" required={true} id="title" label={t("Title")}
                        disabled={formType === "delete"} onChange={handleChange} value={state.title ?? ""} />
                </Grid>
                <Grid item xs={12}>
                    <Select options={widgets.map(entry => ({id: entry.id, label: entry.name}))}
                        id="widgetId" selected={state.widgetId} label={t("Widget")}
                        onChange={(e, v) => setState({...state, widgetId: v ? v.id : null})} />
                </Grid>
                <Grid item xs={6}>
                    <FormNumericInput id="layoutWidth" label={t("Width")} value={state.layoutWidth}
                        onChange={(e) => {setState({...state, layoutWidth: parseInt(e.target.value)})}}
                        maxValue={12} minValue={1} />
                </Grid>
                <Grid item xs={6}>
                    <FormNumericInput id="layoutHeight" label={t("Height")} value={state.layoutHeight}
                        onChange={(e) => {setState({...state, layoutHeight: parseInt(e.target.value)})}}
                        minValue={50} />
                </Grid>
            </Grid>
        </GenericDialog>
    );
}

export default ReportItemFormDialog;
