import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import FormTextInput from "../input/text/FormTextInput";
import FormTextAreaInput from "../input/text/FormTextArea";
import GenericDialog from "./GenericDialog";

const DimensionFormDialog = (props) => {

    const { t } = useTranslation(["dimension"]);
    const [state, setState] = React.useState({})
    const { open, title, type, spec, actionMethod, fullScreen = true } = props;

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.id]: e.target.value,
        });
    }

    const handleAction = (action) => {
        actionMethod(action, state);
    }

    useEffect(() => {
        setState(
            spec.reduce(
                (accum, element) => {
                    accum[element.id] = element.value;
                    return accum;
                }, {}
            )
        );
    }, [spec])

    const submitText = type === "create" ? t("Create") : (
        type === "edit" ? t("Update") : (
            type === "delete" ? t("Delete") : t("Submit")
        )
    );

    const text = type === "delete" ? t("Are you sure you want to delete this entry?") : null;

    return (
        <GenericDialog
            open={open}
            title={title}
            text={text}
            actionMethod={handleAction}
            cancelLabel={t("Cancel")}
            submitLabel={submitText}
            fullScreen={fullScreen}
        >
            {
                spec.map(element => {

                    const commonParams = {
                        key: element.id,
                        required: element.required ?? false,
                        id: element.id,
                        label: element.label,
                        disabled: type === "delete",
                        onChange: handleChange,
                        value: state[element.id] ?? "",
                    }

                    if (element.type === "text") {
                        return <FormTextInput {...commonParams} margin="normal" />
                    }
                    
                    if (element.type === "area") {
                        return <FormTextAreaInput {...commonParams} margin="normal" />
                    }

                    return null;
                })
            }
        </GenericDialog>
    );
}

export default DimensionFormDialog;
