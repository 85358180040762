import { red } from "@mui/material/colors";
import { createTheme, responsiveFontSizes } from "@mui/material";

const theme = responsiveFontSizes(
    createTheme({
        palette: {
            primary: {
                main: "#000066",
            },
            secondary: {
                main: "#B14021",
            },
            error: {
                main: red.A400,
            },
            background: {
                default: "#fff",
            },
        },
        typography: {
            fontSize: 12,
            h1: {
                fontSize: "3.20rem"
            },
            h2: {
                fontSize: "2.70rem"
            },
            h3: {
                fontSize: "2.20rem"
            },
            h4: {
                fontSize: "1.80rem"
            },
            h5: {
                fontSize: "1.50rem"
            },
            h6: {
                fontSize: "1.25rem"
            },
        },
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1536,
            },
        },
    })
);

export default theme;
