import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import FingerprintIcon from '@mui/icons-material/Fingerprint';

import { formatNumberAmount } from "../../helpers/format";
import TextDialog from "../dialog/TextDialog";
import ParcelCardItem from "./ParcelCardItem";

const DimensionCard = (props) => {

    const theme = useTheme();
    const { canUpdateAccount } = props;
    const { name, description, identifier, balance, to, handleEdit, handleDelete } = props;

    const [dialogState, setDialogState] = React.useState({open: false, text: null});

    const descriptionDisabled = !canUpdateAccount || description === null || description === "" || description === undefined;

    return (
        <React.Fragment>
            <Card>
                <CardContent sx={{ textAlign: "center", padding: theme.spacing(1)}}>
                    <ParcelCardItem label={name} amount={balance} />
                </CardContent>
                <CardActions disableSpacing={true}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <IconButton disabled={!canUpdateAccount} onClick={handleDelete}><DeleteIcon /></IconButton>
                            {identifier ? <IconButton onClick={() => setDialogState({open: true, text: identifier})} sx={{marginLeft: theme.spacing(1)}}><FingerprintIcon /></IconButton> : null}
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <IconButton disabled={!canUpdateAccount} onClick={handleEdit}><EditIcon /></IconButton>
                            <IconButton disabled={descriptionDisabled} onClick={() => setDialogState({open: true, text: description})} sx={{marginLeft: theme.spacing(1)}}><TextSnippetIcon /></IconButton>
                            <IconButton disabled={!canUpdateAccount} component={Link} to={to} sx={{marginLeft: theme.spacing(1)}}><InfoRoundedIcon /></IconButton>
                        </Box>
                    </Grid>
                </CardActions>
            </Card>
            <TextDialog open={dialogState.open} text={dialogState.text} onClose={() => setDialogState({open: false, text: null})} />
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        isMobile: state.appSettings.isMobile,
        canUpdateAccount: state.user.canUpdateAccount,
    }
}

export default connect(mapStateToProps)(DimensionCard);
