import * as React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import SortIcon from "@mui/icons-material/Sort";
import IconButton from "@mui/material/IconButton";

const ActionButtonMenuSort = (props) => {

    const { t } = useTranslation(["collection"]);
    const { menuItems, handleMenuClick, isMobile } = props;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => { setAnchorEl(event.currentTarget) };
    const handleClose = () => { setAnchorEl(null) };

    const handleMenu = (menuId) => {
        handleClose();
        handleMenuClick(menuId);
    }

    const buttonCommonParams = {
        onClick: handleClick,
    }

    return (
        <React.Fragment>
            {
                isMobile ? (
                    <IconButton {...buttonCommonParams}><SortIcon /></IconButton>
                ) : (
                    <Button startIcon={<SortIcon />} {...buttonCommonParams}>{t("Sort")}</Button>
                )
            }
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            {
                menuItems.map(element => {
                    const ArrowIcon = element.selected ? (
                        element.ascending ? ArrowUpwardIcon : ArrowDownwardIcon
                    ) : null;
                    return (
                        <MenuItem selected={element.selected} key={element.column}
                            onClick={() => handleMenu(element.column)} sx={{minWidth: "200px"}}>
                            <ListItemText>{element.label}</ListItemText>
                            <ListItemIcon>
                            {
                                ArrowIcon ? (
                                    <ArrowIcon fontSize="small" />
                                ) : null
                            }
                            </ListItemIcon>
                        </MenuItem>
                    )
                })
            }
            </Menu>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        isMobile: state.appSettings.isMobile,
    }
}

export default connect(mapStateToProps)(ActionButtonMenuSort);
