import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import IconButton from "@mui/material/IconButton";

const ActionButtonMenuDownload = (props) => {

    const { t } = useTranslation(["collection"]);

    const { isMobile, onClick } = props;

    const buttonCommonParams = {
        onClick: onClick,
    }

    const icon = <DownloadIcon />;

    return (
        isMobile ? <IconButton {...buttonCommonParams}>{icon}</IconButton> : (
            <Button startIcon={icon} {...buttonCommonParams}>{t("Download")}</Button>
        )
    );
}

const mapStateToProps = (state) => {
    return {
        isMobile: state.appSettings.isMobile,
    }
}

export default connect(mapStateToProps)(ActionButtonMenuDownload);
