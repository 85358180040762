import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";


const Select = (props) => {

    const { t } = useTranslation(["select"]);

    const {
        options,
        id,
        selected,
        label,
        onChange,
        disabled,
        margin = "none",
        addEmptyItem = true,
        multiple = false,
        error = false,
        helperText = "",
        sx = {},
        required = false,
    } = props;

    const allOptions = addEmptyItem ? [{ id: "", label: "" }].concat(options) : options;

    const [value, setValue] = React.useState(multiple ? [] : allOptions[0]);
    const [inputValue, setInputValue] = React.useState("");

    useEffect(() => {
        const idList = Array.isArray(selected) ? selected : [selected];
        const matchOptions = allOptions.filter((entry) => idList.indexOf(entry.id) >= 0);
        const selectedElement = matchOptions.length > 0 ? (multiple ? matchOptions : matchOptions[0]) : (multiple ? [] : allOptions[0]);
        setValue(selectedElement);
    }, [selected, multiple])

    return (
        <Autocomplete disablePortal id={id} options={allOptions} multiple={multiple}
            isOptionEqualToValue={(opt, val) => opt.id === val.id} disabled={disabled}
            value={value} onChange={onChange} inputValue={inputValue} disableClearable={!addEmptyItem}
            onInputChange={(e, v) => { setInputValue(v); }}
            renderInput={(params) => (
                <TextField required={required} {...params} margin={margin ?? "normal"} label={t(label)} error={error} helperText={helperText} sx={sx} />
            )}
        />
    );
}

export default Select;
