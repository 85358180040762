import React from "react";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import DimensionChip from "./DimensionChip";
import { TagChipColor } from "../../helpers/colors";

const TagChip = (props) => {

    const { label, tagId } = props;

    return (
        <DimensionChip
            faIcon={faTag}
            textColor={TagChipColor.textColor}
            regularColor={TagChipColor.regularColor}
            hoverColor={TagChipColor.onHoverColor}
            label={label}
            href={`/tag/view/${tagId}`}
        />
    );
}

export default TagChip;
