import React from "react";
import { useTheme } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { formatNumberAmount, formatNumberPercentage } from "../../helpers/format";

const TotalsCardItem = (props) => {
    const theme = useTheme();
    const { label, value, variation, isCurrency = true } = props;
    const hasVariation = variation !== undefined;
    const formattedNumber = formatNumberAmount(value, isCurrency ? undefined : null, isCurrency ? undefined : 0, undefined, undefined, undefined, isCurrency);

    return (
        <Paper sx={{ textAlign: "center", padding: theme.spacing(1) }} elevation={1}>
            <Typography
                sx={{
                    fontVariant: "small-caps",
                    fontSize: "120%",
                    fontWeight: 800,
                }}
            >
                {label}
            </Typography>
            <Typography
                sx={{
                    fontSize: "130%",
                    fontWeight: 800,
                    marginTop: theme.spacing(0.5)
                }}
            >
                {formattedNumber}
            </Typography>
            {
                hasVariation ? (
                    <Typography
                        sx={{
                            marginTop: theme.spacing(0.5),
                            fontSize: "120%",
                            fontWeight: 700,
                        }}
                        color={variation === null ? "grey" : (variation < 0 ? "red": "green")}
                    >
                        {variation === null ? "-" : formatNumberPercentage(variation)}
                    </Typography>
                ) : null
            }
        </Paper>
    );
}

export default TotalsCardItem;
