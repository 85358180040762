import React from "react";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

const DeleteIconButton = (props) => {
    const { onClick, disabled = false } = props;
    return (
        <IconButton disabled={disabled} color="primary" onClick={onClick}>
            <DeleteIcon />
        </IconButton>
    )
}

export default DeleteIconButton;
