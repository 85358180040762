import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import { UserAvatar, AnonymousUserBox } from "../../components/styled/user";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import { sendPasswordResetEmail } from "../../helpers/firebase";
import SubmitButton from "../../components/input/button/SubmitButton";
import FormTextInput from "../../components/input/text/FormTextInput";


const ForgotPassword = (props) => {

    const theme = useTheme();
    const { t } = useTranslation(["user"]);

    const [state, setState] = React.useState({
        email: "",
    });

    if (props.uid) {
        return <Redirect to={"/"} />;
    }

    const handleSubmitButton = async (e) => {
        const res = await sendPasswordResetEmail(state.email);
        if (res > 0) {
            toast.info(
                t("Please, check your email and follow the instructions."),
                {autoClose: 5000}
            );
        }
        else {
            toast.info(t("Error on sending password reset email. Please, check if your user is registered."));
        }
    }

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.id]: e.target.value
        });
    }

    return (
        <Container component="main" maxWidth="xs">
            <AnonymousUserBox>
                <UserAvatar><LockOutlinedIcon /></UserAvatar>
                <Typography component="h1" variant="h5">{t("Reset Password")}</Typography>
                <Box sx={{ marginTop: theme.spacing(1) }}>
                    <FormTextInput
                        id="email"
                        required={true}
                        label={t("Email Address")}
                        onChange={handleChange}
                        type="email"
                    />
                    <SubmitButton fullWidth={true} marginTop={3} marginBottom={2} onClick={handleSubmitButton}>
                        {t("Send Password Reset Email")}
                    </SubmitButton>
                </Box>
            </AnonymousUserBox>
        </Container>
    );
}

const mapStateToProps = (state) => {
    return {
        uid: state.user.uid,
    }
}

export default connect(mapStateToProps)(ForgotPassword);
