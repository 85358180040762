import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import ActionButtonMenuProductFilter from "./ActionButtonMenuProductFilter";

const ActionButtonMenuProductTypes = (props) => {

    const { t } = useTranslation(["product"]);

    const { handleMenuClick, productTypes, selected } = props;

    return (
        <ActionButtonMenuProductFilter
            title={t("Types")}
            handleMenuClick={handleMenuClick}
            records={
                productTypes.map(element => {
                    return {
                        id: element.id,
                        label: t(element.id),
                    }
                })
            }
            selected={selected}
            icon={<FontAwesomeIcon icon={faCreditCard} />} />
    )
}

const mapStateToProps = (state) => {
    return {
        productTypes: state.user.productTypes,
        productGroups: state.user.groups,
    }
}

export default connect(mapStateToProps)(ActionButtonMenuProductTypes);
