import React from "react";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";


const TextDialog = (props) => {

    const { open, text, onClose } = props;

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogContent>
                <DialogContentText>{text}</DialogContentText>
            </DialogContent>
        </Dialog>
    );
}

export default TextDialog;
