import React from "react";
import { useTranslation } from "react-i18next";

import { Bar } from "recharts";
import { XAxis } from "recharts";
import { YAxis } from "recharts";
import { Legend } from "recharts";
import { CartesianGrid } from "recharts";
import { BarChart } from "recharts";
import { Tooltip } from "recharts";
import { ResponsiveContainer } from "recharts";

import { sequentialPallete } from "../../helpers/colors";
import { mergeArrays } from "../../helpers/utils";
import { apiTotals } from "../../api/aggregations";
import { formatNumberAmount } from "../../helpers/format";

const BarChartWidget = (props) => {
    const { t } = useTranslation(["widget"]);

    const [widgetData, setWidgetData] = React.useState([]);

    const {primaryFilter, secondaryFilter, primaryLabel, secondaryLabel, dimension, parcels, sortParcel, sortDirection, items, height} = props;

    const convertKey = (filterLabel, parcelLabel) => {
        return filterLabel ? `${filterLabel} - ${parcelLabel}` : parcelLabel;
    }

    React.useEffect(() => {

        const parcelInclSort = parcels.concat({key: sortParcel, label: "__SORT__"})

        const getParcels = (list, filterLabel, includeSort) => {
            return list.map(element => {
                const newElement = {
                    id: element.id,
                    name: element.name,
                }
                if (includeSort) {
                    newElement["__SORT__"] = element.__SORT__;
                }
                parcels.forEach(parcel => {
                    const lbl = convertKey(filterLabel, parcel.label);
                    newElement[lbl] = element[parcel.key];
                });
                return newElement;
            })
        }
    
        const doRequest = (filter) => {
            return new Promise((resolve, reject) => {
                apiTotals(
                    {...filter, dimension},
                    (data, successFlag) => {
                        if (successFlag > 0) {
                            const res = data[dimension].map(element => {
                                const obj = {
                                    id: element[`${dimension}Id`],
                                    name: element[`${dimension}Name`] ?? t("Undefined"),
                                }
                                parcelInclSort.forEach(parcel => {
                                    const key = parcel.label === "__SORT__" ? "__SORT__" : parcel.key;
                                    obj[key] = element[parcel.key];
                                });
                                return obj;
                            });
                            return resolve(res);
                        }
                        return reject(successFlag);
                    }
                )
            });
        }

        var promises = [];
        promises.push(doRequest(primaryFilter));
        if (secondaryFilter) {
            promises.push(doRequest(secondaryFilter));
        }

        Promise.all(promises).then((result) => {
            const primaryData = getParcels(result[0], secondaryFilter ? primaryLabel : null, true);
            const secondaryData = result.length > 1 ? getParcels(result[1], secondaryLabel, false) : null;

            const mrgData = (
                secondaryData
                ? mergeArrays(primaryData, secondaryData, (a, b) => a.id === b.id, true)
                : primaryData.slice()
            ).map(element => {
                if (!Object.keys(element).includes("__SORT__")) {
                    element.__SORT__ = null;
                }
                return element;
            }).sort((a, b) => {
                if (a.__SORT__ === null) return 1;
                if (b.__SORT__ === null) return -1;
                if (sortDirection === "ASC") {
                    if (a.__SORT__ < b.__SORT__) return -1;
                    if (a.__SORT__ < b.__SORT__) return 1;
                    return 0;
                }
                if (a.__SORT__ > b.__SORT__) return -1;
                if (a.__SORT__ < b.__SORT__) return 1;
                return 0;
            });

            const topData = mrgData.slice(0, items);
            const sumOthers = {id: "_OTHERS_", name: t("Others")};
            parcels.forEach(parcel => {
                if (secondaryLabel) {
                    const primaryKey = convertKey(primaryLabel, parcel.label);
                    sumOthers[primaryKey] = mrgData.slice(items).reduce((a, b) => a + (b[primaryKey] || 0), 0);
                    const secondaryKey = convertKey(secondaryLabel, parcel.label);
                    sumOthers[secondaryKey] = mrgData.slice(items).reduce((a, b) => a + (b[secondaryKey] || 0), 0);
                }
                else {
                    sumOthers[parcel.key] = mrgData.slice(items).reduce((a, b) => a + (b[parcel.key] || 0), 0);
                }
            });
            topData.push(sumOthers);
            setWidgetData(topData);
        }, (err) => {
            console.log(err);
        });
    }, [primaryFilter, secondaryFilter, primaryLabel, secondaryLabel, dimension, parcels, sortParcel, sortDirection, items]);

    return (
        <ResponsiveContainer width="100%" height={height}>
            <BarChart data={widgetData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip formatter={(value, name, props) => formatNumberAmount(value)} />
                <Legend />
                {
                    parcels.map((parcel, idx) => {
                        const iterLabels = secondaryLabel ? [primaryLabel, secondaryLabel] : [null];
                        return iterLabels.map((lbl, i) => {
                            const secColor = secondaryLabel ? secondaryLabel === lbl : false;
                            const color = sequentialPallete(idx, secColor);
                            const dataKey = convertKey(secondaryLabel ? lbl : null, parcel.label);
                            return <Bar key={`bar-${idx}-${i}`} dataKey={dataKey} fill={color} />
                        })
                    })
                }
            </BarChart>
        </ResponsiveContainer>
    )
}

export default BarChartWidget;
