import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from '@mui/icons-material/Clear';

const SearchInput = (props) => {

    const { size = "small", submit, reset, onChange, value, sx } = props;

    const theme = useTheme();
    const { t } = useTranslation(["dim"]);

    const [resetVisible, setResetVisible] = React.useState(false);

    const handleSubmitSearch = (e) => {
        setResetVisible(true);
        submit();
    }

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            setResetVisible(true);
            submit();
        }
    }

    const handleResetSearch = (e) => {
        setResetVisible(false);
        reset();
    }

    return (
        <TextField label={t("Search...")} size={size} sx={sx}
            fullWidth onChange={onChange} value={value} onKeyDown={handleKeyDown}
            InputProps={{endAdornment: (
                <InputAdornment position="end">
                    {
                        resetVisible
                        ? (
                            <IconButton onClick={handleResetSearch} sx={{ marginRight: theme.spacing(1) }}>
                                <ClearIcon fontSize={size} />
                            </IconButton>
                        ) : null
                    }
                    <IconButton onClick={handleSubmitSearch}><SearchIcon fontSize={size} /></IconButton>
                </InputAdornment>
            )}}
        />
    );
}

export default SearchInput;
