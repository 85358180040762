import React from "react";
import { connect } from "react-redux";
import { Redirect, Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import TextField from "@mui/material/TextField";
import Link from "@mui/material//Link";
import Grid from "@mui/material//Grid";
import Box from "@mui/material//Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useTheme } from "@mui/material/styles";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import { AnonymousUserBox, UserAvatar } from "../../components/styled/user";
import { registerWithEmailAndPassword } from "../../helpers/firebase";
import { validateUserInput } from "../../helpers/user";
import SubmitButton from "../../components/input/button/SubmitButton";


const Register = (props) => {

    const theme = useTheme();
    const { t } = useTranslation(["user"]);

    const [firstSubmit, setFirstSubmit] = React.useState(false);

    const [fieldValues, setFieldValues] = React.useState({
        name: "",
        email: "",
        password: "",
        passwordConfirm: "",
    });

    const [fieldErrors, setFieldErrors] = React.useState({
        name: "",
        email: "",
        password: "",
        passwordConfirm: "",
    });

    if (props.uid) {
        return <Redirect to={"/"} />;
    }

    const validateInputs = () => {
        let errors = { ...fieldErrors };
        errors.name = validateUserInput("name", fieldValues.name, t);
        errors.email = validateUserInput("email", fieldValues.email, t);
        errors.password = validateUserInput("password", fieldValues.password, t);
        errors.passwordConfirm = validateUserInput("passwordConfirm", fieldValues.passwordConfirm, t, fieldValues.password);
        setFieldErrors({ ...errors });
        return Object.keys(errors).map(k => errors[k] === "").every(r => r);
    }

    const handleChange = (e) => {
        setFieldValues({
            ...fieldValues,
            [e.target.id]: e.target.value
        });
        if (firstSubmit) {
            setFieldErrors({
                ...fieldErrors,
                [e.target.id]: validateUserInput(e.target.id, e.target.value, t, fieldValues.password),
            })
        }
    }

    const handleSubmitButton = (e) => {
        e.preventDefault();

        const submitUser = async () => {
            const formValid = validateInputs();
            if (formValid) {
                const res = await registerWithEmailAndPassword(fieldValues.name, fieldValues.email, fieldValues.password);
                if (res > 0) {
                    toast.info(
                        t("User registered with success. Please, check your email and follow the instructions."),
                        {autoClose: 5000}
                    );
                    props.history.push("/user/login");
                }
                else if (res === 0) {
                    toast.error(t("User already exists"), {autoClose: 5000});
                }
                else {
                    toast.error(
                        t("An unknown error occurred during registration"),
                        {autoClose: 4000}
                    );
                }
            }
            else {
                setFirstSubmit(true);
                toast.error(t("Your information contains errors"), {autoClose: 5000});
            }
        }
        submitUser()
    }

    const RenderTextField = (name, label, autoComplete = "none", type = "text", autoFocus = false) => {
        const errorText = fieldErrors[name];
        const hasError = errorText !== null && errorText !== "";
        return (
            <TextField variant="outlined" margin="normal" required fullWidth id={name} label={label}
                name={name} onChange={handleChange} error={hasError} helperText={errorText}
                autoComplete={autoComplete} type={type} autoFocus={autoFocus} onBlur={handleChange} />
        );
    }

    return (
        <Container component="main" maxWidth="xs">
            <AnonymousUserBox>
                <UserAvatar><LockOutlinedIcon /></UserAvatar>
                <Typography component="h1" variant="h5">{t("Sign Up")}</Typography>
                <Box sx={{ marginTop: theme.spacing(1) }}>
                    {RenderTextField("name", t("Name"), "name", undefined, true)}
                    {RenderTextField("email", t("Email"), "email", "email")}
                    {RenderTextField("password", t("Password"), "password", "password")}
                    {RenderTextField("passwordConfirm", t("Confirm Password"), null, "password")}
                    <SubmitButton fullWidth={true} marginTop={3} marginBottom={2} onClick={handleSubmitButton}>
                        {t("Submit")}
                    </SubmitButton>
                    <Grid container justifyContent="flex-end">
                        <Grid item><Link component={RouterLink} to="/user/login" variant="body2">{t("Already have an account")}</Link></Grid>
                    </Grid>
                </Box>
            </AnonymousUserBox>
        </Container>
    );
}

const mapStateToProps = (state) => {
    return {
        uid: state.user.uid,
    }
}

export default connect(mapStateToProps, null)(Register);
