import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import Select from "../../components/input/select/Select";
import FormTextInput from "../../components/input/text/FormTextInput";
import FormTextAreaInput from "../../components/input/text/FormTextArea";
import GenericDialog from "./GenericDialog";

const ProductFormDialog = (props) => {

    const { t } = useTranslation(["product"]);
    const [state, setState] = React.useState({})
    const { productTypes, productGroups } = props;
    const { open, title, type, editState, actionMethod } = props;

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.id]: e.target.value,
        });
    }

    const handleAction = (action) => {
        actionMethod(action, state);
    }

    const handleSelect = (field, e, newValue) => {
        const val = newValue ? newValue.id : "";
        setState({
            ...state,
            [field]: val,
        });
    }

    useEffect(() => {
        setState({...editState});
    }, [editState]);

    const text = type === "delete" ? t("Are you sure you want to delete this entry?") : null;

    return (
        <GenericDialog
            open={open}
            title={title}
            text={text}
            actionMethod={handleAction}
            cancelLabel={t("Cancel")}
            submitLabel={t("Sumbit")}
            fullScreen={true}
        >
            <FormTextInput
                required={true}
                id="name"
                label={t("Name")}
                disabled={type === "delete"}
                onChange={handleChange}
                value={state.name}
                margin="normal" 
            />
            <Select options={productGroups.map((entry) => ({id: entry.id, label: entry.name}))} margin="normal"
                id="productGroupId" disabled={type === "delete"} selected={state.productGroupId} required={true}
                label={t("Product Group")} onChange={(e, v) => handleSelect("productGroupId", e, v)} />
            <Select options={productTypes.map((entry) => ({id: entry.id, label: t(entry.id)}))} margin="normal"
                id="productType" disabled={type === "delete"} selected={state.productType} required={true}
                label={t("Product Type")} onChange={(e, v) => handleSelect("productType", e, v)} />
            {
                state.productType === "FIN" &&
                    <FormTextInput
                        id="identifier"
                        label={t("ISIN")}
                        disabled={type === "delete"}
                        onChange={handleChange}
                        value={state.identifier}
                        margin="normal" />
            }
            {
                state.productType === "CRY" &&
                    <FormTextInput
                        id="identifier"
                        label={t("Symbol")}
                        disabled={type === "delete"}
                        onChange={handleChange}
                        value={state.identifier}
                        margin="normal" />
            }
            <FormTextAreaInput
                id="description"
                label={t("Description")}
                disabled={type === "delete"}
                name="description"
                onChange={handleChange}
                value={state.description}
                margin="normal"
            />
        </GenericDialog>
    );
}

const mapStateToProps = (state) => {
    return {
        productTypes: state.user.productTypes,
        productGroups: state.user.groups,
    }
}

export default connect(mapStateToProps)(ProductFormDialog);
