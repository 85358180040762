import React, { useEffect, useState } from "react";
import { Grid, Paper, Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useTheme } from "@mui/material/styles";

import { apiDataseries } from "../../api/aggregations";
import TotalsCardItem from "./TotalsCardItem";

const TotalsCard = (props) => {

    const { t } = useTranslation(["widget"]);
    const theme = useTheme();
    const [record, setRecord] = useState(null);

    const {parcels, mainFilter, secondaryFilter, itemSize = 12, height = null } = props;

    const doRequest = (filterData) => {
        return new Promise((resolve, reject) => {
            apiDataseries(
                filterData,
                (data, successFlag) => {
                    if (successFlag > 0) {
                        return resolve(data);
                    }
                    return reject(successFlag);
                }
            )
        });
    }

    useEffect(() => {

        var promises = [];
        promises.push(doRequest(mainFilter));
        if (secondaryFilter) {
            promises.push(doRequest(secondaryFilter));
        }
        
        Promise.all(promises).then((result) => {
            const mainData = result[0].dataseries[0];
            const secondaryData = result.length > 1 ? result[1].dataseries[0] : null;
            const parcelNames = parcels.map(element => element.key);
            setRecord(Object.keys(mainData).reduce((acc, key) => {
                if (parcelNames.includes(key)) {
                    acc[key] = mainData[key];
                    if (secondaryData) {
                        acc[`${key}PctChange`] = secondaryData[key] !== 0 && secondaryData[key] !== undefined && secondaryData[key] !== null ? (
                            mainData[key] / secondaryData[key] - 1
                        ) : null;
                    }
                }
                return acc;
            }, {}));
        }, (err) => {
            console.log(err);
        });
    }, [parcels, mainFilter, secondaryFilter]);

    if (!record) return null;

    const sxBox = height ? {height: height, overflow: "auto", padding: theme.spacing(1)} : undefined;

    return (
        <Box sx={sxBox}>
            <Grid container spacing={theme.spacing(1)}>
                {
                    parcels.map(element => {
                        return (
                            <Grid item xs={itemSize} key={element.key}>
                                <TotalsCardItem
                                    label={element.label}
                                    value={record[element.key]}
                                    variation={record[`${element.key}PctChange`]}
                                    isCurrency={element.isCurrency} />
                            </Grid>
                        )
                    })
                }
            </Grid>
        </Box>
    )
}

export default TotalsCard;
