import React from "react";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";

const CollectionAddButton = (props) => {
    const { handleAdd, addText, to, isMobile = false, sx={} } = props;
    const linkParams = (
        to
        ? { to: to, component: Link }
        : null
    );
    return (
        <Button size="small" startIcon={<AddIcon />} onClick={handleAdd} {...linkParams}
            fullWidth={isMobile} variant={isMobile ? "contained" : "text"} sx={sx}>
            {addText}
        </Button>
    )
}

export default CollectionAddButton;
