import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { toast } from "react-toastify";

import { useTheme } from "@mui/material/styles";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import DeleteIcon from "@mui/icons-material/Delete";
import DescriptionIcon from "@mui/icons-material/Description";

import { getTransactionService, createTransactionService, updateTransactionService } from "../../service/transaction";
import { generateUuid, snakeToPascalCase } from "../../helpers/utils";
import { translateApiErrorType } from "../../helpers/error";
import { useHistory } from "react-router-dom";

import CancelButton from "../../components/input/button/CancelButton";
import SubmitButton from "../../components/input/button/SubmitButton";
import Select from "../../components/input/select/Select";
import DateInput from "../../components/input/text/DateInput";

import { redirectUrlDownload } from "../../helpers/download";

import { Button, IconButton, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper } from "@mui/material";
import { apiGetFileUrl } from "../../api/transaction";

const TransactionForm = (props) => {
    const theme = useTheme();
    const { t } = useTranslation(["transaction", "amount_type", "transaction_type", "errors"]);

    const { isCopy = false } = props;
    const history = useHistory();

    const { amountTypes, transactionTypes, products, categories, items, entities, tags } = props;

    const [copy, setCopy] = React.useState(isCopy);
    const [state, setState] = React.useState({
        id: null,
        date: moment().format("YYYY-MM-DD"),
        amount: 0,
        description: "",
        amountType: "REG",
        transactionType: "DEB",
        productId: "",
        categoryId: "",
        itemId: "",
        entityId: "",
        tags: [],
    });

    const [filesToUpload, setFilesToUpload] = React.useState([]);
    const [filesToDelete, setFilesToDelete] = React.useState([]);

    const [errors, setErrors] = React.useState({
        date: null,
        amount: null,
        description: null,
        amountType: null,
        transactionType: null,
        productId: null,
        categoryId: null,
        itemId: null,
        entityId: null,
        tags: null,
    });

    const setData = (data) => {
        setState({
            ...state,
            id: copy ? null : data.transaction.id,
            date: moment(data.transaction.date).format("YYYY-MM-DD"),
            amount: data.transaction.amount,
            amountType: data.transaction.amount_type,
            transactionType: data.transaction.transaction_type,
            productId: data.transaction.product_id,
            categoryId: data.transaction.category_id,
            itemId: data.transaction.item_id,
            entityId: data.transaction.entity_id ?? "",
            description: data.transaction.description ?? "",
            tags: data.transaction.tags.map((tag) => tag.tag_id),
        });
    }

    const handleDateChange = (value) => {
        setState({
            ...state,
            date: moment(value).format("YYYY-MM-DD"),
        })
    }

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.id]: e.target.value
        });
    }

    const handleSelect = (field, e, newValue) => {
        const val = (
            newValue
            ? (field === "tags" ? newValue.map((entry) => entry.id) : newValue.id)
            : (field === "tags"? [] : "")
        );
        setState({
            ...state,
            [field]: val,
        });
    }

    const handleErrors = (errorsList) => {
        const fieldErrors = errorsList.reduce((obj, err) => {
            const field = snakeToPascalCase(err.field_name);
            if (field in errors) {
                const errorMessage = translateApiErrorType(err.error_type);
                obj[field] = t(errorMessage, {ns: "errors"});
                return obj;
            }
            return {};
        }, {});
        setErrors({
            ...errors,
            ...fieldErrors,
        });
    }

    const resetErrors = () => {
        const newErrors = {...errors};
        Object.keys(errors).map((key) => {
            newErrors[key] = null;
        });
        setErrors(newErrors);
    }

    const updateFilesState = (transactionFiles) => {
        setFilesToUpload(
            transactionFiles.map(file => {
                return {
                    id: file.file_id,
                    isNew: false,
                    originalFileName: file.original_file_name,
                }
            })
        );
    }

    const handleSubmit = (e) => {
        const { id, date, amount, transactionType, amountType, productId, categoryId, itemId, entityId, description, tags } = state;
        const files = filesToUpload.filter(element => element.isNew).map(element => element.fileContent);
        if (!id) {
            createTransactionService(
                { date, amount, transactionType, amountType, productId, categoryId, itemId, entityId, description, tags, files },
                (data, successFlag) => {
                    if (successFlag > 0) {
                        setCopy(false);
                        resetErrors();
                        setState({...state, id: data.transaction.id});
                        updateFilesState(data.transaction.files);
                        toast.success(t("Your transaction was created successfully"));
                    }
                    else if (successFlag === 0) {
                        handleErrors(data);
                    }
            });
        }
        else {
            updateTransactionService(
                { id, date, amount, transactionType, amountType, productId, categoryId, itemId, entityId, description, tags, files, filesToDelete },
                (data, successFlag) => {
                    if (successFlag > 0) {
                        resetErrors();
                        updateFilesState(data.transaction.files);
                        setFilesToDelete([]);
                        toast.success(t("Your transaction was updated successfully"));
                    }
                    else if (successFlag === 0) {
                        handleErrors(data);
                    }
            });
        }
    }

    useEffect(() => {
        const paramId = props.match.params.id;
        if (paramId && paramId !== "0") {
            getTransactionService({ id: paramId }, (data, successFlag) => {
                if (successFlag > 0) {
                    setData(data);
                    if (!copy) {
                        updateFilesState(data.transaction.files);
                    }
                }
            });
        }
    }, []);

    const handleFileUpload = (e) => {
        if (!e.target.files) {
            return;
        }
        const file = e.target.files[0];
        let addFiles = filesToUpload.slice();
        addFiles.push({id: generateUuid(), isNew: true,  originalFileName: file.name, fileContent: file});
        setFilesToUpload(addFiles);
    };

    const deleteFile = (fileSpec) => {
        let rmFiles = filesToUpload.filter(element => (
            element.id !== fileSpec.id && element.originalFileName !== fileSpec.originalFileName
        ));
        setFilesToUpload(rmFiles);
        if (!fileSpec.isNew) {
            let addDeletion = filesToDelete.slice()
            addDeletion.push(fileSpec.id);
            setFilesToDelete(addDeletion);
        }
    }

    const handleDownload = (fileId, originalFileName) => {
        apiGetFileUrl({id: fileId}, (data, successFlag) => {
            if (successFlag > 0) {
                redirectUrlDownload(data.url, originalFileName);
            }
        })
    }

    return (
        <Container component="main" maxWidth="lg" sx={{ marginTop: theme.spacing(1) }}>
            <Typography component="h1" variant="h1">
                {state.id ? t("Update Transaction") : t("Create Transaction")}
            </Typography>
            <Grid container sx={{ marginTop: theme.spacing(2) }} spacing={2}>

                <Grid item xs={12} sm={6} md={3}>
                    <DateInput label={t("Date")} value={state.date} onChange={handleDateChange}
                        error={errors.date !== null} helperText={errors.date} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <TextField required id="amount" type="number" variant="outlined" fullWidth label={t("Amount")}
                        onChange={handleChange} value={state.amount} error={errors.amount !== null} helperText={errors.amount} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Select options={transactionTypes.map((entry) => ({id: entry.id, label: t(entry.id, {ns: "transaction_type"})}))}
                        id="transactionType" selected={state.transactionType} label={t("Transaction Type")} margin="none"
                        onChange={(e, v) => handleSelect("transactionType", e, v)} addEmptyItem={false}
                        error={errors.transactionType !== null} helperText={errors.transactionType} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Select options={amountTypes.map((entry) => ({id: entry.id, label: t(entry.id, {ns: "amount_type"})}))}
                        id="amountType" selected={state.amountType} label={t("Amount Type")} margin="none" helperText={errors.amountType}
                        onChange={(e, v) => handleSelect("amountType", e, v)} addEmptyItem={false} error={errors.amountType !== null} />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Select options={products.map((entry) => ({id: entry.id, label: entry.name}))}
                        id="productId" selected={state.productId} label={t("Product")} margin="none" helperText={errors.productId}
                        onChange={(e, v) => handleSelect("productId", e, v)} error={errors.productId !== null} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Select options={categories.map((entry) => ({id: entry.id, label: entry.name}))}
                        id="categoryId" selected={state.categoryId} label={t("Category")} margin="none" helperText={errors.categoryId}
                        onChange={(e, v) => handleSelect("categoryId", e, v)} error={errors.categoryId !== null} />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Select options={items.map((entry) => ({id: entry.id, label: entry.name}))}
                        id="itemId" selected={state.itemId} label={t("Item")} margin="none" helperText={errors.itemId}
                        onChange={(e, v) => handleSelect("itemId", e, v)} error={errors.itemId !== null} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Select options={entities.map((entry) => ({id: entry.id, label: entry.name}))}
                        id="entityId" selected={state.entityId} label={t("Entity")} margin="none" helperText={errors.entityId}
                        onChange={(e, v) => handleSelect("entityId", e, v)} error={errors.entityId !== null} />
                </Grid>

                <Grid item xs={12}>
                    <Select options={tags.map((entry) => ({id: entry.id, label: entry.name}))}
                        id="tags" selected={state.tags} label={t("Tags")} margin="none" helperText={errors.tags}
                        onChange={(e, v) => handleSelect("tags", e, v)} multiple={true} error={errors.tags !== null} />
                </Grid>
                
                <Grid item xs={12}>
                    <TextField variant="outlined" margin="none" fullWidth id="description" label={t("Description")}
                        name="description" onChange={handleChange} value={state.description} multiline minRows={1} maxRows={4}
                        error={errors.description !== null} helperText={errors.description} />
                </Grid>

                <Grid item xs={12}>
                    <React.Fragment>
                        {
                            filesToUpload ? (
                                <List>
                                    {
                                        filesToUpload.map(element => (
                                            <ListItem key={element.id}
                                                secondaryAction={
                                                    <IconButton edge="end" aria-label="delete" onClick={(e) => deleteFile(element)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                }
                                            >
                                                <ListItemIcon><DescriptionIcon /></ListItemIcon>
                                                <ListItemText primary={
                                                    element.isNew ? element.originalFileName : (
                                                        <Link onClick={() => handleDownload(element.id, element.originalFileName)}
                                                            underline="hover" component="button">
                                                            {element.originalFileName}
                                                        </Link>
                                                    )
                                                } />
                                            </ListItem>
                                        ))
                                    }
                                </List>    
                            ) : null
                        }
                        <Button size="small" fullWidth variant="outlined" color="secondary" component="label">
                            {t("Upload")}
                            <input type="file" hidden onChange={handleFileUpload} />
                        </Button>
                    </React.Fragment>
                </Grid>

                <Grid container item xs={12} justifyContent="flex-end">
                    <CancelButton onClick={history.goBack}>{t("Back")}</CancelButton>
                    <SubmitButton onClick={handleSubmit} marginLeft={2}>
                        {state.id ? t("Update") : t("Create")}
                    </SubmitButton>
                </Grid>

            </Grid>
        </Container>
    )
}


const mapStateToProps = (state) => {
    return {
        isMobile: state.appSettings.isMobile,
        canUpdateAccount: state.user.canUpdateAccount,
        transactionTypes: state.user.transactionTypes,
        amountTypes: state.user.amountTypes,
        products: state.user.products,
        categories: state.user.categories,
        items: state.user.items,
        entities: state.user.entities,
        tags: state.user.tags,
    }
}

export default connect(mapStateToProps)(TransactionForm);
