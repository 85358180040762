import { axiosDelete, axiosGet, axiosPost, axiosPut } from "./instance";

export const apiReportList = (callback) => {
    axiosGet("report/list", {}, callback);
}

export const apiReportCreate = ({name}, callback) => {
    axiosPost("report/create", {name}, callback);
}

export const apiReportGet = ({id}, callback) => {
    axiosGet("report/" + id, {}, callback);
}

export const apiReportUpdate = ({id, name}, callback) => {
    axiosPut("report/" + id, {name}, callback);
}

export const apiReportDelete = ({id}, callback) => {
    axiosDelete("report/" + id, callback);
}

export const apiReportMoveUp = ({ id }, callback) => {
    axiosPut(`report/${id}/up`, {}, callback);
}

export const apiReportMoveDown = ({ id }, callback) => {
    axiosPut(`report/${id}/down`, {}, callback);
}

export const apiReportItemCreate = ({title, reportPageId, layoutWidth, layoutHeight, widgetId}, callback) => {
    axiosPost("report/add-item", {
        title: title,
        report_page_id: reportPageId,
        layout_width: layoutWidth,
        layout_height: layoutHeight,
        widget_id: widgetId,
    }, callback);
}

export const apiReportItemUpdate = ({id, title, layoutWidth, layoutHeight, widgetId}, callback) => {
    axiosPut("report/item/" + id, {
        title: title,
        layout_width: layoutWidth,
        layout_height: layoutHeight,
        widget_id: widgetId,
    }, callback);
}

export const apiReportItemDelete = ({id}, callback) => {
    axiosDelete("report/item/" + id, callback);
}

export const apiReportItemMoveUp = ({ id }, callback) => {
    axiosPut(`report/item/${id}/up`, {}, callback);
}

export const apiReportItemMoveDown = ({ id }, callback) => {
    axiosPut(`report/item/${id}/down`, {}, callback);
}
