import React from "react";
import { faSitemap } from "@fortawesome/free-solid-svg-icons";
import DimensionChip from "./DimensionChip";
import { CategoryChipColor } from "../../helpers/colors";

const CategoryChip = (props) => {

    const { label, categoryId } = props;

    return (
        <DimensionChip
            faIcon={faSitemap}
            textColor={CategoryChipColor.textColor}
            regularColor={CategoryChipColor.regularColor}
            hoverColor={CategoryChipColor.onHoverColor}
            label={label}
            href={`/category/view/${categoryId}`}
        />
    );
}

export default CategoryChip;
