import { DIM_CREATE, DIM_UPDATE, DIM_DELETE } from "./types";
import { apiItemCreate, apiItemUpdate, apiItemDelete } from "../../api/item";


export const createItem = ({ name, description }, callback) => {
    return (dispatch, getState) => {
        apiItemCreate({ name, description }, (data, successFlag) => {
            if (successFlag > 0) {
                dispatch({ type: DIM_CREATE, payload: { data: data.item, attr: "items" } });
            }
            callback(data, successFlag);
        });
    }
};


export const updateItem = ({ id, name, description }, callback) => {
    return (dispatch, getState) => {
        apiItemUpdate({ id, name, description }, (data, successFlag) => {
            if (successFlag > 0) {
                dispatch({ type: DIM_UPDATE, payload: { data: data.item, attr: "items" } });
            }
            callback(data, successFlag);
        });
    }
};


export const deleteItem = ({ id }, callback) => {
    return (dispatch, getState) => {
        apiItemDelete({ id }, (data, successFlag) => {
            if (successFlag > 0) {
                dispatch({ type: DIM_DELETE, payload: { data: id, attr: "items" } });
            }
            callback(data, successFlag);
        });
    }
};
