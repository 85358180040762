import { React } from "react";
import { Link as RouterLink } from "react-router-dom"
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import { Link } from "@mui/material";

export const TooltipLinkCell = (props) => {
    return <Tooltip title={props.text ? props.text : ""} arrow enterTouchDelay={0}>
        <Box component="span" sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        }}>
            <Link underline="hover" variant="button" component={RouterLink} to={props.url}>
                {props.text}
            </Link>
        </Box>
    </Tooltip>
};
