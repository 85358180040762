import React from "react";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

import { formatNumberAmount } from "../../helpers/format";

const AmountCardHeader = (props) => {
    const { label, value } = props;
    const theme = useTheme();
    const backgroundColor = theme.palette.primary;
    
    return (
        <Paper sx={{padding: theme.spacing(1), backgroundColor: backgroundColor.light}}>
            <Typography sx={{fontWeight: 800, fontSize: "110%", color: backgroundColor.contrastText}}>{label}</Typography>
            <Typography sx={{fontWeight: 600, color: backgroundColor.contrastText, fontSize: "110%"}}>{formatNumberAmount(value)}</Typography>
        </Paper>
    )
}

export default AmountCardHeader;
