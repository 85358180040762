import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import GenericDialog from "./GenericDialog";

const ListFieldsDialog = (props) => {

    const { open, onClose, title, items } = props;

    return (
        <GenericDialog
            open={open}
            title={title}
            onClose={onClose}>
            {
                items ? items.map((entry, idx) => {
                    return (
                        <ListItem key={idx}>
                            <ListItemText primary={entry.primary} secondary={entry.secondary} />
                        </ListItem>
                    )
                }) : null
            }
        </GenericDialog>
    )
}

export default ListFieldsDialog;
