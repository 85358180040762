import { axiosDelete, axiosGet, axiosPost, axiosPut } from "./instance";

export const apiEntityList = (
    {
        pageNumber,
        pageSize,
        sortCol,
        sortDir,
        search
    },
    callback
) => {
    axiosGet(
        "entity/list",
        {
            page_number: pageNumber,
            page_size: pageSize,
            sort_col: sortCol,
            sort_dir: sortDir,
            search: search
        },
        callback
    );
}

export const apiEntityCreate = ({ name, description, identifier }, callback) => {
    axiosPost("entity/create", { name, description, identifier }, callback);
}

export const apiEntityGet = ({ id }, callback) => {
    axiosGet("entity/" + id, {}, callback);
}

export const apiEntityUpdate = ({ id, name, description, identifier }, callback) => {
    axiosPut("entity/" + id, { name, description, identifier }, callback);
}

export const apiEntityDelete = ({ id }, callback) => {
    axiosDelete("entity/" + id, callback);
}
