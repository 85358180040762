import * as React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import AddchartIcon from "@mui/icons-material/Addchart";
import IconButton from "@mui/material/IconButton";

const ActionButtonMenuSettings = (props) => {

    const { t } = useTranslation(["collection"]);

    const { isMobile, onClick } = props;

    const buttonCommonParams = {
        onClick: onClick,
    }

    const icon = <AddchartIcon />;

    return (
        isMobile ? <IconButton {...buttonCommonParams}>{icon}</IconButton> : (
            <Button startIcon={icon} {...buttonCommonParams}>{t("Settings")}</Button>
        )
    );
}

const mapStateToProps = (state) => {
    return {
        isMobile: state.appSettings.isMobile,
    }
}

export default connect(mapStateToProps)(ActionButtonMenuSettings);
