import React from "react";
import { faPuzzlePiece } from "@fortawesome/free-solid-svg-icons";
import DimensionChip from "./DimensionChip";
import { ItemChipColor } from "../../helpers/colors";

const ItemChip = (props) => {

    const { label, itemId } = props;

    return (
        <DimensionChip
            faIcon={faPuzzlePiece}
            textColor={ItemChipColor.textColor}
            regularColor={ItemChipColor.regularColor}
            hoverColor={ItemChipColor.onHoverColor}
            label={label}
            href={`/item/view/${itemId}`}
        />
    );
}

export default ItemChip;
