import React from "react";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import DimensionChip from "./DimensionChip";
import { apiGetFileUrl } from "../../api/transaction";
import { redirectUrlDownload } from "../../helpers/download";
import { FileChipColor } from "../../helpers/colors";

const FileChip = (props) => {

    const { label, fileId } = props;

    const handleDownload = () => {
        apiGetFileUrl({id: fileId}, (data, successFlag) => {
            if (successFlag > 0) {
                redirectUrlDownload(data.url, label);
            }
        })
    }

    return (
        <DimensionChip
            faIcon={faFile}
            textColor={FileChipColor.textColor}
            regularColor={FileChipColor.regularColor}
            hoverColor={FileChipColor.onHoverColor}
            label={label}
            onClick={handleDownload}
        />
    );
}

export default FileChip;
