import React from "react";
import { useTheme } from "@mui/material/styles";

import Typography from "@mui/material/Typography";

const PageMainItem = (props) => {
    const theme = useTheme();

    return (
        <Typography
            component="h3"
            sx={{
                fontWeight: 800,
                fontSize: "150%",
                color: theme.palette.primary.light,
            }}
        >
            {props.text}
        </Typography>
    );
}

export default PageMainItem;
