import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { Line } from "recharts";
import { XAxis } from "recharts";
import { YAxis } from "recharts";
import { CartesianGrid } from "recharts";
import { Tooltip } from "recharts";
import { Legend } from "recharts";
import { ResponsiveContainer } from "recharts";
import { ComposedChart } from 'recharts';
import { Bar } from 'recharts';

import { formatNumberAmount } from "../../helpers/format";
import { sequentialPallete } from "../../helpers/colors";
import { apiDataseries } from "../../api/aggregations";


const SeasonalWidget = (props) => {

    const { t } = useTranslation(["widget"]);

    const [widgetData, setWidgetData] = useState([]);
    const [years, setYears] = useState([]);
    const [opacity, setOpacity] = useState({});

    const { parcel, filter, numYears, height } = props;

    const currentYear = moment().year();

    const resetOpacity = (v) => {
        return Array.from(Array(numYears).keys()).reduce(
            (acc, key) => {
                acc[`year${key}`] = v;
                return acc;
            },
            {}
        );
    }

    React.useEffect(() => {

        const monthTranslation = {
            1: t("Jan"), 2: t("Feb"), 3: t("Mar"), 4: t("Apr"), 5: t("May"), 6: t("Jun"),
            7: t("Jul"), 8: t("Aug"), 9: t("Sep"), 10: t("Oct"), 11: t("Nov"), 12: t("Dec"),
        };

        const y = Array.from(Array(numYears).keys()).map((tick) => (currentYear - tick));
        setYears(y);
        setOpacity(resetOpacity(1));

        apiDataseries(
            {...filter, dateAggrType: "ym"},
            (data, successFlag) => {
                if (successFlag > 0) {
                    const timeseriesData = Array.from(Array(12).keys()).map((month) => {
                        const entry = {
                            monthNum: month + 1,
                            month: monthTranslation[month + 1],
                        };
                        y.forEach(yr => {
                            const tick = currentYear - yr;
                            entry[`val${tick}`] = 0;
                        })
                        return entry;
                    });

                    data.dataseries.forEach(entry => {
                        const tickDiff = currentYear - entry.year;
                        if (tickDiff >= 0 && tickDiff < numYears) {
                            timeseriesData[entry.month - 1][`val${tickDiff}`] = Math.round(entry[parcel] * 100) / 100;
                        }
                    });
                    setWidgetData(timeseriesData);
                }
            }
        );
    }, [filter, parcel, numYears]);

    const handleMouseEnter = (o) => {
        const { dataKey } = o;
        const opAttr = `year${dataKey.substr("year".length - 1)}`;
        const opDict = resetOpacity(0.1);
        setOpacity({
            ...opDict,
            [opAttr]: 1,
        });
    };

    const handleMouseLeave = (o) => {
        setOpacity(resetOpacity(1));
    };

    return (
        <ResponsiveContainer width="100%" height={height}>
            <ComposedChart data={widgetData} margin={{ top: 10, right: 10, bottom: 10, left: 10 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis tickFormatter={(value, index) => formatNumberAmount(value, null, 0)} />
                <Tooltip formatter={(value, name, props) => formatNumberAmount(value)} />
                <Legend onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter} />
                <Bar name={`${currentYear}`} dataKey={"val0"}
                    barSize={30} fill={sequentialPallete(0)} fillOpacity={opacity.year0} />
                {
                    years.map((yr, idx) => {
                        if (idx > 0) {
                            return (
                                <Line key={`year${idx}`} name={`${yr}`} type="linear"
                                    dataKey={`val${idx}`}
                                    stroke={sequentialPallete(idx)} activeDot={{ r: 3 }}
                                    strokeOpacity={opacity[`year${idx}`]}
                                    fillOpacity={opacity[`year${idx}`]} strokeWidth={3} />
                            );
                        }
                        return null;
                    })
                }
            </ComposedChart>
        </ResponsiveContainer>
    )
}

export default SeasonalWidget;
