import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { apiItemGet } from "../../api/item";
import { updateItem } from "../../store/actions/item";
import DimensionPage from "../../layout/DimensionPage";

const ItemView = (props) => {
    const { t } = useTranslation(["dimension"]);
    const { updateMethod } = props;
    return (
        <DimensionPage dimension="item" title={t("Item")} instanceId={props.match.params.id}
            getMethod={apiItemGet} dataKey="items" updateMethod={updateMethod}
            spec={
                [
                    {id: "name", required: true, label: t("Name"), type: "text", value: null},
                    {id: "description", required: false, label: t("Description"), type: "area", value: null},
                ]
            }
        />
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateMethod: ({ id, name, description }, callback) => dispatch(updateItem({ id, name, description }, callback)),
    }
}

export default connect(null, mapDispatchToProps)(ItemView);
