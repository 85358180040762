import React from "react";
import TextField from "@mui/material/TextField";
import { MobileDatePicker } from "@mui/x-date-pickers";

const DateInput = (props) => {

    const { label, value, onChange, disabled = false, error = null, helperText = null } = props;

    return (
        <MobileDatePicker label={label} inputFormat="yyyy-MM-DD" value={value}
            clearable={true} showDaysOutsideCurrentMonth={true} disableCloseOnSelect={true}
            onChange={onChange} disabled={disabled} showToolbar={true}
            componentsProps={{
                actionBar: {
                    actions: ['today', 'cancel', 'accept'],
                },
            }}
            renderInput={(params) => <TextField {...params} fullWidth={true}  error={error} helperText={helperText} />}
        />
    );
}

export default DateInput;
