import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

import { faCalendar } from "@fortawesome/free-regular-svg-icons";

import { formatNumberAmount } from "../../helpers/format";
import DimensionChip from "../chip/DimensionChip";
import ProductChip from "../chip/ProductChip";
import GenericDialog from "./GenericDialog";

const DeleteTransactionDialog = (props) => {

    const { t } = useTranslation(["transaction"]);
    const theme = useTheme();

    const { open, actionMethod, transactions } = props;

    const stateAction = (action) => {
        actionMethod({action, idList: transactions.map(element => element.id)})
    }

    const text = transactions.length === 1 ? (
        t("Are you sure you want to delete this transaction?")
    ) : t("Are you sure you want to delete these transactions?")

    return (
        <GenericDialog
            open={open}
            title={t("Delete")}
            text={text}
            cancelLabel={t("Cancel")}
            submitLabel={t("Delete")}
            actionMethod={stateAction}
        >
            {
                transactions.map(element => {
                    return (
                        <Paper key={element.id} elevation={2}
                            sx={{marginTop: theme.spacing(1), padding: theme.spacing(1)}}>
                            <Box component="span" sx={{marginRight: theme.spacing(1)}}>
                                <DimensionChip faIcon={faCalendar} label={element.date} chipColor="primary" />
                            </Box>
                            <Box component="span" sx={{marginRight: theme.spacing(1)}}>
                                <ProductChip clickable={false} label={element.productName} />
                            </Box>
                            <Box component="span" sx={{marginRight: theme.spacing(1)}}>
                                <DimensionChip label={formatNumberAmount(element.value)} chipColor="secondary" />
                            </Box>
                        </Paper>
                    )
                })
            }
        </GenericDialog>
    );

    /*
    return (
        <GenericDialog
            open={open}
            title={t("Delete Transaction")}
            text={t("Are you sure you want to delete this transaction?")}
            cancelLabel={t("Cancel")}
            submitLabel={t("Delete")}
            actionMethod={stateAction}
        >
            <Box sx={{ marginTop: theme.spacing(1), textAlign: "center" }}>
                <Typography component="p" variant="h4">{formatNumberAmount(value)}</Typography>
                <Typography color="text.secondary" sx={{ flex: 1 }}>{date}</Typography>
                <Paper elevation={3} sx={{
                    marginTop: theme.spacing(1),
                    padding: theme.spacing(2),
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}>
                    <Avatar sx={{ bgcolor: deepOrange[900] }}>
                        <FontAwesomeIcon icon={faCreditCard} />
                    </Avatar>
                    <Typography variant="h6">{productName}</Typography>
                </Paper>
            </Box>
        </GenericDialog>
    );
    */
}

export default DeleteTransactionDialog;
