import { APP_RESIZE } from "../actions/types";

const initState = {
    width: window.innerWidth,
    height: window.innerHeight,
    isMobile: window.innerWidth <= 768,
};

const appSettingsReducer = (state = initState, action) => {

    switch (action.type) {

        case APP_RESIZE:
            return {
                ...state,
                width: window.innerWidth,
                height: window.innerHeight,
                isMobile: window.innerWidth <= 768,
            };

        default:
            return state;
    }
}

export default appSettingsReducer;
