import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { createTag, updateTag, deleteTag } from "../../store/actions/tag";
import { apiTagList } from "../../api/tag";
import DimensionCollection from "../../components/collection/DimensionCollection";


const TagList = (props) => {

    const { t } = useTranslation(["dim"]);
    const { createMethod, updateMethod, deleteMethod } = props;

    return (
        <DimensionCollection
            createMethod={createMethod}
            updateMethod={updateMethod}
            deleteMethod={deleteMethod}
            listMethod={apiTagList}
            dimensionTitle={t("Tag")}
            listResultAttribute="tags"
            urlMainPath="tag"
            spec={
                [
                    {id: "name", required: true, label: t("Name"), type: "text"},
                    {id: "description", required: false, label: t("Description"), type: "area"}
                ]
            } />
    );
}

const mapStateToProps = (state) => {
    return {
        isMobile: state.appSettings.isMobile,
        canUpdateAccount: state.user.canUpdateAccount,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createMethod: ({ name, description }, callback) => dispatch(createTag({ name, description }, callback)),
        updateMethod: ({ id, name, description }, callback) => dispatch(updateTag({ id, name, description }, callback)),
        deleteMethod: ({ id }, callback) => dispatch(deleteTag({ id }, callback)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TagList);
