import { axiosDelete, axiosGet, axiosPost, axiosPut } from "./instance";

export const apiProductList = (
    {
        pageNumber,
        pageSize,
        sortCol,
        sortDir,
        search,
        productTypes,
        productGroups,
    },
    callback
) => {
    axiosGet(
        "product/list",
        {
            page_number: pageNumber,
            page_size: pageSize,
            sort_col: sortCol,
            sort_dir: sortDir,
            search: search,
            product_types: productTypes,
            product_groups: productGroups,
        },
        callback
    );
}

export const apiProductCreate = (
    {
        name,
        description,
        productGroupId,
        productType,
        instrumentIsin,
        cryptoSymbol
    },
    callback
) => {
    axiosPost(
        "product/create",
        {
            name,
            description,
            product_group_id: productGroupId,
            product_type: productType,
            instrument_isin: instrumentIsin,
            crypto_symbol: cryptoSymbol
        },
        callback
    );
}

export const apiProductGet = ({ id }, callback) => {
    axiosGet("product/" + id, {}, callback);
}

export const apiProductUpdate = (
    {
        id,
        name,
        description,
        productGroupId,
        productType,
        instrumentIsin,
        cryptoSymbol
    },
    callback
) => {
    axiosPut(
        `product/${id}`,
        {
            name,
            description,
            product_group_id: productGroupId,
            product_type: productType,
            instrument_isin: instrumentIsin,
            crypto_symbol: cryptoSymbol
        },
        callback);
}

export const apiProductDelete = ({ id }, callback) => {
    axiosDelete("product/" + id, callback);
}

export const apiProductVariationIsin = ({ id }, callback) => {
    axiosGet("product/variation/isin/" + id, {}, callback);
}

export const apiProductVariationCrypto = ({ id }, callback) => {
    axiosGet("product/variation/crypto/" + id, {}, callback);
}
