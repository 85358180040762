import { ACCOUNT_CREATE, ACCOUNT_UPDATE, ACCOUNT_DELETE } from "./types";
import { createAccountService, updateAccountService, deleteAccountService } from "../../service/account";


export const createAccount = ({ name, description }, callback) => {
    return (dispatch, getState) => {
        createAccountService({ name, description }, (data, successFlag) => {
            if (successFlag > 0) {
                dispatch({ type: ACCOUNT_CREATE, payload: data.account });
            }
            callback(data, successFlag);
        });
    }
};


export const updateAccount = ({ id, name, description }, callback) => {
    return (dispatch, getState) => {
        updateAccountService({ id, name, description }, (data, successFlag) => {
            if (successFlag > 0) {
                dispatch({ type: ACCOUNT_UPDATE, payload: data.account });
            }
            callback(data, successFlag);
        });
    }
};


export const deleteAccount = ({ id }, callback) => {
    return (dispatch, getState) => {
        deleteAccountService({ id }, (data, successFlag) => {
            if (successFlag > 0) {
                dispatch({ type: ACCOUNT_DELETE, payload: id });
            }
            callback(data, successFlag);
        });
    }
};
