import React from "react";
import ProductPage from "../../layout/ProductPage";

const ProductView = (props) => {
    return (
        <ProductPage instanceId={props.match.params.id} />
    )
}

export default ProductView;
