import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import GridViewIcon from "@mui/icons-material/GridView";

const ReportLayoutCard = (props) => {

    const theme = useTheme();
    const { t } = useTranslation(["report"]);

    const { isMobile, canUpdateAccount } = props;
    const { id, name, order, numWidgets = 0 } = props;
    const { handleEdit, handleDelete, handleUp, handleDown } = props;

    const canMoveUp = canUpdateAccount && handleUp !== null && handleUp !== undefined;
    const canMoveDown = canUpdateAccount && handleDown !== null && handleDown !== undefined;

    return (
        <Card>
            <CardHeader
                title={<Typography variant="h4">{name}</Typography>}
                avatar={<Avatar sx={{bgcolor: theme.palette.primary.light}}>{order}</Avatar>} />
            <CardContent>
                <Typography>{t("Number of Widgets")}: {numWidgets}</Typography>
            </CardContent>
            <CardActions disableSpacing={true}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <IconButton disabled={!canMoveUp} onClick={handleUp}>{isMobile ? <ArrowUpwardIcon /> : <WestIcon />}</IconButton>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <IconButton disabled={!canUpdateAccount} onClick={handleDelete}><DeleteIcon /></IconButton>
                        <IconButton disabled={!canUpdateAccount} onClick={handleEdit} sx={{marginX: theme.spacing(2)}}><EditIcon /></IconButton>
                        <IconButton disabled={!canUpdateAccount} component={Link} to={`/report/${id}`}><GridViewIcon /></IconButton>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <IconButton disabled={!canMoveDown} onClick={handleDown}>{isMobile ? <ArrowDownwardIcon /> : <EastIcon />}</IconButton>
                    </Box>
                </Grid>
            </CardActions>
        </Card>
    )
}

const mapStateToProps = (state) => {
    return {
        isMobile: state.appSettings.isMobile,
        canUpdateAccount: state.user.canUpdateAccount,
    }
}

export default connect(mapStateToProps)(ReportLayoutCard);
