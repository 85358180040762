import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import DimensionFormDialog from "../components/dialog/DimensionFormDialog";
import { toast } from "react-toastify";
import DimensionTopLayout from "./DimensionTopLayout";

const DimensionPage = (props) => {

    const { t } = useTranslation(["dimension"]);

    const { instanceId, dimension, title, getMethod, updateMethod, dataKey, spec } = props;

    const [name, setName] = useState(null);
    const [description, setDescription] = useState(null);
    const [editOpen, setEditOpen] = useState(false);
    const [editSpec, setEditSpec] = useState([])

    useEffect(() => {
        if (instanceId) {
            getMethod({ id: instanceId }, (data, successFlag) => {
                if (successFlag > 0) {
                    setName(data[dimension].name);
                    setDescription(data[dimension].description);
                    setEditSpec(spec.map(element => {
                        return {
                            ...element,
                            value: data[dimension][element.id],
                        }
                    }));
                }
            });
        }
    }, [instanceId, dimension, getMethod, spec]);

    if (!instanceId) {
        return null;
    }

    const onEditSubmit = (action, newState) => {

        if (action === "submit") {
            updateMethod({...newState, id: instanceId}, (data, success) => {
                if (success > 0) {
                    setName(newState.name);
                    setDescription(newState.description);
                    toast.success(t("Updated successfully"));
                }
            })
        }

        setEditOpen(false);
    }

    return (
        <React.Fragment>
            <DimensionTopLayout
                title={title}
                name={name}
                description={description}
                onEditClick={() => setEditOpen(true)}
                transactionFilter={{ [dataKey]: [instanceId] }} />
            <DimensionFormDialog
                open={editOpen}
                title={t("Update")}
                type="edit"
                spec={editSpec}
                actionMethod={onEditSubmit} />
        </React.Fragment>
    )
}

export default DimensionPage;
