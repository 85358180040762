import React from "react";

import ActionButtonMenuSort from "../input/button/ActionButtonMenuSort";
import ActionButtonMenuDownload from "../input/button/ActionButtonMenuDownload";
import ActionButtonMenuAdd from "../input/button/ActionButtonMenuAdd";
import ButtonGroupWrapper from "./ButtonGroupWrapper";
import ActionButtonMenuProductTypes from "../input/button/ActionButtonMenuProductTypes";
import ActionButtonMenuProductGroups from "../input/button/ActionButtonMenuProductGroups";

const DimensionCollectionActions = (props) => {

    const { onSortChange, sortMenuItems, onDownloadClick, handleAdd } = props;
    const { handleProductType, selectedProductTypes, handleProductGroup, selectedProductGroups } = props;

    return (
        <ButtonGroupWrapper fullWidth={false}>
            {
                handleProductType ? (
                    <ActionButtonMenuProductTypes handleMenuClick={handleProductType} selected={selectedProductTypes} />
                ) : null
            }
            {
                handleProductGroup ? (
                    <ActionButtonMenuProductGroups handleMenuClick={handleProductGroup} selected={selectedProductGroups} />
                ) : null
            }
            <ActionButtonMenuSort handleMenuClick={onSortChange} menuItems={sortMenuItems} />
            <ActionButtonMenuDownload onClick={onDownloadClick} />
            <ActionButtonMenuAdd onClick={handleAdd} />
        </ButtonGroupWrapper>
    );
}

export default DimensionCollectionActions;
