import React, { useEffect } from "react";
import { connect } from "react-redux";
import { BrowserRouter, Switch, Route, useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { auth } from "./helpers/firebase";
import Navbar from "./components/nav/Navbar";
import { PrivateRoute } from './components/layout/PrivateRoute';
import Landing from "./pages/home/Landing";
import Home from "./pages/home/Home";
import Login from "./pages/user/Login";
import Register from "./pages/user/Register";

import { setUserAuthInfo } from "./store/actions/user";
import { handleWindowSizeChange } from "./store/actions/app";
import { userAuthenticationService } from "./service/user";
import ForgotPassword from "./pages/user/ForgotPassword";
import AccountWidget from "./pages/account/AccountWidget";
import MemberWidget from "./pages/account/MemberWidget";
import CategoryList from "./pages/dim/CategoryList";
import EntityList from "./pages/dim/EntityList";
import ItemList from "./pages/dim/ItemList";
import TagList from "./pages/dim/TagList";
import GroupWidget from "./pages/product/GroupWidget";
import ProductList from "./pages/product/ProductList";
import TransactionForm from "./pages/transaction/TransactionForm";
import UserSettings from "./pages/user/UserSettings";
import Search from "./pages/filter/Search";
import Widget from "./pages/widget/Widget";
import ReportEdition from "./pages/report/ReportEdition";
import TransactionList from "./pages/transaction/TransactionList";
import CategoryView from "./pages/category/CategoryView";
import ItemView from "./pages/item/ItemView";
import EntityView from "./pages/entity/EntityView";
import TagView from "./pages/tag/TagView";
import ProductView from "./pages/product/ProductView";
import GroupView from "./pages/group/GroupView";
import ReportList from "./pages/report/ReportList";


const App = (props) => {

    const [userAuthResponse, setUserAuthResponse] = React.useState(false);

    const currentUid = props.uid;
    const authenticated = currentUid !== null;
    const { setUserAuthInfoProp, handleWindowSizeChangeProp } = props;
    const history = useHistory();

    useEffect(() => {

        window.addEventListener("resize", () => { handleWindowSizeChangeProp(); });

        let unsubscribeFromAuth = async () => {

            auth.onIdTokenChanged(async (user) => {

                if (user && user.emailVerified) {
                    const { uid, displayName, photoURL, email, providerData } = user;
                    const requiresUserDataRefresh = currentUid !== uid;
                    const userToken = await user.getIdToken();
                    localStorage.setItem("userToken", userToken);
                    if (requiresUserDataRefresh) {
                        userAuthenticationService((data, successFlag) => {
                            if (successFlag > 0) {
                                setUserAuthInfoProp({
                                    userAuth: { uid, displayName, photoURL, email, providerData },
                                    userData: data,
                                }, () => { setUserAuthResponse(true); });
                            }
                            else {
                                setUserAuthResponse(true);
                            }
                        });
                    }
                    else {
                        setUserAuthResponse(true);
                    }
                }
                else {
                    localStorage.removeItem("userToken");
                    setUserAuthInfoProp( { userAuth: null, userData: null }, () => {
                        setUserAuthResponse(true);
                    });
                }
            });
        }

        unsubscribeFromAuth();

        return () => {
            // unsubscribeFromAuth();
            window.removeEventListener("resize", () => { handleWindowSizeChangeProp(); });
        }

    }, []);

    return (
        <React.Fragment>
            {
                userAuthResponse
                ? (
                    <BrowserRouter history={history}>
                        <Switch>
                            <Route exact path="/landing" component={Landing} />
                            <React.Fragment>
                                <Navbar />
                                <PrivateRoute exact path="/" component={Home} authenticated={authenticated} />
                                <Route exact path="/user/login" component={Login} />
                                <Route exact path="/user/signup" component={Register} />
                                <Route exact path="/user/forgot-password" component={ForgotPassword} />
                                <PrivateRoute exact path="/user/settings" component={UserSettings} authenticated={authenticated} />
                                <PrivateRoute exact path="/account" component={AccountWidget} authenticated={authenticated} />
                                <PrivateRoute path="/members/:id" component={MemberWidget} authenticated={authenticated} />
                                <PrivateRoute exact path="/category" component={CategoryList} authenticated={authenticated} />
                                <PrivateRoute exact path="/item" component={ItemList} authenticated={authenticated} />
                                <PrivateRoute exact path="/entity" component={EntityList} authenticated={authenticated} />
                                <PrivateRoute exact path="/tag" component={TagList} authenticated={authenticated} />
                                <PrivateRoute exact path="/group" component={GroupWidget} authenticated={authenticated} />
                                <PrivateRoute exact path="/product" component={ProductList} authenticated={authenticated} />
                                <PrivateRoute exact path="/transaction" component={TransactionList} authenticated={authenticated} />
                                <PrivateRoute path="/transaction/edit/:id" component={TransactionForm} isCopy={false} authenticated={authenticated} />
                                <PrivateRoute path="/transaction/copy/:id" component={TransactionForm} isCopy={true} authenticated={authenticated} />
                                <PrivateRoute path="/category/view/:id" component={CategoryView} authenticated={authenticated} />
                                <PrivateRoute path="/item/view/:id" component={ItemView} authenticated={authenticated} />
                                <PrivateRoute path="/entity/view/:id" component={EntityView} authenticated={authenticated} />
                                <PrivateRoute path="/tag/view/:id" component={TagView} authenticated={authenticated} />
                                <PrivateRoute path="/product/view/:id" component={ProductView} authenticated={authenticated} />
                                <PrivateRoute path="/group/view/:id" component={GroupView} authenticated={authenticated} />
                                <PrivateRoute path="/filter" component={Search} authenticated={authenticated} />
                                <PrivateRoute path="/widget" component={Widget} authenticated={authenticated} />
                                <PrivateRoute exact path="/report" component={ReportList} authenticated={authenticated} />
                                <PrivateRoute path="/report/:id" component={ReportEdition} authenticated={authenticated} />
                            </React.Fragment>
                        </Switch>
                    </BrowserRouter>
                )
                : null
            }
            <ToastContainer 
                position="bottom-left"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={true}
                rtl={false}
                draggable={true}
                pauseOnHover={true}>
            </ToastContainer>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        uid: state.user.uid,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUserAuthInfoProp: ({ userAuth, userData }, callback) => dispatch(setUserAuthInfo({ userAuth, userData }, callback)),
        handleWindowSizeChangeProp: () => dispatch(handleWindowSizeChange()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
