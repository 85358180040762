import { DIM_CREATE, DIM_UPDATE, DIM_DELETE } from "./types";
import { apiProductCreate, apiProductUpdate, apiProductDelete } from "../../api/product";


export const createProduct = ({ name, description, productGroupId, productType, instrumentIsin, cryptoSymbol }, callback) => {
    return (dispatch, getState) => {
        apiProductCreate({ name, description, productGroupId, productType, instrumentIsin, cryptoSymbol }, (data, successFlag) => {
            if (successFlag > 0) {
                dispatch({ type: DIM_CREATE, payload: { data: data.product, attr: "products" } });
            }
            callback(data, successFlag);
        });
    }
};


export const updateProduct = ({ id, name, description, productGroupId, productType, instrumentIsin, cryptoSymbol }, callback) => {
    return (dispatch, getState) => {
        apiProductUpdate({ id, name, description, productGroupId, productType, instrumentIsin, cryptoSymbol }, (data, successFlag) => {
            if (successFlag > 0) {
                dispatch({ type: DIM_UPDATE, payload: { data: data.product, attr: "products" } });
            }
            callback(data, successFlag);
        });
    }
};


export const deleteProduct = ({ id }, callback) => {
    return (dispatch, getState) => {
        apiProductDelete({ id }, (data, successFlag) => {
            if (successFlag > 0) {
                dispatch({ type: DIM_DELETE, payload: { data: id, attr: "products" } });
            }
            callback(data, successFlag);
        });
    }
};
