import { DIM_CREATE, DIM_UPDATE, DIM_DELETE } from "./types";
import { apiTagCreate, apiTagUpdate, apiTagDelete } from "../../api/tag";


export const createTag = ({ name, description }, callback) => {
    return (dispatch, getState) => {
        apiTagCreate({ name, description }, (data, successFlag) => {
            if (successFlag > 0) {
                dispatch({ type: DIM_CREATE, payload: { data: data.tag, attr: "tags" } });
            }
            callback(data, successFlag);
        });
    }
};


export const updateTag = ({ id, name, description }, callback) => {
    return (dispatch, getState) => {
        apiTagUpdate({ id, name, description }, (data, successFlag) => {
            if (successFlag > 0) {
                dispatch({ type: DIM_UPDATE, payload: { data: data.tag, attr: "tags" } });
            }
            callback(data, successFlag);
        });
    }
};


export const deleteTag = ({ id }, callback) => {
    return (dispatch, getState) => {
        apiTagDelete({ id }, (data, successFlag) => {
            if (successFlag > 0) {
                dispatch({ type: DIM_DELETE, payload: { data: id, attr: "tags" } });
            }
            callback(data, successFlag);
        });
    }
};
