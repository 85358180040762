import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from "@mui/x-date-pickers";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./helpers/i18n";
import theme from "./helpers/theme";
import Loading from "./components/layout/Loading";

import rootReducer from "./store/reducers/root";

const store = createStore(
    rootReducer,
    applyMiddleware(thunk)
);

ReactDOM.render(
    <React.Suspense fallback={<Loading />}>
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <App />
                </LocalizationProvider>
                <Loading />
            </ThemeProvider>
        </Provider>
    </React.Suspense>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
