import React from "react";
import { useTheme } from "@mui/material/styles";

import Typography from "@mui/material/Typography";

const PageSecondaryItem = (props) => {
    const theme = useTheme();

    return (
        <Typography
            sx={{
                fontSize: "110%",
                color: theme.palette.grey[600],
            }}
        >
            {props.text}
        </Typography>
    );
}

export default PageSecondaryItem;
