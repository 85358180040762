import { toast } from "react-toastify";
import ErrorMessageToast from "../components/layout/ErrorMessageToast";

export const translateApiErrorType = (errorType) => {
    const errorTranslation = {
        "UNKNOWN_ERROR": "Unknown Error",
        "NOT_AUTHORIZED": "Operation Not Authorized",
        "REQUIRED_FIELD": "Field is required",
        "RESOURCE_NOT_FOUND": "The resource was not found",
        "USER_NOT_FOUND": "The user is not registered in the platform yet",
        "MINIMUM_NR_ADMINS": "The account must have at least one admin",
        "OPERATION_NOT_ALLOWED": "Operation not allowed",
        "METHOD_NOT_FOUND": "Method not found",
        "INVALID_EMAIL": "Invalid Email",
        "MEMBER_ALREADY_EXISTS": "This member already exists",
        "NO_DEFAULT_ACCOUNT": "You must set a default account to proceed",
        "TRANSACTION_ASSOCIATED": "Impossible to delete because there are transactions associated",
        "PRODUCT_ASSOCIATED": "Impossible to delete because there are products associated",
        "OBJECT_ASSOCIATED": "Impossible to delete because there are objects associated",
        "DUPLICATED_NAME": "The value already exists",
        "INVALID_FINANCIAL_INSTRUMENT": "Product is not an instrument or the ISIN was not found",
        "INSUFFICIENT_DATA_FIN": "It was not possible to find this instrument and it must be updated manually",
        "INVALID_CRYPTO_PRODUCT": "Product is not a crypto currency or the symbol was not found",
        "INSUFFICIENT_DATA_CRY": "It was not possible to find this crypto symbol and it must be updated manually",
        "INVALID_VALUE": "Invalid value",
    };
    return errorType in errorTranslation ? errorTranslation[errorType] : (errorTranslation["UNKNOWN_ERROR"] + ": " + errorType);
}

export const handleFieldErrors = (successFlag, errorData, translationPath) => {
    if (successFlag === 0) {
        errorData.forEach(element => {
            toast.error(
                <ErrorMessageToast errorType={element.error_type} fieldName={element.field_name} translationPath={translationPath} />
            );
        });
    }
    else {
        toast.error(errorData);
    }
}
