import * as React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import IconButton from "@mui/material/IconButton";
import { Divider } from "@mui/material";

const ActionButtonMenuFilterOptions = (props) => {

    const { t } = useTranslation(["collection"]);
    const { handleMenuClick, isMobile } = props;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => { setAnchorEl(event.currentTarget) };
    const handleClose = () => { setAnchorEl(null) };

    const handleMenu = (menuId) => {
        handleClose();
        handleMenuClick(menuId);
    }

    const buttonCommonParams = {
        onClick: handleClick,
    }

    const icon = <MenuOpenIcon />;
    const menuItemMinWidth = {minWidth: "200px"};

    return (
        <React.Fragment>
            {
                isMobile ? (
                    <IconButton {...buttonCommonParams}>{icon}</IconButton>
                ) : (
                    <Button startIcon={icon} {...buttonCommonParams}>{t("Selection")}</Button>
                )
            }
            <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
                <MenuItem sx={menuItemMinWidth} onClick={() => handleMenu("new")}>{t("New")}</MenuItem>
                <MenuItem sx={menuItemMinWidth} onClick={() => handleMenu("copy")}>{t("Copy")}</MenuItem>
                <Divider />
                <MenuItem sx={menuItemMinWidth} onClick={() => handleMenu("open")}>{t("Open")}</MenuItem>
                <Divider />
                <MenuItem sx={menuItemMinWidth} onClick={() => handleMenu("save")}>{t("Save")}</MenuItem>
                <Divider />
                <MenuItem sx={menuItemMinWidth} onClick={() => handleMenu("delete")}>{t("Delete")}</MenuItem>
            </Menu>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        isMobile: state.appSettings.isMobile,
    }
}

export default connect(mapStateToProps)(ActionButtonMenuFilterOptions);
