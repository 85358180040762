import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import moment from "moment";
import { useTheme } from "@mui/material/styles";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import AppBar from "@mui/material/AppBar";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import SectionTitle from "../../components/typography/SectionTitle";
import TransactionCollection from "../../components/collection/TransactionCollection";
import AmountCardCollection from "../../components/collection/AmountCardCollection";
import NoAccountInfo from "../../layout/NoAccountInfo";

const Home = (props) => {

    const { t } = useTranslation(["misc"]);
    const theme = useTheme();

    const { isMobile, groups, accounts, defaultAccountId } = props;

    const [selectedGroupIndex, setSelectedGroupIndex] = React.useState(0);
    const selectedGroup = groups.length > selectedGroupIndex ? groups[selectedGroupIndex].id : null;
    
    // list product card parameters
    const productListColumns = isMobile ? 1 : 3;

    // check if requirements are meet
    const hasAccounts = accounts !== undefined && accounts.length > 0;
    const hasDefaultAccount = defaultAccountId !== null;
    const hasGroups = groups !== undefined && groups.length > 0;
    if (!hasAccounts || !hasDefaultAccount || !hasGroups) {
        return (
            <NoAccountInfo
                hasAccounts={hasAccounts}
                hasDefaultAccount={hasDefaultAccount}
                hasGroups={hasGroups} />
        )
    }

    return (
        <React.Fragment>
            <Box m={theme.spacing(1)}>
                <Paper sx={{marginBottom: theme.spacing(1)}}>
                    <AppBar position="static" color="default">
                        <Tabs onChange={(event, newValue) => { setSelectedGroupIndex(newValue) }} value={selectedGroupIndex}
                            indicatorColor="primary" textColor="primary" variant="scrollable" scrollButtons>
                            {
                                groups.map((entry, index) => (
                                    <Tab key={entry.id} label={entry.name} value={index} id={entry.id} />
                                ))
                            }
                        </Tabs>
                    </AppBar>
                    <Box sx={{padding: theme.spacing(1)}}>
                        <AmountCardCollection dimension="product" filter={{groups: [selectedGroup]}}
                            parcel="bal" labelTotal={t("Total")} columns={productListColumns} />
                    </Box>
                </Paper>
                <Paper sx={{padding: theme.spacing(1)}}>
                    <SectionTitle text={t("Transactions")} />
                    <Box sx={{marginTop: theme.spacing(1)}}>
                        <TransactionCollection endDate={moment().add(3, "days").format("YYYY-MM-DD")} />
                    </Box>
                </Paper>
            </Box>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        isMobile: state.appSettings.isMobile,
        groups: state.user.groups,
        accounts: state.user.accounts,
        defaultAccountId: state.user.defaultAccountId,
    }
}

export default connect(mapStateToProps)(Home);
