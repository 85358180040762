import React from "react";
import { connect } from "react-redux";
import { useTheme } from "@mui/material/styles";

import Box from "@mui/material/Box";
import Collapse from '@mui/material/Collapse';
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';


import { useTranslation } from "react-i18next";

const MobileListItem = (props) => {

    const { t } = useTranslation(["transaction"]);
    const theme = useTheme();

    const [open, setOpen] = React.useState(false);

    const { dataItem, mainAttribute, mainIndicator, columns, sx, triggerClose } = props;

    const transformElements = columns.map((entry) => {
        const isMainAttribute = entry.field === mainAttribute;
        const isMainIndicator = entry.field === mainIndicator;
        const isAction = entry.type === "actions";
        const useRenderMethod = entry.useRenderMethodOnMobile === undefined ? false : entry.useRenderMethodOnMobile;
        return {
            isMainAttribute: isMainAttribute,
            isMainIndicator: isMainIndicator,
            isAction: isAction,
            headerName: entry.headerName,
            content: isAction || useRenderMethod ? entry.renderCell({row: dataItem}) : (
                entry.type === "boolean"
                ? (
                    dataItem[entry.field] ? <CheckIcon /> : <ClearIcon />
                )
                : (
                    dataItem[entry.field] === null || dataItem[entry.field] === ""
                    ? t("Undefined")
                    : dataItem[entry.field]
                )
            ),
        }
    });

    const attributeElement = transformElements.filter((entry) => entry.isMainAttribute)[0];
    const indicatorElement = transformElements.filter((entry) => entry.isMainIndicator)[0];
    const actionElements = transformElements.filter((entry) => entry.isAction);
    const actions = actionElements.length > 0 ? actionElements[0] : undefined;

    const otherAttributes = transformElements.filter((entry) => (
        !entry.isMainAttribute && !entry.isMainIndicator && !entry.isAction
    ));

    const switchOpen = () => { setOpen(!open); }

    React.useEffect(() => {
        setOpen(false);
    }, [triggerClose]);

    return (
        <React.Fragment>
            <Paper sx={sx}>
                <Grid container>
                    <Grid item xs={8} alignSelf="center">
                        <IconButton sx={{ padding: 0 }} onClick={switchOpen} size="large">
                            {open ? <ArrowDropDownIcon fontSize="inherit" /> : <ArrowRightIcon fontSize="inherit" />}
                        </IconButton>
                        <Box component="span" sx={{ fontWeight: "bold" }}>{attributeElement.content}</Box>
                    </Grid>
                    <Grid container item xs={4} justifyContent="right" alignSelf="center">
                        {indicatorElement.content}
                    </Grid>
                </Grid>
                <Collapse in={open}>
                    {
                        otherAttributes.map((attr, idx) => (
                            <Box sx={{ marginTop: theme.spacing(1) }} key={`attribute${idx}`} textAlign="center">
                                <Typography>{attr.content}</Typography>
                                <Typography sx={{ fontStyle: "italic" }} variant="subtitle2">{attr.headerName}</Typography>
                            </Box>
                        ))
                    }
                    {actions && <Box textAlign="right">{actions.content}</Box>}
                </Collapse>
            </Paper>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        canUpdateAccount: state.user.canUpdateAccount,
    }
}

export default connect(mapStateToProps)(MobileListItem);
