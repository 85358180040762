import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Select from "../select/Select";
import FormTextInput from "../text/FormTextInput";
import SecondaryButton from "../button/SecondaryButton";
import DeleteIconButton from "../button/DeleteIconButton";
import EditIconButton from "../button/EditIconButton";
import SaveIconButton from "../button/SaveIconButton";

const SelectableLabelInput = (props) => {
    
    const theme = useTheme();
    const { t } = useTranslation(["widget"]);

    const { id, options, selectLabel, handleAction, state, spacing = 2 } = props;

    const [optionSelected, setOptionSelected] = useState(null);
    const [labelInputValue, setLabelInputValue] = useState(null);
    const [editMode, setEditMode] = useState(true);

    useEffect(() => {
        if (state) {
            const s = state.split("|")[0];
            const l = state.substring(state.indexOf("|") + 1);
            setOptionSelected(s !== "" ? s : null);
            setLabelInputValue(l !== "" ? l : null);
            setEditMode(false);
        }
    }, [state]);

    const handleSelect = (e, newValue) => {
        setOptionSelected(newValue ? newValue.id : null);
        setLabelInputValue(newValue.base);
    }

    const onSaveClick = () => {
        if (state) {
            setEditMode(false);
        }
        const copyOptionSelected = optionSelected;
        const copyLabelInputValue = labelInputValue;
        setOptionSelected(null);
        setLabelInputValue(null);
        handleAction("save", copyOptionSelected, copyLabelInputValue);
    }

    const onDeleteClick = () => {
        handleAction("delete", optionSelected, labelInputValue);
    }

    const onEditClick = () => {
        setEditMode(true);
    }

    const sxAlignment = {display: 'flex', alignItems: 'center', justifyContent: 'center' };

    return (
        <React.Fragment>
            <Grid container spacing={spacing}>
                <Grid item xs={5}>
                    <Select options={options} id={`${id}__select`} selected={optionSelected}
                        label={selectLabel} onChange={handleSelect} disabled={!editMode} />
                </Grid>
                <Grid item xs={5}>
                    <FormTextInput id={`${id}__label`} label={t("Label")} value={labelInputValue}
                        onChange={(e) => setLabelInputValue(e.target.value)} disabled={!editMode} />
                </Grid>
                {
                    editMode ? (
                        <Grid item xs={2} sx={sxAlignment}>
                            <SaveIconButton onClick={onSaveClick} />
                        </Grid>
                    ) : (
                        <React.Fragment>
                            <Grid item xs={1} sx={sxAlignment}>
                                <EditIconButton onClick={onEditClick} />
                            </Grid>
                            <Grid item xs={1} sx={sxAlignment}>
                                <DeleteIconButton onClick={onDeleteClick} />
                            </Grid>
                        </React.Fragment>
                    )
                }
            </Grid>
        </React.Fragment>
    )
}

export default SelectableLabelInput;
