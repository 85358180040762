import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { connect } from "react-redux";

import Box from "@mui/material/Box";
import PageMainItem from "../../components/typography/PageMainItem";
import PageTitle from "../../components/typography/PageTitle";
import ActionButtonMenuSettings from "../../components/input/button/ActionButtonMenuSettings";
import ActionButtonMenuFilterOptions from "../../components/input/button/ActionButtonMenuFilterOptions";
import WidgetFormDialog from "../../components/dialog/WidgetFormDialog";
import DimensionFormDialog from "../../components/dialog/DimensionFormDialog";
import { apiWidgetCreate, apiWidgetDelete, apiWidgetList, apiWidgetUpdate } from "../../api/widget";
import { apiFilterList } from "../../api/filter";
import { toast } from "react-toastify";
import ListDialog from "../../components/dialog/ListDialog";
import WidgetContainer from "../../components/widget/WidgetContainer";
import { Grid } from "@mui/material";
import FormNumericInput from "../../components/input/text/FormNumericInput";
import DeleteDialog from "../../components/dialog/DeleteDialog";

const initState = {
    generic: {
        type: null,
        primaryFilterId: null,
        secondaryFilterId: null,
    },
    bar: {
        items: null,
        dimension: null,
        sortParcel: null,
        sortDirection: null,
        parcels: [],
    },
    list: {
        labelTotal: null,
        parcel: null,
        dimension: null,
        columns: null,
    },
    periodic: {
        frequency: null,
        parcel: null,
        hasProduct: false,
        hasCategory: false,
        hasItem: false,
        hasEntity: false,
    },
    pie: {
        items: null,
        dimension: null,
        transactionType: null,
    },
    seasonal: {
        parcel: null,
        numYears: null,
    },
    timeseries: {
        frequency: null,
        lines: [],
        bars: [],
    },
    totals: {
        parcels: [],
        columns: null,
    },
    transactions: {
        recordType: null, // table or cards
    },
}

const Widget = (props) => {

    const theme = useTheme();
    const { t } = useTranslation(["widget"]);

    const [widgetId, setWidgetId] = useState(null);
    const [widgetName, setWidgetName] = useState(null);
    const [widgetSettings, setWidgetSettings] = useState(initState);

    const [widgets, setWidgets] = useState([]);
    const [availableFilters, setAvailableFilters] = useState([]);

    const [width, setWidth] = useState(12);
    const [height, setHeight] = useState(500);

    const [widgetFormDialogOpen, setWidgetFormDialogOpen] = useState(false);
    const [nameFormOpen, setNameFormOpen] = useState(false);
    const [widgetListOpen, setWidgetListOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);

    // auto generated aux vars
    const selectedPrimaryFilter = widgetSettings.generic.primaryFilterId && availableFilters.length > 0 ? (
        availableFilters.filter(element => element.id === widgetSettings.generic.primaryFilterId)[0]
    ) : null;
    const selectedSecondaryFilter = widgetSettings.generic.secondaryFilterId && availableFilters.length > 0 ? (
        availableFilters.filter(element => element.id === widgetSettings.generic.secondaryFilterId)[0]
    ) : null;
    const widgetParams = widgetSettings.generic.type ? widgetSettings[widgetSettings.generic.type.toLowerCase()] : {};

    const onSettingsClick = () => {
        setWidgetFormDialogOpen(true);
    }

    const onOptionClick = (action) => {
        
        if (action === "new") {
            setWidgetId(null);
            setWidgetName(null);
            setWidgetSettings(initState);
        }

        if (action === "save") {
            setNameFormOpen(true);
        }

        if (action === "open") {
            setWidgetListOpen(true);
        }

        if (action === "copy") {
            if (widgetId) {
                setWidgetId(null);
                const newName = `${t("COPY OF")} ${widgetName}`;
                setWidgetName(newName);
                toast.info(t("Widget settings copied but not saved"))
            }
            else {
                toast.warning(t("Please, open a widget settings first"));
            }
        }

        if (action === "delete") {
            setDeleteOpen(false);
        }
    }

    const handleDelete = (action) => {
        if (action === "submit") {
            if (widgetId) {
                apiWidgetDelete({id: widgetId}, (data, success) => {
                    if (success > 0) {
                        const deletedWidget = widgets.filter(element => element.id !== widgetId);
                        setWidgets(deletedWidget);
                        setWidgetId(null);
                        setWidgetName(null);
                        setWidgetSettings(initState);
                    }
                })
            }
            else {
                toast.warning(t("Please, open a widget first"));
            }
        }

        setDeleteOpen(false);
    }

    const onSettingsSubmit = (action, newState) => {

        if (action === "submit") {
            setWidgetSettings(newState);
        }

        setWidgetFormDialogOpen(false);
    }

    const onListSubmit = (settings) => {
        if (settings) {
            setWidgetId(settings.id);
            setWidgetName(settings.name);
            setWidgetSettings(
                {
                    ...widgetSettings,
                    generic: {
                        type: settings.type,
                        primaryFilterId: settings.primaryFilterId,
                        secondaryFilterId: settings.secondaryFilterId,
                    },
                    [settings.type.toLowerCase()]: settings.params,
                }
            );
        }
        setWidgetListOpen(false);
    }

    const onEditSubmit = (action, newState) => {

        if (action === "submit") {
            if (widgetId) {

                apiWidgetUpdate(
                    {
                        id: widgetId,
                        name: newState.name,
                        type: widgetSettings.generic.type,
                        primaryFilterId: widgetSettings.generic.primaryFilterId,
                        secondaryFilterId: widgetSettings.generic.secondaryFilterId,
                        ...widgetSettings[widgetSettings.generic.type.toLowerCase()],
                    },
                    (data, success) => {
                        if (success > 0) {
                            toast.success(t("Widget updated successfully"));
                            setWidgetName(newState.name);
                            let updatedWidget = widgets.map(element => {
                                if (element.id === widgetId) {
                                    return {
                                        id: widgetId,
                                        name: newState.name,
                                        type: widgetSettings.generic.type,
                                        primaryFilterId: widgetSettings.generic.primaryFilterId,
                                        secondaryFilterId: widgetSettings.generic.secondaryFilterId,
                                        params: widgetSettings[widgetSettings.generic.type.toLowerCase()],
                                    }
                                }
                                return element;
                            })
                            updatedWidget.sort((a, b) => (a.name > b.name) ? 1 : ((a.name < b.name) ? -1 : 0));
                            setWidgets(updatedWidget);
                        }
                    }
                )

            }
            else {

                apiWidgetCreate(
                    {
                        name: newState.name,
                        type: widgetSettings.generic.type,
                        primaryFilterId: widgetSettings.generic.primaryFilterId,
                        secondaryFilterId: widgetSettings.generic.secondaryFilterId,
                        ...widgetSettings[widgetSettings.generic.type.toLowerCase()],
                    },
                    (data, success) => {
                        if (success > 0) {
                            toast.success(t("Widget created successfully"));
                            setWidgetId(data.widget.id);
                            setWidgetName(newState.name);
                            let createdWidget = widgets.slice();
                            createdWidget.push(data.widget);
                            createdWidget.sort((a, b) => (a.name > b.name) ? 1 : ((a.name < b.name) ? -1 : 0));
                            setWidgets(createdWidget);
                        }
                    }
                )

            }
        }

        setNameFormOpen(false);
    }

    useEffect(() => {
        apiWidgetList((data, success) => {
            if (success > 0) {
                setWidgets(data.widgets);
            }
        })
        apiFilterList((data, success) => {
            if (success > 0) {
                setAvailableFilters(data.filters)
            }
        });
    }, []);

    return (
        <React.Fragment>
            <Box sx={{margin: theme.spacing(1), padding: theme.spacing(1)}}>
                <Box sx={{display: "flex"}}>
                    <Box sx={{flexGrow: 1}}><PageTitle text={t("Widgets")} /></Box>
                    <Box>
                        <ActionButtonMenuSettings onClick={onSettingsClick} />
                        <ActionButtonMenuFilterOptions handleMenuClick={onOptionClick} />
                    </Box>
                </Box>
                { widgetName ? <Box sx={{marginTop: theme.spacing(1)}}><PageMainItem text={widgetName} /></Box> : null }
                <Grid container spacing={theme.spacing(1)} sx={{marginTop: theme.spacing(1), marginBottom: theme.spacing(1)}}>
                    <Grid item xs={6}>
                        <FormNumericInput id="width" label={t("Width")} value={width}
                            onChange={(e) => {setWidth(parseInt(e.target.value))}} maxValue={12} minValue={1} />
                    </Grid>
                    <Grid item xs={6}>
                        <FormNumericInput id="height" label={t("Height")} value={height}
                            onChange={(e) => {setHeight(parseInt(e.target.value))}} minValue={50} />
                    </Grid>
                    <Grid item xs={width}>
                        <WidgetContainer title={t("Preview")} type={widgetSettings.generic.type}
                            primaryFilter={selectedPrimaryFilter} secondaryFilter={selectedSecondaryFilter}
                            params={widgetParams} height={height} />
                    </Grid>
                </Grid>
            </Box>
            <WidgetFormDialog
                open={widgetFormDialogOpen}
                editState={widgetSettings}
                actionMethod={onSettingsSubmit}
                filters={availableFilters} />
            <DimensionFormDialog
                open={nameFormOpen}
                title={widgetId ? t("Update") : t("Create")}
                type="edit"
                spec={[{id: "name", required: true, label: t("Name"), type: "text", value: widgetName}]}
                actionMethod={onEditSubmit}
                fullScreen={false} />
            <ListDialog
                open={widgetListOpen}
                title={t("Widgets")}
                text={t("Select a widget to open")}
                attrName={"name"}
                selected={widgetId}
                items={widgets}
                onClose={onListSubmit} />
            <DeleteDialog
                open={deleteOpen}
                actionMethod={handleDelete}
                text={t("Are you sure you want to delete this widget?")} />
        </React.Fragment>
    )

}

const mapStateToProps = (state) => {
    return {
        isMobile: state.appSettings.isMobile,
    }
}

export default connect(mapStateToProps)(Widget);
