import { DIM_CREATE, DIM_UPDATE, DIM_DELETE } from "./types";
import { apiEntityCreate, apiEntityUpdate, apiEntityDelete } from "../../api/entity";


export const createEntity = ({ name, description, identifier }, callback) => {
    return (dispatch, getState) => {
        apiEntityCreate({ name, description, identifier }, (data, successFlag) => {
            if (successFlag > 0) {
                dispatch({ type: DIM_CREATE, payload: { data: data.entity, attr: "entities" } });
            }
            callback(data, successFlag);
        });
    }
};


export const updateEntity = ({ id, name, description, identifier }, callback) => {
    return (dispatch, getState) => {
        apiEntityUpdate({ id, name, description, identifier }, (data, successFlag) => {
            if (successFlag > 0) {
                dispatch({ type: DIM_UPDATE, payload: { data: data.entity, attr: "entities" } });
            }
            callback(data, successFlag);
        });
    }
};


export const deleteEntity = ({ id }, callback) => {
    return (dispatch, getState) => {
        apiEntityDelete({ id }, (data, successFlag) => {
            if (successFlag > 0) {
                dispatch({ type: DIM_DELETE, payload: { data: id, attr: "entities" } });
            }
            callback(data, successFlag);
        });
    }
};
