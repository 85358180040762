import React from "react";
import { connect } from "react-redux";
import { Redirect, Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme, styled } from "@mui/material/styles";
import Container from "@mui/material/Container";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";

import { AnonymousUserBox, UserAvatar } from "../../components/styled/user";
import { signInWithEmailAndPassword, signInWithGoogle, logout } from "../../helpers/firebase";
import FormTextInput from "../../components/input/text/FormTextInput";

const SubmitButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(3, 0, 2),
}));

const Login = (props) => {

    const theme = useTheme();
    const { t } = useTranslation(["user"]);

    const [state, setState] = React.useState({
        email: "",
        password: "",
    });

    if (props.uid) {
        return <Redirect to={"/"} />;
    }

    const handleSubmitButton = async (e) => {
        const user = await signInWithEmailAndPassword(state.email, state.password);
        if (user) {
            if (user.emailVerified) {
                toast.info(t("User signed in with success"));
            }
            else {
                await logout();
                toast.info(
                    t("Your email is not not verified. Please, check your email and follow the instructions."),
                    {autoClose: 5000}
                );
            }
        }
        else {
            toast.info(t("Invalid email / password"));
        }
    }

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.id]: e.target.value
        });
    }

    const handleGoogleButton = async (e) => {
        const user = await signInWithGoogle();
        if (user) {
            toast.info(t("User signed in with success"));
        }
    }

    return (
        <Container component="main" maxWidth="xs">
            <AnonymousUserBox>
                <UserAvatar><LockOutlinedIcon /></UserAvatar>
                <Typography component="h1" variant="h5">{t("Sign In")}</Typography>
                <Box sx={{ marginTop: theme.spacing(1) }}>
                    <FormTextInput
                        required={true}
                        id="email"
                        label={t("Email Address")}
                        type="email"
                        onChange={handleChange}
                    />
                    <FormTextInput
                        required={true}
                        id="password"
                        onChange={handleChange}
                        label={t("Password")}
                        type="password"
                    />
                    <SubmitButton type="submit" fullWidth variant="contained" color="primary" onClick={handleSubmitButton}>
                        {t("Sign In")}
                    </SubmitButton>
                    <Grid container>
                        <Grid item xs><Link component={RouterLink} to="/user/forgot-password" variant="body2">{t("Forgot Password")}</Link></Grid>
                        <Grid item><Link component={RouterLink} to="/user/signup" variant="body2">{t("Don't have an account")}</Link></Grid>
                    </Grid>
                </Box>
                <Box sx={{ margin: theme.spacing(3, 0, 1, 0) }}>{t("OR")}</Box>
                <SubmitButton fullWidth variant="outlined" color="primary"
                    onClick={handleGoogleButton} startIcon={<FontAwesomeIcon icon={faGoogle} />}>
                    {t("Sign in with Google")}
                </SubmitButton>
            </AnonymousUserBox>
        </Container>
    );
}

const mapStateToProps = (state) => {
    return {
        uid: state.user.uid,
    }
}

export default connect(mapStateToProps)(Login);
