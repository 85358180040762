export const validateUserInput = (name, value, t, comparePassword = null) => {

    if (name === "name") {
        if (value) {
            return "";
        }
        else {
            return t("This field is required");
        }
    }

    if (name === "email") {
        if (value) {
            return /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(value) ? "" : t("Email is not valid");
        }
        else {
            return t("This field is required");
        }
    }

    if (name === "password") {
        if (value) {
            let passwordErrors = [];
            var lowerCaseLetters = /[a-z]/g; // Validate lowercase letters
            if (!value.match(lowerCaseLetters)) {
                passwordErrors.push(t("Lowercase letters required"));
            }
            var upperCaseLetters = /[A-Z]/g; // Validate capital letters
            if (!value.match(upperCaseLetters)) {
                passwordErrors.push(t("Uppercase letters required"));
            }
            var numbers = /[0-9]/g; // Validate numbers
            if (!value.match(numbers)) {
                passwordErrors.push(t("Numeric characters required"))
            }
            const maxLen = 6; // Validate length
            if (value.length < maxLen) {
                passwordErrors.push(t("Length must be higher or equal to", { maxLen }));
            }
            return passwordErrors ? passwordErrors.join(" | ") : "";
        }
        else {
            return t("This field is required");
        }
    }

    if (name === "passwordConfirm") {
        if (value) {
            return value === comparePassword ? "" : t("Doesn't match with password");
        }
        else {
            return t("This field is required");
        }
    }

    return "";
}
