import { toast } from "react-toastify";
import ErrorMessageToast from "../components/layout/ErrorMessageToast";

export const getInitials = (n) => {
    let initials = n[0];
    let idx = n.lastIndexOf(" ");
    if (idx > 0 && idx + 1 < n.length) {
        initials += n[idx + 1];
    }
    return initials;
}

export const getIndexInArrayByAttr = (array, attr, value) => {
    for (var i = 0; i < array.length; i += 1) {
        if (array[i][attr] === value) {
            return i;
        }
    }
    return -1;
}

export const pascalToSnakeCase = (s) => {
    return s.replace(/(?:^|\.?)([A-Z])/g, (x, y) => ("_" + y.toLowerCase())).replace(/^_/, "");
}

export const snakeToPascalCase = (s, firstUpper = false) => {
    return s.split("/").map(snake => (
        snake.split("_").map((substr, idx) => (
            (idx === 0 ? (firstUpper ? substr.charAt(0).toUpperCase() : substr.charAt(0)) : substr.charAt(0).toUpperCase()) + substr.slice(1)
        )).join("")
    )).join("/");
}

export const snakeToCamel = (obj) => {

    if (typeof obj === 'object' && Array.isArray(obj)) {
        return obj.map(entry => snakeToCamel(entry));
    }

    if (typeof obj !== "object" || obj === null) {
        return obj;
    }

    let newObj = {};
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            let newKey = key.replace(/_([a-z])/g, (match) => {
                return match[1].toUpperCase();
            });
            newObj[newKey] = (typeof obj[key] === 'object') ? snakeToCamel(obj[key]) : obj[key];
        }
    }
    return newObj;
}

export const camelToSnake = (s) => {
    return s.replace(/(?:^|\.?)([A-Z])/g, (x, y) => ("_" + y.toLowerCase())).replace(/^_/, "");
}

/**
 * formatCurrencyAmount(n, x, s, c)
 * 
 * @param integer n: length of decimal
 * @param integer x: length of whole part
 * @param mixed   s: sections delimiter
 * @param mixed   c: decimal delimiter
 */
export const formatCurrencyAmount = (val, currSign = 'EUR', n = 2, x = 3, s = '.', c = ',') => {
    const checkZero = Math.round(val * 10**n) === 0 ? 0 : val;
    const num = checkZero.toFixed(Math.max(0, ~~n));
    const re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')';
    const conv = (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
    if (currSign) return `${conv} ${currSign}`;
    return `${conv}`;
}

/**
 * formatPercentage(n, x, s, c)
 * 
 * @param integer n: length of decimal
 * @param integer x: length of whole part
 * @param mixed   s: sections delimiter
 * @param mixed   c: decimal delimiter
 */
 export const formatPercentageAmount = (val, pctSign = '%', n = 2, x = 3, s = '.', c = ',') => {
    const checkZero = Math.round(val * 100 * 10**n) === 0 ? 0 : val * 100;
    const num = checkZero.toFixed(Math.max(0, ~~n));
    const re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')';
    const conv = (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
    if (pctSign) return `${conv} ${pctSign}`;
    return `${conv}`;
}


export const supportedLanguages = () => {
    return {
        "en": "English",
        "pt": "Português",
    };
}

export const flattenObject = (data) => {
    var result = {};
    
    const recurse = (cur, prop) => {
        if (Object(cur) !== cur) {
            result[prop] = cur;
        }
        else if (Array.isArray(cur)) {
            for (var i=0, l=cur.length; i < l; i++) {
                recurse(cur[i], prop + "[" + i + "]");
            }
            if (l === 0) {
                result[prop] = [];
            }
        }
        else {
            var isEmpty = true;
            for (var p in cur) {
                isEmpty = false;
                recurse(cur[p], prop ? prop + "_" + p : p);
            }
            if (isEmpty && prop) {
                result[prop] = {};
            }
        }
    }
    recurse(data, "");
    return result;
}


export const minBetweenStrings = (a, b) => { if (a > b) { return b; } return a; }
export const maxBetweenStrings = (a, b) => { if (a < b) { return b; } return a; }


export const mergeArrays = (arr1, arr2, compareFunc, outer = true) => {
    let merged = [];
    for (let i = 0; i < arr1.length; i++) {
        const rightElem = arr2.find(entry => compareFunc(entry, arr1[i]));
        const elem = {...arr1[i], ...rightElem};
        merged.push(elem);
    }
    if (outer) {
        for (let i = 0; i < arr2.length; i++) {
            const leftElem = arr1.find(entry => compareFunc(entry, arr2[i]));
            if (!leftElem) {
                merged.push(arr2[i]);
            }
        }
    }
    return merged;
}

export const generateUuid = () => {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}
