import { DIM_CREATE, DIM_UPDATE, DIM_DELETE, DIM_MOVE } from "./types";
import { createGroupService, deleteGroupService, moveGroupUpService, moveGroupDownService } from "../../service/group";
import { apiGroupCreate, apiGroupUpdate, apiGroupDelete, apiGroupUp, apiGroupDown } from "../../api/group";


export const createGroup = ({ name, description }, callback) => {
    return (dispatch, getState) => {
        createGroupService({ name, description }, (data, successFlag) => {
            if (successFlag > 0) {
                dispatch({ type: DIM_CREATE, payload: { data: data.group, attr: "groups" } });
            }
            callback(data, successFlag);
        });
    }
};


export const updateGroup = ({ id, name, description }, callback) => {
    return (dispatch, getState) => {
        apiGroupUpdate({ id, name, description }, (data, successFlag) => {
            if (successFlag > 0) {
                dispatch({ type: DIM_UPDATE, payload: { data: data.group, attr: "groups" } });
            }
            callback(data, successFlag);
        });
    }
};

export const deleteGroup = ({ id }, callback) => {
    return (dispatch, getState) => {
        deleteGroupService({ id }, (data, successFlag) => {
            if (successFlag > 0) {
                dispatch({ type: DIM_DELETE, payload: { data: id, attr: "groups" } });
            }
            callback(data, successFlag);
        });
    }
};

export const moveGroupUp = ({ id }, callback) => {
    return (dispatch, getState) => {
        moveGroupUpService({ id }, (data, successFlag) => {
            if (successFlag > 0) {
                dispatch({ type: DIM_MOVE, payload: { id, incr: -1 } });
            }
            callback(data, successFlag);
        });
    }
};

export const moveGroupDown = ({ id }, callback) => {
    return (dispatch, getState) => {
        moveGroupDownService({ id }, (data, successFlag) => {
            if (successFlag > 0) {
                dispatch({ type: DIM_MOVE, payload: { id, incr: 1 } });
            }
            callback(data, successFlag);
        });
    }
};
