import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { toast } from "react-toastify";
import { useTheme } from "@mui/material/styles";
import { DataGrid } from '@mui/x-data-grid';

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import { createGroup, updateGroup, deleteGroup, moveGroupUp, moveGroupDown } from "../../store/actions/group";
import { getGroupsService } from "../../service/group";
import { getIndexInArrayByAttr } from "../../helpers/utils";
import { TooltipCell } from "../../components/table/TooltipCell";
import { getCustomTableToolbar } from "../../components/table/SimpleToolbar";
import CollectionAddButton from "../../components/input/button/CollectionAddButton";
import { DimDialog } from "../../components/dim/DimDialog";
import { formatCurrencyAmount } from "../../helpers/utils";
import ErrorMessageToast from "../../components/layout/ErrorMessageToast";
import MobileListItem from "../../components/dim/MobileListItem";
import SearchInput from "../../components/input/text/SearchInput";


const GroupWidget = (props) => {

    const theme = useTheme();
    const { t } = useTranslation(["group", "amount_type"]);

    const { isMobile, canUpdateAccount } = props;
    const { createMethod, updateMethod, deleteMethod, moveUpMethod, moveDownMethod } = props;

    const [rows, setRows] = React.useState([]);
    const [searchInput, setSearchInput] = React.useState("");
    const [searchFetch, setSearchFetch] = React.useState("");
    const [formGroup, setFormGroup] = React.useState({
        formOpen: false,
        formType: "",
        stateId: "",
        stateName: "",
        stateDescription: "",
    });
    const [triggerCloseMobileItem, setTriggerCloseMobileItem] = React.useState(true);

    const getFlexMobile = (flexVal) => (isMobile ? null : flexVal);

    const columns = [
        {
            field: "order",
            headerName: t("Order"),
            headerClassName: "table-header",
            flex: getFlexMobile(1),
            align: "center",
            headerAlign: "center",
            sortable: false,
            renderCell: (params) => {
                return (
                    <React.Fragment>
                        <IconButton aria-label="Up" disabled={params.row.order === 1}
                            onClick={() => {handleChangeOrder({ id: params.row.id, incr: -1 })}}>
                            <ArrowUpwardIcon />
                        </IconButton>
                        <Box component="span">{params.row.order}</Box>
                        <IconButton aria-label="Down" disabled={params.row.order === rows.length}
                            onClick={() => {handleChangeOrder({ id: params.row.id, incr: 1 })}}>
                            <ArrowDownwardIcon />
                        </IconButton>
                    </React.Fragment>
                )
            },
            useRenderMethodOnMobile: true,
        },
        {
            field: "name",
            headerName: t("Name"),
            headerClassName: "table-header",
            flex: getFlexMobile(4),
            sortable: false,
            renderCell: (params) => <TooltipCell text={params.row.name} />,
        },
        {
            field: "description",
            headerName: t("Description"),
            headerClassName: "table-header",
            flex: getFlexMobile(3),
            sortable: false,
            renderCell: (params) => <TooltipCell text={params.row.description} />,
        },
        {
            field: "bal",
            type: "number",
            headerName: t("BAL", {ns: "amount_type"}),
            headerClassName: "table-header",
            flex: getFlexMobile(1),
            renderCell: (params) => formatCurrencyAmount(params.row.bal),
            useRenderMethodOnMobile: true,
        },
        {
            field: "int",
            type: "number",
            headerName: t("INT", {ns: "amount_type"}),
            headerClassName: "table-header",
            flex: getFlexMobile(1),
            renderCell: (params) => formatCurrencyAmount(params.row.int),
            useRenderMethodOnMobile: true,
        },
        {
            field: "upd",
            type: "number",
            headerName: t("UPD", {ns: "amount_type"}),
            headerClassName: "table-header",
            flex: getFlexMobile(1),
            renderCell: (params) => formatCurrencyAmount(params.row.upd),
            useRenderMethodOnMobile: true,
        },
        {
            field: "action",
            headerName: "Actions",
            align: "center",
            headerClassName: "table-header",
            flex: getFlexMobile(1),
            sortable: false,
            renderCell: (params) => (
                <React.Fragment>
                    <IconButton disabled={!canUpdateAccount} aria-label="Delete" onClick={(e) => {handleDelete(params.row)}} sx={{ marginRigh: theme.spacing(1) }}><DeleteIcon /></IconButton>
                    <IconButton disabled={!canUpdateAccount} aria-label="Edit"  onClick={(e) => {handleEdit(params.row)}}><EditIcon /></IconButton>
                    <IconButton aria-label="Info" component={Link} to={`/group/view/${params.row.id}`}><InfoIcon /></IconButton>
                </React.Fragment>
            ),
            type: "actions",
        },
    ];

    const fetchGroups = () => {
        getGroupsService({ search: searchFetch }, (data, successFlag) => {
            if (successFlag > 0) {
                setRows(data.groups.map((entry) => {
                    return {
                        id: entry.id,
                        name: entry.name,
                        description: entry.description,
                        order: entry.order,
                        bal: entry.bal,
                        int: entry.int,
                        upd: entry.upd,
                    }
                }));
            }
        });
    };

    const handleSubmitSearch = (e) => {
        setSearchFetch(searchInput);
    };

    const handleSearchChange = (e) => {
        setSearchInput(e.target.value);
    };

    const handleResetSearch = () => {
        setSearchFetch("");
        setSearchInput("");
    };

    const handleDelete = (row) => {
        setFormGroup({
            formOpen: true,
            formType: "delete",
            stateId: row.id,
            stateName: row.name,
            stateDescription: row.description,
        });
    };

    const handleEdit = (row) => {
        setFormGroup({
            formOpen: true,
            formType: "edit",
            stateId: row.id,
            stateName: row.name,
            stateDescription: row.description,
        });
    };

    const handleAdd = (e) => {
        setFormGroup({
            formOpen: true,
            formType: "create",
            stateId: "",
            stateName: "",
            stateDescription: "",
        });
    }

    const handleModalResponse = ({ action, state }) => {

        if (action === "submit") {

            if (formGroup.formType === "create") {
                createMethod({name: state.name, description: state.description}, (data, successFlag) => {
                    if (successFlag > 0) {
                        fetchGroups();
                        toast.success(t("Product Group created successfuly"));
                    }
                });
            }

            if (formGroup.formType === "edit") {
                updateMethod({id: formGroup.stateId, name: state.name, description: state.description}, (data, successFlag) => {
                    if (successFlag > 0) {
                        const newRows = rows.map((entry, idx) => {
                            if (entry.id === formGroup.stateId) {
                                return {
                                    id: data.group.id,
                                    name: data.group.name,
                                    description: data.group.description,
                                    order: data.group.order,
                                    bal: entry.bal,
                                    int: entry.int,
                                    upd: entry.upd,
                                };
                            }
                            return entry;
                        });
                        setRows(newRows);
                        toast.success(t("Product Group updated successfuly"));
                    }
                });
            }

            if (formGroup.formType === "delete") {
                deleteMethod({ id: formGroup.stateId }, (data, successFlag) => {
                    if (successFlag > 0) {
                        fetchGroups();
                        toast.success(t("Product Group deleted successfuly"));
                    }
                    else {
                        if (successFlag === 0) {
                            data.map((err) => {
                                toast.error(
                                    <ErrorMessageToast errorType={err.error_type} fieldName={err.field_name} />
                                );
                            });
                        }
                        else {
                            toast.error(data);
                        }
                    }
                });
            }

        }

        setFormGroup({
            formOpen: false,
            formType: "",
            stateId: "",
            stateName: "",
            stateDescription: "",
        });
    }

    const handleChangeOrder = ({ id, incr }) => {
        const method = incr > 0 ? moveDownMethod : (incr < 0 ? moveUpMethod : null);
        if (method) {
            method({ id }, (data, success) => {
                if (success) {
                    const idxToMove = getIndexInArrayByAttr(rows, "id", id);
                    let newRows = rows.slice();
                    newRows[idxToMove].order = newRows[idxToMove].order + incr;
                    newRows[idxToMove + incr].order = newRows[idxToMove + incr].order - incr;
                    newRows.sort((a, b) => (a.order - b.order));
                    setRows(newRows);
                    setTriggerCloseMobileItem(!triggerCloseMobileItem);
                }
            });
        }
    }

    useEffect(() => {
        fetchGroups();
    }, [searchFetch]);

    return (
        <Container component="main" maxWidth="xl" sx={{ marginTop: theme.spacing(1) }}>
            <Typography component="h1" variant="h1">{t("Product Groups")}</Typography>
            <SearchInput
                value={searchInput}
                submit={handleSubmitSearch}
                reset={handleResetSearch}
                onChange={handleSearchChange}
                sx={{marginTop: theme.spacing(1)}} />
            <Box component="div" sx={{
                marginY: theme.spacing(1),
                '& .table-header': {
                    backgroundColor: "#eaeaff",
                    fontWeight: "bold", 
                    width: "100%",
                },
            }}>
            {
                isMobile
                ? (
                    <React.Fragment>
                        {
                            rows.map((row, idx) => {
                                return <MobileListItem key={`mobileRow${idx}`} dataItem={row}
                                    triggerClose={triggerCloseMobileItem}
                                    mainAttribute="name" mainIndicator="bal" columns={columns}
                                    sx={{ marginTop: theme.spacing(1), padding: theme.spacing(1) }} />
                            })
                        }
                        <CollectionAddButton handleAdd={handleAdd} addText={t("Add")} to={undefined} isMobile={isMobile}
                            sx={{marginTop: theme.spacing(2)}} />
                    </React.Fragment>
                )
                : (
                    <DataGrid
                        sx={{
                            border: 0,
                            '& .MuiDataGrid-toolbarContainer': {
                                display: "block",
                                textAlign: "right",
                            },
                            '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                                outline: 'none',
                            },
                        }}
                        rows={rows}
                        columns={columns}
                        autoHeight={true}
                        disableSelectionOnClick={true}
                        hideFooterPagination={true}
                        disableColumnMenu={true}
                        density={isMobile ? "compact" : "standard" }
                        components={{ Toolbar: (() => getCustomTableToolbar(handleAdd, t("Add"))), }}
                    />
                )  
            }
            </Box>
            <DimDialog {...formGroup} modalCloseCallback={handleModalResponse} isMobile={isMobile}
                dialogText={t("Are you sure you want to delete this group?")} titleEdit={t("Edit Product Group")}
                titleCreate={t("Create Product Group")} titleDelete={t("Delete Product Group")} />
        </Container>
    );
}

const mapStateToProps = (state) => {
    return {
        isMobile: state.appSettings.isMobile,
        canUpdateAccount: state.user.canUpdateAccount,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createMethod: ({ name, description }, callback) => dispatch(createGroup({ name, description }, callback)),
        updateMethod: ({ id, name, description }, callback) => dispatch(updateGroup({ id, name, description }, callback)),
        deleteMethod: ({ id }, callback) => dispatch(deleteGroup({ id }, callback)),
        moveUpMethod: ({ id }, callback) => dispatch(moveGroupUp({ id }, callback)),
        moveDownMethod: ({ id }, callback) => dispatch(moveGroupDown({ id }, callback)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupWidget);
