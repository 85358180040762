import React from "react";
import { useTranslation } from "react-i18next";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import moment from "moment";
import GenericDialog from "./GenericDialog";
import { formatNumberAmount, formatNumberPercentage } from "../../helpers/format";

const InstrumentFormDialog = (props) => {

    const { baseDate, baseValue, currentDate, currentValue, lastBalance, lastTransactionDate, update, variation } = props;
    const { actionMethod, open } = props;
    const { t } = useTranslation(["product"]);

    const baseText = t("Unit value at", {date: moment(baseDate).format("YYYY-MM-DD")});
    const baseFormatted = formatNumberAmount(baseValue);
    const currentText = t("Unit value at", {date: moment(currentDate).format("YYYY-MM-DD")});
    const currentFormatted = formatNumberAmount(currentValue);
    const varFormatted = formatNumberPercentage(variation);

    const lastText = t("Balance at", {date: moment(lastTransactionDate).format("YYYY-MM-DD")});
    const lastFormatted = formatNumberAmount(lastBalance);
    const updatedBalance = formatNumberAmount(lastBalance + update);

    return (
        <GenericDialog
            open={open}
            title={t("Update Instrument Value")}
            text={t("Do you want to create a transaction to update product balance?")}
            actionMethod={actionMethod}
            cancelLabel={t("No")}
            submitLabel={t("Yes")}>
            <List>
                <ListItem><ListItemText primary={baseFormatted} secondary={baseText} /></ListItem>
                <ListItem><ListItemText primary={currentFormatted} secondary={currentText} /></ListItem>
                <ListItem><ListItemText primary={varFormatted} secondary={t("Variation")} /></ListItem>
                <ListItem><ListItemText primary={lastFormatted} secondary={lastText} /></ListItem>
                <ListItem><ListItemText primary={updatedBalance} secondary={t("Updated balance")} /></ListItem>
            </List>
        </GenericDialog>
    );
}

export default InstrumentFormDialog;
