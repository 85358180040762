import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { DataGrid } from '@mui/x-data-grid';
import { apiPeriodic } from "../../api/aggregations";
import { TooltipLinkCell } from "../../components/table/TooltipLinkCell";
import moment from "moment";
import { Box } from "@mui/material";

import { formatNumberAmount } from "../../helpers/format";

const SummaryTableWidget = (props) => {

    const { t } = useTranslation(["widget"]);

    const {filter, hasProduct, hasCategory, hasItem, hasEntity, parcel, frequency, isMobile, height} = props;

    const [tableColumns, setTableColumns] = React.useState([]);
    const [widgetData, setWidgetData] = React.useState([]);

    React.useEffect(() => {

        const getFlexMobile = (flexVal) => (isMobile ? null : flexVal);

        apiPeriodic({
            ...filter,
            hasProduct,
            hasCategory,
            hasItem,
            hasEntity,
            parcel,
            frequency,
        }, (data, successFlag) => {
            if (successFlag > 0) {

                let colsAux = [];

                if (hasProduct) {
                    colsAux.push(
                        {
                            field: "productName",
                            headerName: t("Product"),
                            headerClassName: "table-header",
                            flex: getFlexMobile(3),
                            renderCell: (params) => (
                                <TooltipLinkCell text={params.row.productName} url={`/product/view/${params.row.productId}`} />
                            ),
                        }
                    )
                }

                if (hasCategory) {
                    colsAux.push(
                        {
                            field: "categoryName",
                            headerName: t("Category"),
                            headerClassName: "table-header",
                            flex: getFlexMobile(3),
                            renderCell: (params) => (
                                <TooltipLinkCell text={params.row.categoryName} url={`/category/view/${params.row.categoryId}`} />
                            ),
                        }
                    )
                }

                if (hasItem) {
                    colsAux.push(
                        {
                            field: "itemName",
                            headerName: t("Item"),
                            headerClassName: "table-header",
                            flex: getFlexMobile(3),
                            renderCell: (params) => (
                                <TooltipLinkCell text={params.row.itemName} url={`/item/view/${params.row.itemId}`} />
                            ),
                        }
                    )
                }

                if (hasEntity) {
                    colsAux.push(
                        {
                            field: "entityName",
                            headerName: t("Entity"),
                            headerClassName: "table-header",
                            flex: getFlexMobile(3),
                            renderCell: (params) => (
                                <TooltipLinkCell text={params.row.entityName} url={`/entity/view/${params.row.entityId}`} />
                            ),
                        }
                    )
                }

                data.dateColumns.forEach(element => {
                    const dateStr = element.split("_")[1];
                    const dateHeader = frequency === "ymd" ? moment(dateStr).format("yyyy-MM-DD") : (
                        frequency === "ym" ? moment(dateStr + "01").format("yyyy-MM") : (
                            frequency === "y" ? dateStr : null
                        )
                    );
                    colsAux.push({
                        field: element,
                        headerName: dateHeader,
                        headerClassName: "table-header",
                        flex: getFlexMobile(1),
                        type: "number",
                        valueFormatter: (params) => formatNumberAmount(params.value ?? 0),
                    })
                });

                setTableColumns(colsAux);
                setWidgetData(data.data.map((entry, idx) => { return {id: idx, ...entry} }));
            }
        });
    }, [filter, hasProduct, hasCategory, hasItem, hasEntity, parcel, frequency, isMobile]);

    const headerHeight = 60;

    return (
        <Box sx={{ height: `${height}px`}}>
            <DataGrid
                sx={{
                    border: 0,
                    '& .MuiDataGrid-toolbarContainer': { display: "block", textAlign: "right" },
                    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': { outline: 'none' },
                    '& .table-header': { backgroundColor: "#eaeaff", fontWeight: "bold" },
                }}
                rows={widgetData}
                columns={tableColumns}
                disableSelectionOnClick={true}
                disableColumnMenu={true}
                density="compact"
                headerHeight={headerHeight}
            />
        </Box>
    );
}

const mapStateToProps = (state) => {
    return {
        isMobile: state.appSettings.isMobile,
        canUpdateAccount: state.user.canUpdateAccount,
    }
}

export default connect(mapStateToProps)(SummaryTableWidget);
