// Button component
import React from "react";
import Button from "./Button";

const CancelButton = (props) => {
    const {
        onClick,
        children,
        marginLeft = 0,
        marginRight = 0,
    } = props;

    return (
        <Button
            variant="outlined"
            color="primary"
            onClick={onClick}
            marginLeft={marginLeft}
            marginRight={marginRight}
        >
            {children}
        </Button>
    )
}

export default CancelButton;
