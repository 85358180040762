import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import moment from "moment";

import Grid from "@mui/material/Grid";

import Select from "../../components/input/select/Select";
import GenericDialog from "./GenericDialog";
import DateInput from "../input/text/DateInput";
import FormAmountInput from "../../components/input/text/FormAmountInput";

const FilterFormDialog = (props) => {

    const theme = useTheme();
    const { t } = useTranslation(["search", "product"]);
    const [state, setState] = React.useState({})
    const { dateRangeOptions, transactionTypes, amountTypes, productTypes, groups, products, categories, items, entities, tags } = props;
    const { open, editState, actionMethod } = props;

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.id]: e.target.value,
        });
    }

    const handleAction = (action) => {
        actionMethod(action, state);
    }

    const handleSelect = (field, e, newValue) => {
        const val = field !== "dateRange" ? (
            newValue
            ? newValue.map((entry) => entry.id)
            : []
        ) : newValue ? newValue.id : "";
        setState({
            ...state,
            [field]: val,
        });
    }

    const handleDateChange = (value, name) => {
        setState({
            ...state,
            [name]: moment(value).format("YYYY-MM-DD"),
        });
    }

    useEffect(() => {
        setState(editState);
    }, [editState])

    return (
        <GenericDialog
            open={open}
            title={t("Filters")}
            actionMethod={handleAction}
            cancelLabel={t("Cancel")}
            submitLabel={t("Apply")}
            fullScreen={true}
        >
            <Grid container sx={{marginTop: theme.spacing(1)}} spacing={2}>

                <Grid item xs={12} sm={12} lg={4}>
                    <Select options={dateRangeOptions.map((entry) => ({id: entry.id, label: t(entry.id)}))}
                        id="dateRange" selected={state.dateRange} label={t("Date Range")}
                        onChange={(e, v) => handleSelect("dateRange", e, v)} addEmptyItem={true} />
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <DateInput label={t("Start Date")} value={Boolean(state.dateRange) ? "" : state.startDate}
                        onChange={(v) => handleDateChange(v, "startDate")} disabled={Boolean(state.dateRange)} />
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <DateInput label={t("End Date")} value={Boolean(state.dateRange) ? "" : state.endDate}
                        onChange={(v) => handleDateChange(v, "endDate")} disabled={Boolean(state.dateRange)} />
                </Grid>

                <Grid item xs={12} sm={4} lg={2}>
                    <Select options={transactionTypes.map((entry) => ({id: entry.id, label: t(entry.id, {ns: "transaction_type"})}))}
                        id="transactionTypes" selected={state.transactionTypes} label={t("Transaction Types")}
                        onChange={(e, v) => handleSelect("transactionTypes", e, v)} addEmptyItem={false} multiple={true} />
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                    <Select options={amountTypes.map((entry) => ({id: entry.id, label: t(entry.id, {ns: "amount_type"})}))}
                        id="amountTypes" selected={state.amountTypes} label={t("Amount Types")}
                        onChange={(e, v) => handleSelect("amountTypes", e, v)} addEmptyItem={false} multiple={true} />
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                    <Select options={productTypes.map((entry) => ({id: entry.id, label: t(entry.id, {ns: "product"})}))}
                        id="productTypes" multiple={true} selected={state.productTypes} addEmptyItem={false}
                        label={t("Product Types")} onChange={(e, v) => handleSelect("productTypes", e, v)} />
                </Grid>
                <Grid item xs={12} sm={6} lg={2}>
                    <FormAmountInput id="amountMin" label={t("Min Amount")} onChange={handleChange} value={state.amountMin} />
                </Grid>
                <Grid item xs={12} sm={6} lg={2}>
                    <FormAmountInput id="amountMax" label={t("Max Amount")} onChange={handleChange} value={state.amountMax} />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Select options={groups.map((entry) => ({id: entry.id, label: entry.name}))}
                        id="groups" selected={state.groups} label={t("Groups")} addEmptyItem={false}
                        onChange={(e, v) => handleSelect("groups", e, v)} multiple={true} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Select options={products.map((entry) => ({id: entry.id, label: entry.name}))}
                        id="products" selected={state.products} label={t("Products")} addEmptyItem={false}
                        onChange={(e, v) => handleSelect("products", e, v)} multiple={true} />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Select options={categories.map((entry) => ({id: entry.id, label: entry.name}))}
                        id="categories" selected={state.categories} label={t("Categories")}
                        onChange={(e, v) => handleSelect("categories", e, v)} multiple={true} addEmptyItem={false} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Select options={items.map((entry) => ({id: entry.id, label: entry.name}))}
                        id="items" selected={state.items} label={t("Items")} addEmptyItem={false}
                        onChange={(e, v) => handleSelect("items", e, v)} multiple={true} />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Select options={entities.map((entry) => ({id: entry.id, label: entry.name}))}
                        id="entities" selected={state.entities} label={t("Entities")} addEmptyItem={false}
                        onChange={(e, v) => handleSelect("entities", e, v)} multiple={true} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Select options={tags.map((entry) => ({id: entry.id, label: entry.name}))}
                        id="tags" selected={state.tags} label={t("Tags")} addEmptyItem={false}
                        onChange={(e, v) => handleSelect("tags", e, v)} multiple={true} />
                </Grid>

            </Grid>
        </GenericDialog>
    );
}

const mapStateToProps = (state) => {
    return {
        dateRangeOptions: state.user.dateRangeOptions,
        transactionTypes: state.user.transactionTypes,
        amountTypes: state.user.amountTypes,
        productTypes: state.user.productTypes,
        groups: state.user.groups,
        products: state.user.products,
        categories: state.user.categories,
        items: state.user.items,
        entities: state.user.entities,
        tags: state.user.tags,
    }
}

export default connect(mapStateToProps)(FilterFormDialog);
