import React from "react";
import TextField from "@mui/material/TextField";

const FormTextInput = (props) => {

    const {
        id,
        label,
        onChange,
        value,
        margin = "none",
        required = false,
        disabled = false,
        multiline = false,
        minRows = undefined,
        maxRows = undefined,
        type = "text",
        inputProps = undefined,
        onBlur = undefined,
    } = props;

    const autoComplete = type === "email" ? "email" : (
        type === "password" ? "current-password" : undefined
    );

    return (
        <TextField
            variant="outlined"
            margin={margin}
            required={required}
            fullWidth
            id={id}
            name={id}
            label={label}
            disabled={disabled}
            onChange={onChange}
            value={value ?? ""}
            multiline={multiline}
            minRows={minRows}
            maxRows={maxRows}
            type={type}
            autoComplete={autoComplete}
            InputProps={inputProps}
            onBlur={onBlur}
        />
    );
}

export default FormTextInput;
