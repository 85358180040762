import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { updateProduct } from "../store/actions/product";
import { apiProductGet } from "../api/product";
import ProductFormDialog from "../components/dialog/ProductFormDialog";
import DimensionTopLayout from "./DimensionTopLayout";
import GroupChip from "../components/chip/GroupChip";

const ProductPage = (props) => {

    const { t } = useTranslation(["dimension"]);

    const { updateMethod } = props;
    const { instanceId } = props;

    const [name, setName] = useState(null);
    const [description, setDescription] = useState(null);
    const [productGroupId, setProductGroupId] = useState(null);
    const [productGroupName, setProductGroupName] = useState(null);

    const [editOpen, setEditOpen] = useState(false);
    const [editState, setEditState] = useState([])

    const updateState = (data) => {
        setName(data.name);
        setDescription(data.description);
        setProductGroupId(data.productGroupId);
        setProductGroupName(data.productGroupName);
    }

    useEffect(() => {
        if (instanceId) {
            apiProductGet({ id: instanceId }, (data, successFlag) => {
                if (successFlag > 0) {
                    updateState(data["product"]);
                    setEditState(data["product"]);
                }
            });
        }
    }, [instanceId]);

    if (!instanceId) {
        return null;
    }

    const onEditSubmit = (action, newState) => {

        if (action === "submit") {
            updateMethod({...newState, id: instanceId}, (data, success) => {
                if (success > 0) {
                    updateState(data["product"]);
                    toast.success(t("Updated successfully"));
                }
            })
        }

        setEditOpen(false);
    }

    return (
        <React.Fragment>
            <DimensionTopLayout
                title={t("Product")}
                name={name}
                description={description}
                onEditClick={() => setEditOpen(true)}
                transactionFilter={{products: [instanceId] }}
                secondaryElement={<GroupChip groupId={productGroupId} label={productGroupName} />} />
            <ProductFormDialog
                open={editOpen}
                title={t("Update")}
                formType="edit"
                editState={editState}
                actionMethod={onEditSubmit} />
        </React.Fragment>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateMethod: ({ id, name, description }, callback) => dispatch(updateProduct({ id, name, description }, callback)),
    }
}

export default connect(null, mapDispatchToProps)(ProductPage);
