import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { toast } from "react-toastify";
import { useTheme } from "@mui/material/styles";
import { DataGrid } from '@mui/x-data-grid';


import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import GroupsIcon from '@mui/icons-material/Groups';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';

import { createAccount, updateAccount, deleteAccount } from "../../store/actions/account";
import { setUserDefaultAccount } from "../../store/actions/user";
import { getAccountsService } from "../../service/account";
import { TooltipCell } from "../../components/table/TooltipCell";
import { Tooltip } from "@mui/material";
import { getCustomTableToolbar } from "../../components/table/SimpleToolbar";
import CollectionAddButton from "../../components/input/button/CollectionAddButton";
import { DimDialog } from "../../components/dim/DimDialog";
import { handleFieldErrors } from "../../helpers/error";
import MobileListItem from "../../components/dim/MobileListItem";
import SearchInput from "../../components/input/text/SearchInput";


const AccountWidget = (props) => {

    const pageSizeOptions = [10, 25, 50];
    const theme = useTheme();
    const { t } = useTranslation(["account"]);

    const { isMobile, defaultAccountId } = props;
    const { createAccountProp, updateAccountProp, deleteAccountProp, setDefaultProp } = props;

    const [rows, setRows] = React.useState([]);
    const [rowCount, setRowCount] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(pageSizeOptions[0]);
    const [pageNumber, setPageNumber] = React.useState(0);
    const [sortModel, setSortModel] = React.useState([{ field: "name", sort: "asc" }]);
    const [searchInput, setSearchInput] = React.useState("");
    const [searchFetch, setSearchFetch] = React.useState("");
    const [formAccount, setFormAccount] = React.useState({
        formOpen: false,
        formType: "",
        stateId: "",
        stateName: "",
        stateDescription: "",
    });

    const getFlexMobile = (flexVal) => (isMobile ? null : flexVal);

    const ButtonDefaultAccount = (props) => {
        const disabled = defaultAccountId === props.rowId
        const button = (
            <IconButton disabled={disabled} aria-label="Default" onClick={(e) => handleDefault(props.rowId)}
                ><BookmarkAddedIcon />
            </IconButton>
        );
        return (
            disabled
            ? button
            : <Tooltip title={t("Set as Default")}>{button}</Tooltip>
        );
    }

    const columns = [
        {
            field: "name",
            headerName: t("Name"),
            headerClassName: "table-header",
            flex: getFlexMobile(4),
            renderCell: (params) => <TooltipCell text={params.row.name} />,
        },
        {
            field: "description",
            headerName: t("Description"),
            headerClassName: "table-header",
            flex: getFlexMobile(3),
            renderCell: (params) => <TooltipCell text={params.row.description} />,
        },
        {
            field: "canUpdate",
            headerName: t("Can Update"),
            headerClassName: "table-header",
            flex: getFlexMobile(1),
            type: "boolean",
        },
        {
            field: "isAdmin",
            headerName: t("Is Admin"),
            headerClassName: "table-header",
            flex: getFlexMobile(1),
            type: "boolean",
        },
        {
            field: "isDefault",
            headerName: t("Is Default"),
            headerClassName: "table-header",
            flex: getFlexMobile(1),
            type: "boolean",
        },
        {
            field: "members",
            headerName: t("Members"),
            headerClassName: "table-header",
            flex: getFlexMobile(1),
            type: "number",
        },
        {
            field: "action",
            headerName: "Actions",
            align: "center",
            headerClassName: "table-header",
            flex: getFlexMobile(1),
            sortable: false,
            renderCell: (params) => (
                <React.Fragment>
                    <IconButton disabled={!params.row.isAdmin} aria-label="Delete" onClick={(e) => {handleDelete(params.row)}} sx={{ marginRigh: theme.spacing(1) }}><DeleteIcon /></IconButton>
                    <IconButton disabled={!params.row.isAdmin} aria-label="Edit"  onClick={(e) => {handleEdit(params.row)}} sx={{ marginRigh: theme.spacing(1) }}><EditIcon /></IconButton>
                    <IconButton aria-label="Members" component={RouterLink} to={"/members/" + params.row.id} sx={{ marginRigh: theme.spacing(1) }}><GroupsIcon /></IconButton>
                    <ButtonDefaultAccount rowId={params.row.id} />
                </React.Fragment>
            ),
            type: "actions",
        },
    ];

    const fetchAccounts = () => {
        getAccountsService({
            pageNumber: pageNumber,
            pageSize: pageSize,
            sortDir: sortModel.length === 1 ? sortModel[0].sort : null,
            sortCol: sortModel.length === 1 ? sortModel[0].field : null,
            search: searchFetch,
        }, (data, successFlag) => {
            if (successFlag > 0) {
                setRows(data.accounts.map((entry) => {
                    return {
                        id: entry.id,
                        name: entry.name,
                        description: entry.description,
                        canUpdate: entry.can_update,
                        isAdmin: entry.is_admin,
                        isDefault: entry.is_default,
                        members: entry.members.length,
                    }
                }));
                setRowCount(data.total_rows);
            }
        });
    };

    const pageSizeHandler = (newPageSize) => {
        setPageSize(newPageSize);
    };

    const pageHandler = (newPageNumber) => {
        setPageNumber(newPageNumber);
    };

    const sortHandler = (newModel) => {
        setSortModel(newModel);
    };

    const handleSubmitSearch = (e) => {
        setSearchFetch(searchInput);
    };

    const handleSearchChange = (e) => {
        setSearchInput(e.target.value);
    };

    const handleResetSearch = () => {
        setSearchFetch("");
        setSearchInput("");
    };

    const handleDelete = (row) => {
        setFormAccount({
            formOpen: true,
            formType: "delete",
            stateId: row.id,
            stateName: row.name,
            stateDescription: row.description,
        });
    };

    const handleEdit = (row) => {
        setFormAccount({
            formOpen: true,
            formType: "edit",
            stateId: row.id,
            stateName: row.name,
            stateDescription: row.description,
        });
    };

    const handleAdd = (e) => {
        setFormAccount({
            formOpen: true,
            formType: "create",
            stateId: "",
            stateName: "",
            stateDescription: "",
        });
    }

    const handleDefault = (accountId) => {
        setDefaultProp({ defaultAccountId: accountId }, (data, successFlag) => {
            if (successFlag > 0) {
                const newRows = rows.map((entry, idx) => {
                    return {...entry, isDefault: entry.id === accountId};
                });
                setRows(newRows);
                toast.success(t("Default account was set successfuly"));
            }
        });
    }

    const handleModalResponse = ({ action, state }) => {

        if (action === "submit") {

            if (formAccount.formType === "create") {
                createAccountProp({name: state.name, description: state.description}, (data, successFlag) => {
                    if (successFlag > 0) {
                        fetchAccounts();
                        toast.success(t("Account created successfuly"));
                    }
                    else {
                        handleFieldErrors(successFlag, data, "account");
                    }
                });
            }

            if (formAccount.formType === "edit") {
                updateAccountProp({id: formAccount.stateId, name: state.name, description: state.description}, (data, successFlag) => {
                    if (successFlag > 0) {
                        const newRows = rows.map((entry, idx) => {
                            if (entry.id === formAccount.stateId) {
                                return {
                                    id: data.account.id,
                                    name: data.account.name,
                                    description: data.account.description,
                                    canUpdate: data.account.can_update,
                                    isAdmin: data.account.is_admin,
                                    isDefault: data.account.is_default,
                                    members: data.account.members.length,
                                };
                            }
                            return entry;
                        });
                        setRows(newRows);
                        toast.success(t("Account updated successfuly"));
                    }
                    else {
                        handleFieldErrors(successFlag, data, "account");
                    }
                });
            }

            if (formAccount.formType === "delete") {
                deleteAccountProp({ id: formAccount.stateId }, (data, successFlag) => {
                    if (successFlag > 0) {
                        fetchAccounts();
                        toast.success(t("Account deleted successfuly"));
                    }
                    else {
                        handleFieldErrors(successFlag, data, "account");
                    }
                });
            }

        }

        setFormAccount({
            formOpen: false,
            formType: "",
            stateId: "",
            stateName: "",
            stateDescription: "",
        });
    }

    useEffect(() => {
        fetchAccounts();
    }, [pageNumber, pageSize, sortModel, searchFetch]);

    return (
        <Container component="main" maxWidth="xl" sx={{ marginTop: theme.spacing(1) }}>
            <Typography component="h1" variant="h1">{t("Accounts")}</Typography>
            <SearchInput
                value={searchInput}
                submit={handleSubmitSearch}
                reset={handleResetSearch}
                onChange={handleSearchChange}
                sx={{marginTop: theme.spacing(1)}} />
            <Box component="div" sx={{
                marginTop: theme.spacing(1),
                '& .table-header': {
                    backgroundColor: "#eaeaff",
                    fontWeight: "bold", 
                    width: "100%",
                },
            }}>
                {
                    isMobile
                    ? (
                        <React.Fragment>
                            {
                                rows.map((row, idx) => {
                                    return <MobileListItem key={`mobileRow${idx}`} dataItem={row}
                                        mainAttribute="name" mainIndicator="isDefault" columns={columns}
                                        sx={{ marginTop: theme.spacing(1), padding: theme.spacing(1) }} />
                                })
                            }
                            <CollectionAddButton handleAdd={handleAdd} addText={t("Add")} to={undefined} isMobile={isMobile}
                                sx={{marginTop: theme.spacing(2)}} />
                        </React.Fragment>
                    )
                    : (
                        <DataGrid
                            sx={{
                                border: 0,
                                '& .MuiDataGrid-toolbarContainer': {
                                    display: "block",
                                    textAlign: "right",
                                },
                                '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                                    outline: 'none',
                                },
                            }}
                            rows={rows}
                            columns={columns}
                            autoHeight={true}
                            disableSelectionOnClick={true}
                            rowsPerPageOptions={pageSizeOptions}
                            pageSize={pageSize}
                            paginationMode="server"
                            rowCount={rowCount}
                            onPageSizeChange={pageSizeHandler}
                            onPageChange={pageHandler}
                            disableColumnMenu={true}
                            sortModel={sortModel}
                            onSortModelChange={sortHandler}
                            sortingMode="server"
                            density={isMobile ? "compact" : "standard" }
                            components={{ Toolbar: (() => getCustomTableToolbar(handleAdd, t("Add"))), }}
                        />
                    )
                }
            </Box>
            <DimDialog {...formAccount} modalCloseCallback={handleModalResponse}
                dialogText={t("Are you sure you want to delete this account?")} isMobile={isMobile}
                titleEdit={t("Edit Account")} titleCreate={t("Create Account")} titleDelete={t("Delete Account")} />
        </Container>
    );
}

const mapStateToProps = (state) => {
    return {
        isMobile: state.appSettings.isMobile,
        defaultAccountId: state.user.defaultAccountId,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createAccountProp: ({ name, description }, callback) => dispatch(createAccount({ name, description }, callback)),
        updateAccountProp: ({ id, name, description }, callback) => dispatch(updateAccount({ id, name, description }, callback)),
        deleteAccountProp: ({ id }, callback) => dispatch(deleteAccount({ id }, callback)),
        setDefaultProp: ({ defaultAccountId }, callback) => dispatch(setUserDefaultAccount({ defaultAccountId }, callback)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountWidget);
