import React from "react";
import List from '@mui/material/List';
import ListItemButton from "@mui/material/ListItemButton";
import GenericDialog from "./GenericDialog";

const ListDialog = (props) => {

    const { open, title, text, attrName, selected, items, onClose } = props;

    const handleClose = () => {
        if (selected) {
            const mapped = items.filter(element => element.id === selected.id);
            if (mapped.length > 0) {
                onClose(mapped[0]);
            }
            else {
                onClose(null);
            }
        }
        else {
            onClose(null);
        }
    }

    const handleSelectFilter = (filter) => {
        onClose(filter);
    }

    return (
        <GenericDialog open={open} onClose={handleClose} title={title} text={text}>
            <List>
                {
                    items.map(element => (
                        <ListItemButton
                            key={`filter-${element.id}`}
                            onClick={() => handleSelectFilter(element)}>
                            {element[attrName]}
                        </ListItemButton>
                    ))
                }
            </List>
        </GenericDialog>
    );
}

export default ListDialog;
