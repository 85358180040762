import { axiosGet } from "./instance";

export const apiDataseries = (
    {
        groups,
        products,
        categories,
        items,
        entities,
        tags,
        startDate,
        endDate,
        amountMin,
        amountMax,
        transactionTypes,
        amountTypes,
        productTypes,
        dateAggrType,
        dateRange
    },
    callback
) => {
    axiosGet("transaction/dataseries", {
        product_groups: groups,
        products: products,
        categories: categories,
        items: items,
        entities: entities,
        tags: tags,
        start_date: startDate,
        end_date: endDate,
        amount_min: amountMin,
        amount_max: amountMax,
        transaction_types: transactionTypes,
        amount_types: amountTypes,
        product_types: productTypes,
        date_aggr_type: dateAggrType,
        date_range: dateRange,
    }, callback);
}

export const apiTotals = ({
    groups,
    products,
    categories,
    items,
    entities,
    tags,
    startDate,
    endDate,
    amountMin,
    amountMax,
    transactionTypes,
    amountTypes,
    productTypes,
    dateRange,
    dimension = null,
}, callback) => {
    axiosGet("transaction/totals", {
        product_groups: groups,
        products: products,
        categories: categories,
        items: items,
        entities: entities,
        tags: tags,
        start_date: startDate,
        end_date: endDate,
        amount_min: amountMin,
        amount_max: amountMax,
        transaction_types: transactionTypes,
        amount_types: amountTypes,
        product_types: productTypes,
        date_range: dateRange,
        dimension: dimension,
    }, callback);
}

export const apiPeriodic = ({
    groups,
    products,
    categories,
    items,
    entities,
    tags,
    startDate,
    endDate,
    amountMin,
    amountMax,
    transactionTypes,
    amountTypes,
    productTypes,
    dateRange,
    frequency,
    hasProduct,
    hasCategory,
    hasItem,
    hasEntity,
    parcel,
}, callback) => {
    axiosGet("transaction/periodic", {
        product_groups: groups,
        products: products,
        categories: categories,
        items: items,
        entities: entities,
        tags: tags,
        start_date: startDate,
        end_date: endDate,
        amount_min: amountMin,
        amount_max: amountMax,
        transaction_types: transactionTypes,
        amount_types: amountTypes,
        product_types: productTypes,
        frequency: frequency,
        date_range: dateRange,
        has_product: hasProduct,
        has_category: hasCategory,
        has_item: hasItem,
        has_entity: hasEntity,
        parcel: parcel,
    }, callback);
}
