import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { apiGroupGet } from "../../api/group";
import { updateGroup } from "../../store/actions/group";
import DimensionPage from "../../layout/DimensionPage";

const GroupView = (props) => {
    const { t } = useTranslation(["dimension"]);
    const { updateMethod } = props;
    return (
        <DimensionPage dimension="group" title={t("Product Group")} instanceId={props.match.params.id}
            getMethod={apiGroupGet} dataKey="groups" updateMethod={updateMethod}
            spec={
                [
                    {id: "name", required: true, label: t("Name"), type: "text", value: null},
                    {id: "description", required: false, label: t("Description"), type: "area", value: null},
                ]
            }
        />
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateMethod: ({ id, name, description }, callback) => dispatch(updateGroup({ id, name, description }, callback)),
    }
}

export default connect(null, mapDispatchToProps)(GroupView);
