import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { useTheme, styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";

import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';

import { formatNumberAmount } from "../../helpers/format";

import ProductChip from "../chip/ProductChip";
import CategoryChip from "../chip/CategoryChip";
import ItemChip from "../chip/ItemChip";
import EntityChip from "../chip/EntityChip";
import TagChip from "../chip/TagChip";
import FileChip from "../chip/FileChip";
import AmountTypeChip from "../chip/AmountTypeChip";
import ListFieldsDialog from "../dialog/ListFieldsDialog";

const HeaderItem = styled(Typography)(({ theme }) => ({
    fontWeight: "bolder",
    fontSize: "135%",
}));

const DateItem = styled(HeaderItem)(({ theme }) => ({
    color: theme.palette.grey[700],
}));

const AmountItem = styled(HeaderItem)(({ theme }) => ({
    color: theme.palette.primary.main,
}));

const DimensionList = styled("ul")(({ theme }) => ({
    display: "flex",
    flexWrap: "wrap",
    listStyle: "none",
    margin: 0,
    padding: 0,
}));

const DimensionItem = styled("li")(({ theme }) => ({
    margin: theme.spacing(0.4),
}));

const TransactionCard = (props) => {

    const theme = useTheme();
    const { t } = useTranslation(["transaction", "amount_type", "transaction_type", "product"]);

    // global variables
    const { canUpdateAccount } = props;

    // transaction record
    const { transaction } = props;

    // actions
    const { deleteMethod, onSelect, checked = false } = props;

    // details dialog
    const [detailsFields, setDetailsFields] = React.useState(null);
    const detailsOpen = Boolean(detailsFields)
    const onInfoClick = () => {
        setDetailsFields([
            {primary: t("Date"), secondary: transaction.date},
            {primary: t("Amount"), secondary: formatNumberAmount(transaction.value)},
            {primary: t("Transaction Type"), secondary: t(transaction.transactionType, {ns: "transaction_type"})},
            {primary: t("Amount Type"), secondary: t(transaction.amountType, {ns: "amount_type"})},
            {primary: t("Product Group"), secondary: transaction.productGroupName},
            {primary: t("Product Type"), secondary: t(transaction.productType, {ns: "product"})},
            {primary: t("Description"), secondary: transaction.description},
        ])
    }

    return (
        <React.Fragment>
            <Paper elevation={checked ? 4 : 1} sx={{padding: theme.spacing(1), backgroundColor: checked ? grey[100] : null}}>
                <Grid container>
                    <Grid item xs={6}>
                        <DateItem>{transaction.date}</DateItem>
                    </Grid>
                    <Grid item xs={6} textAlign="right">
                        <AmountItem>{formatNumberAmount(transaction.value)}</AmountItem>
                    </Grid>
                    <Grid item xs={12}>
                        <DimensionList>
                            <DimensionItem>
                                <ProductChip label={transaction.productName} productId={transaction.productId} />
                            </DimensionItem>
                            <DimensionItem>
                                <CategoryChip label={transaction.categoryName} categoryId={transaction.categoryId} />
                            </DimensionItem>
                            <DimensionItem>
                                <ItemChip label={transaction.itemName} itemId={transaction.itemId} />
                            </DimensionItem>
                            {
                                transaction.entityId ? (
                                    <DimensionItem>
                                        <EntityChip label={transaction.entityName} entityId={transaction.entityId} />
                                    </DimensionItem>
                                ) : null
                            }
                            {
                                transaction.tags.map(element => {
                                    return (
                                        <DimensionItem key={`tag-${transaction.id}-${element.tagId}`}>
                                            <TagChip label={element.tagName} tagId={element.tagId} />
                                        </DimensionItem>
                                    )
                                })
                            }
                            {
                                transaction.files.map(element => {
                                    return (
                                        <DimensionItem key={`file-${transaction.id}-${element.fileId}`}>
                                            <FileChip label={element.originalFileName} fileId={element.fileId} />
                                        </DimensionItem>
                                    )
                                })
                            }
                            <DimensionItem>
                                <AmountTypeChip label={transaction.amountType} />
                            </DimensionItem>
                        </DimensionList>
                    </Grid>
                    <Grid item xs={3}>
                        <Checkbox checked={checked} onChange={onSelect} />
                    </Grid>
                    <Grid item xs={9} textAlign="right">
                        <IconButton disabled={!canUpdateAccount} onClick={() => {deleteMethod(transaction)}}><DeleteIcon /></IconButton>
                        <IconButton disabled={!canUpdateAccount} to={`/transaction/edit/${transaction.id}`} component={RouterLink}><EditIcon /></IconButton>
                        <IconButton onClick={() => {onInfoClick(transaction)}}><InfoRoundedIcon /></IconButton>
                        <IconButton disabled={!canUpdateAccount} to={`/transaction/copy/${transaction.id}`} component={RouterLink}><ContentCopyIcon /></IconButton>
                    </Grid>
                </Grid>
            </Paper>
            <ListFieldsDialog open={detailsOpen} onClose={() => setDetailsFields(null)} title={t("Details")} items={detailsFields} />
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        canUpdateAccount: state.user.canUpdateAccount,
    }
}

export default connect(mapStateToProps)(TransactionCard);
