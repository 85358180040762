import React from "react";
import Typography from "@mui/material/Typography";

const SectionTitle = (props) => {
    return (
        <Typography component="h2" variant="h3">{props.text}</Typography>
    );
}

export default SectionTitle;
