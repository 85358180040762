import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useTheme } from "@mui/material/styles";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TablePagination from "@mui/material/TablePagination";

import { createProduct, updateProduct, deleteProduct } from "../../store/actions/product";
import { apiProductVariationIsin, apiProductList, apiProductVariationCrypto } from "../../api/product";
import { formatCurrencyAmount } from "../../helpers/utils";
import { handleFieldErrors } from "../../helpers/error";
import { apiTransactionCreate } from "../../api/transaction";
import moment from "moment";
import SearchInput from "../../components/input/text/SearchInput";
import PageTitle from "../../components/typography/PageTitle";
import DimensionCollectionActions from "../../components/nav/DimensionCollectionActions";
import ProductCard from "../../components/card/ProductCard";
import { downloadProducts } from "../../helpers/download";
import ProductFormDialog from "../../components/dialog/ProductFormDialog";
import InstrumentFormDialog from "../../components/dialog/InstrumentFormDialog";


const ProductList = (props) => {

    const pageSizeOptions = [20, 50, 100];
    const theme = useTheme();
    const { t } = useTranslation(["product", "amount_type"]);
    const history = useHistory();

    const { isMobile, canUpdateAccount } = props;
    const { createMethod, updateMethod, deleteMethod } = props;

    const [rows, setRows] = React.useState([]);
    const [rowCount, setRowCount] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(pageSizeOptions[0]);
    const [pageNumber, setPageNumber] = React.useState(0);
    const [sortModel, setSortModel] = React.useState({ field: "name", sort: "asc" });
    const [searchInput, setSearchInput] = React.useState("");
    const [searchFetch, setSearchFetch] = React.useState("");
    const [selectedProductTypes, setSelectedProductTypes] = React.useState(["_ALL_"]);
    const [selectedProductGroups, setSelectedProductGroups] = React.useState(["_ALL_"]);

    const [formProduct, setFormProduct] = React.useState({
        open: false,
        type: null,
        id: null,
        name: null,
        description: null,
        productType: null,
        productGroupId: null,
        identifier: null,
    });

    const [formInstrument, setFormInstrument] = React.useState({
        id: null,
        open: false,
        baseDate: null,
        baseValue: null,
        currentDate: null,
        currentValue: null,
        lastBalance: null,
        lastTransactionDate: null,
        update: 0.0,
        variation: 0.0,
        lastCategoryId: null,
        lastItemId: null,
        lastEntityId: null,
        lastTags: [],
    });

    const getIdentifier = (entry) => {
        return entry.productType === "FIN" ? entry.instrumentIsin : (
            entry.productType === "CRY" ? entry.cryptoSymbol : (
                entry.productType === "CUR" ? entry.bankIban : null
            )
        )
    }

    const productFilter = (selected) => {
        return selected.filter(element => element !== "_ALL_");
    }

    const fetchMethod = () => {
        apiProductList({
            pageNumber: pageNumber,
            pageSize: pageSize,
            sortDir: sortModel.sort,
            sortCol: sortModel.field,
            search: searchFetch,
            productTypes: productFilter(selectedProductTypes),
            productGroups: productFilter(selectedProductGroups),
        }, (data, successFlag) => {
            if (successFlag > 0) {
                setRows(data.products.map((entry) => {
                    return {
                        id: entry.id,
                        name: entry.name,
                        description: entry.description,
                        productGroupId: entry.productGroupId,
                        productGroupName: entry.productGroupName,
                        productType: entry.productType,
                        identifier: getIdentifier(entry),
                        bal: entry.bal,
                        int: entry.int,
                        upd: entry.upd,
                    }
                }));
                setRowCount(data.totalRows);
            }
        });
    };

    const pageSizeHandlerMobile = (event) => {
        setPageSize(parseInt(event.target.value, 20));
        setPageNumber(0);
    };

    const pageHandlerMobile = (e, newPageNumber) => {
        setPageNumber(newPageNumber);
    };

    const sortHandler = (sortCol) => {
        const dir = sortCol === sortModel.field ? (
            sortModel.sort === "asc" ? "desc" : "asc"
        ) : "asc";
        setSortModel({field: sortCol, sort: dir});
    };

    const handleSubmitSearch = (e) => {
        setSearchFetch(searchInput);
    };

    const handleSearchChange = (e) => {
        setSearchInput(e.target.value);
    };

    const handleResetSearch = () => {
        setSearchFetch("");
        setSearchInput("");
    };

    const handleValueUpdate = (id, method) => {
        method({id}, (data, successFlag) => {
            if (successFlag > 0) {
                setFormInstrument({
                    id: id,
                    open: true,
                    baseDate: data.update.baseDate,
                    baseValue: data.update.baseValue,
                    currentDate: data.update.currentDate,
                    currentValue: data.update.currentValue,
                    lastBalance: data.update.lastBalance,
                    lastTransactionDate: data.update.lastTransactionDate,
                    update: data.update.update,
                    variation: data.update.variation,
                    lastCategoryId: data.lastCategoryId,
                    lastItemId: data.lastItemId,
                    lastEntityId: data.lastEntityId,
                    lastTags: data.lastTags,
                });
            }
            else {
                handleFieldErrors(successFlag, data, "product");
            }
        });
    }

    const handleFinancialInstrumentUpdate = (productId) => {
        handleValueUpdate(productId, apiProductVariationIsin);
    }

    const handleCryptoUpdate = (productId) => {
        handleValueUpdate(productId, apiProductVariationCrypto);
    }

    const handleDelete = (row) => {
        setFormProduct({
            id: row.id,
            open: true,
            type: "delete",
            name: row.name,
            description: row.description,
            productGroupId: row.productGroupId,
            productType: row.productType,
            identifier: getIdentifier(row),
        });
    };

    const handleEdit = (row) => {
        setFormProduct({
            open: true,
            type: "edit",
            id: row.id,
            name: row.name,
            description: row.description,
            productGroupId: row.productGroupId,
            productType: row.productType,
            identifier: getIdentifier(row),
        });
    };

    const handleAdd = (e) => {
        setFormProduct({
            open: true,
            type: "create",
            id: null,
            name: null,
            description: null,
            productGroupId: null,
            productType: null,
            identifier: null,
        });
    }

    const clearProductModal = () => {
        setFormProduct({
            open: false,
            type: null,
            id: null,
            name: null,
            description: null,
            productGroupId: null,
            productType: null,
            identifier: null,
        });
    }

    const clearInstrumentModal = () => {
        setFormInstrument({
            id: null,
            open: false,
            baseDate: null,
            baseValue: null,
            currentDate: null,
            currentValue: null,
            lastBalance: null,
            lastTransactionDate: null,
            update: 0.0,
            variation: 0.0,
            lastCategoryId: null,
            lastItemId: null,
            lastEntityId: null,
            lastTags: [],

        });
    }

    const handleInstrumentResponse = (action) => {

        if (action === "cancel") {
            clearInstrumentModal();
        }

        if (action === "submit") {
            apiTransactionCreate(
                {
                    date: formInstrument.currentDate,
                    amount: Math.abs(formInstrument.update),
                    transactionType: formInstrument.update > 0 ? "CRE" : "DEB",
                    amountType: "UPD",
                    productId: formInstrument.id,
                    categoryId: formInstrument.lastCategoryId,
                    itemId: formInstrument.lastItemId,
                    entityId: formInstrument.lastEntityId ?? "",
                    description: t(
                        "Updated automatically",
                        {
                            price: formatCurrencyAmount(formInstrument.currentValue),
                            date: moment(formInstrument.currentDate).format("YYYY-MM-DD"),
                        }
                    ),
                    tags: formInstrument.lastTags,
                },
                (data, successFlag) => {
                    if (successFlag > 0) {
                        toast.success(t("Your transaction was created successfully"));
                        history.push("/transaction/edit/" + data.transaction.id);
                    }
                    else {
                        clearInstrumentModal();
                    }
                }
            );
        }
    }

    const handleProductResponse = (action, state) => {

        if (action === "cancel") {
            clearProductModal();
        }

        if (action === "submit") {
            
            const paramIdentifier = state.productType === "FIN" ? "instrumentIsin" : (
                state.productType === "CRY" ? "cryptoSymbol" : null
            );
            const paramValue = paramIdentifier ? {[paramIdentifier]: state.identifier} : {};

            if (formProduct.type === "create") {
                createMethod({
                    name: state.name,
                    description: state.description,
                    productGroupId: state.productGroupId,
                    productType: state.productType,
                    ...paramValue,
                }, (data, successFlag) => {
                    if (successFlag > 0) {
                        clearProductModal();
                        fetchMethod();
                        toast.success(t("Product created successfuly"));
                    }
                    else {
                        handleFieldErrors(successFlag, data, "product");
                    }
                });
            }

            if (formProduct.type === "edit") {
                updateMethod({
                    id: formProduct.id,
                    name: state.name,
                    description: state.description,
                    productGroupId: state.productGroupId,
                    productType: state.productType,
                    ...paramValue,
                }, (data, successFlag) => {
                    if (successFlag > 0) {
                        const newRows = rows.map(entry => {
                            if (entry.id === formProduct.id) {
                                return {
                                    id: data.product.id,
                                    name: data.product.name,
                                    description: data.product.description,
                                    productGroupId: data.product.productGroupId,
                                    productGroupName: data.product.productGroupName,
                                    productType: data.product.productType,
                                    identifier: getIdentifier(data.product),
                                    bal: entry.bal,
                                    int: entry.int,
                                    upd: entry.upd,
                                };
                            }
                            return entry;
                        });
                        clearProductModal();
                        setRows(newRows);
                        toast.success(t("Product updated successfuly"));
                    }
                    else {
                        handleFieldErrors(successFlag, data, "product");
                    }
                });
            }

            if (formProduct.type === "delete") {
                deleteMethod({ id: formProduct.id }, (data, successFlag) => {
                    if (successFlag > 0) {
                        clearProductModal();
                        fetchMethod();
                        toast.success(t("Product deleted successfuly"));
                    }
                    else {
                        handleFieldErrors(successFlag, data, "product");
                    }
                });
            }

        }
    }

    const handleDownload = () => {
        apiProductList({
            sortDir: sortModel.sort,
            sortCol: sortModel.field,
            search: searchFetch,
            productTypes: productFilter(selectedProductTypes),
            productGroups: productFilter(selectedProductGroups),
        }, (data, successFlag) => {
            if (successFlag > 0) {
                const records = data.products.map(entry => {
                    return {
                        ...entry,
                        identifier: getIdentifier(entry),
                        balance: entry.bal,
                        interests: entry.int,
                        updates: entry.upd,
                    }
                });
                downloadProducts(records)
            }
        });
    }

    useEffect(() => {
        fetchMethod();
    }, [pageNumber, pageSize, sortModel, searchFetch, selectedProductTypes, selectedProductGroups]);

    const sortItems = [
        {id: "name", label: t("Name")},
        {id: "description", label: t("Description")},
        {id: "product_group_name", label: t("Product Group")},
        {id: "product_type", label: t("Product Type")},
    ].map(element => {
        return {
            selected: sortModel.field === element.id,
            ascending: sortModel.sort === "asc",
            column: element.id,
            label: element.label,
        }
    });

    const handleProductFilter = (menuId, method, state) => {
        if (menuId === "_ALL_") {
            method(["_ALL_"]);
        }
        else {
            const isSelected = state.includes(menuId);
            const addSelection = state.filter(element => {
                if (isSelected) {
                    return element !== "_ALL_" && element !== menuId;
                }
                return element !== "_ALL_"
            });
            if (!isSelected) {
                addSelection.push(menuId);
            }
            method(addSelection);
        }
    }

    const handleProductType = (menuId) => {
        handleProductFilter(menuId, setSelectedProductTypes, selectedProductTypes);
    }

    const handleProductGroup = (menuId) => {
        handleProductFilter(menuId, setSelectedProductGroups, selectedProductGroups);
    }

    return (
        <Box sx={{margin: theme.spacing(1)}}>
            <PageTitle text={t("Products")} />
            <SearchInput
                value={searchInput}
                submit={handleSubmitSearch}
                reset={handleResetSearch}
                onChange={handleSearchChange}
                sx={{marginTop: theme.spacing(1)}} />
            <Box sx={{marginTop: theme.spacing(1)}}>
                <DimensionCollectionActions
                    handleProductType={handleProductType} selectedProductTypes={selectedProductTypes}
                    handleProductGroup={handleProductGroup} selectedProductGroups={selectedProductGroups}
                    onSortChange={sortHandler}
                    sortMenuItems={sortItems}
                    onDownloadClick={handleDownload}
                    handleAdd={handleAdd} />
            </Box>
            <Grid container sx={{ marginY: theme.spacing(1)}} spacing={theme.spacing(1)}>
                {
                    rows.map(row => {
                        const handleExternal = row.productType === "FIN" ? handleFinancialInstrumentUpdate : (
                            row.productType === "CRY" ? handleCryptoUpdate : null
                        );
                        return <Grid item xs={12} sm={4} md={3} key={row.id}>
                            <ProductCard productId={row.id} name={row.name} description={row.description}
                                identifier={row.identifier} productType={row.productType} productGroup={row.productGroupName}
                                balance={row.bal} interests={row.int} updates={row.upd}
                                to={`/product/view/${row.id}`} handleEdit={() => handleEdit(row)}
                                handleDelete={() => handleDelete(row)} handleExternal={() => handleExternal(row.id)} />
                        </Grid>
                    })
                }
            </Grid>
            {
                rowCount > 0
                ? (
                    <TablePagination component="div" count={rowCount} page={pageNumber}
                        onPageChange={pageHandlerMobile} rowsPerPage={pageSize}
                        onRowsPerPageChange={pageSizeHandlerMobile} rowsPerPageOptions={pageSizeOptions} />
                ) : null
            }
            <ProductFormDialog
                open={formProduct.open}
                title={t("Product")}
                type={formProduct.type}
                editState={{
                    name: formProduct.name,
                    productGroupId: formProduct.productGroupId,
                    productType: formProduct.productType,
                    identifier: formProduct.identifier,
                    description: formProduct.description,
                }}
                actionMethod={handleProductResponse} />
            <InstrumentFormDialog
                open={formInstrument.open}
                baseDate={formInstrument.baseDate}
                baseValue={formInstrument.baseValue}
                currentDate={formInstrument.currentDate}
                currentValue={formInstrument.currentValue}
                lastBalance={formInstrument.lastBalance}
                lastTransactionDate={formInstrument.lastTransactionDate}
                update={formInstrument.update}
                variation={formInstrument.variation}
                actionMethod={handleInstrumentResponse} />
        </Box>
    );
}

const mapStateToProps = (state) => {
    return {
        isMobile: state.appSettings.isMobile,
        canUpdateAccount: state.user.canUpdateAccount,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createMethod: ({ name, description, productGroupId, productType, instrumentIsin, cryptoSymbol }, callback) => dispatch(createProduct({ name, description, productGroupId, productType, instrumentIsin, cryptoSymbol }, callback)),
        updateMethod: ({ id, name, description, productGroupId, productType, instrumentIsin, cryptoSymbol }, callback) => dispatch(updateProduct({ id, name, description, productGroupId, productType, instrumentIsin, cryptoSymbol }, callback)),
        deleteMethod: ({ id }, callback) => dispatch(deleteProduct({ id }, callback)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
