import { axiosDelete, axiosGet, axiosPost, axiosPut } from "./instance";

export const apiFilterList = (callback) => {
    axiosGet("filter/list", {}, callback);
}

export const apiFilterCreate = ({
    name,
    groups,
    products,
    categories,
    items,
    entities,
    tags,
    startDate,
    endDate,
    amountMin,
    amountMax,
    transactionTypes,
    amountTypes,
    productTypes,
    dateRange,
}, callback) => {
    axiosPost("filter/create", {
        name: name,
        product_groups: groups,
        products: products,
        categories: categories,
        items: items,
        entities: entities,
        tags: tags,
        start_date: startDate,
        end_date: endDate,
        amount_min: amountMin,
        amount_max: amountMax,
        transaction_types: transactionTypes,
        amount_types: amountTypes,
        product_types: productTypes,
        date_range: dateRange,
    }, callback);
}

export const apiFilterUpdate = ({
    id,
    name,
    groups,
    products,
    categories,
    items,
    entities,
    tags,
    startDate,
    endDate,
    amountMin,
    amountMax,
    transactionTypes,
    amountTypes,
    productTypes,
    dateRange,
}, callback) => {
    axiosPut(`filter/${id}`, {
        name: name,
        product_groups: groups,
        products: products,
        categories: categories,
        items: items,
        entities: entities,
        tags: tags,
        start_date: startDate,
        end_date: endDate,
        amount_min: amountMin,
        amount_max: amountMax,
        transaction_types: transactionTypes,
        amount_types: amountTypes,
        product_types: productTypes,
        date_range: dateRange,
    }, callback);
}

export const apiFilterDelete = ({ id }, callback) => {
    axiosDelete(`filter/${id}`, callback);
}
