/**
 * formatNumberAmount(n, x, s, c)
 * 
 * @param val: original value
 * @param currSign: currency signal
 * @param n: length of decimal
 * @param x: length of whole part
 * @param s: sections delimiter
 * @param c: decimal delimiter
 */
export const formatNumberAmount = (val, currSign = 'EUR', n = 2, x = 3, s = '.', c = ',', includePosSignal = true) => {
    if (val) {
        const checkZero = Math.round(val * 10**n) === 0 ? 0 : val;
        const num = checkZero.toFixed(Math.max(0, ~~n));
        const re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')';
        const conv = (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
        const signal = includePosSignal && val > 0 ? '+' : '';
        if (currSign) return `${signal}${conv} ${currSign}`;
        return `${signal}${conv}`;
    }
    return "-";
}

/**
 * formatNumberPercentage(n, x, s, c)
 * 
 * @param val: original value
 * @param currSign: currency signal
 * @param n: length of decimal
 * @param x: length of whole part
 * @param s: sections delimiter
 * @param c: decimal delimiter
 */
 export const formatNumberPercentage = (val, pctSign = '%', n = 2, x = 3, s = '.', c = ',', includePosSignal = true) => {
    if (val) {
        const checkZero = Math.round(val * 100 * 10**n) === 0 ? 0 : val * 100;
        const num = checkZero.toFixed(Math.max(0, ~~n));
        const re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')';
        const conv = (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
        const signal = includePosSignal && val > 0 ? '+' : '';
        if (pctSign) return `${signal}${conv} ${pctSign}`;
        return `${signal}${conv}`;
    }
    return "-";
}

export const unpackLabeledParcel = (element) => {
    const key = element.split("|")[0];
    const label = element.substring(element.indexOf("|") + 1);
    return {key, label}
};
