import React from "react";
import FormTextInput from "./FormTextInput";

const FormTextAreaInput = (props) => {

    const {
        id,
        label,
        onChange,
        value,
        margin,
        required = false,
        disabled = false,
        minRows = 1,
        maxRows = 4,
    } = props;

    return (
        <FormTextInput
            id={id}
            label={label}
            disabled={disabled}
            onChange={onChange}
            value={value}
            multiline={true}
            minRows={minRows}
            maxRows={maxRows}
            required={required}
            margin={margin}
        />
    );
}

export default FormTextAreaInput;

