import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";

import { apiTransactionList, apiTransactionDelete } from "../../api/transaction";

import TransactionCard from "../card/TransactionCard";
import DeleteTransactionDialog from "../dialog/DeleteTransactionDialog";
import Pagination from "./Pagination";
import TransactionCollectionActions from "../nav/TransactionCollectionActions";
import SearchInput from "../input/text/SearchInput";

import { downloadTransactions } from "../../helpers/download";
import { Alert } from "@mui/material";

const DELETE_DIALOG_INIT = {
    open: false,
    transactions: [],
}

const TransactionCollection = (props) => {

    const { t } = useTranslation(["transaction"]);
    const theme = useTheme();

    // rows of transactions
    const [transactions, setTransactions] = React.useState([]);

    // page and sort settings
    const pageSizeOptions = [25, 50, 100];
    const [pageNumber, setPageNumber] = useState(0);
    const [pageSize, setPageSize] = useState(pageSizeOptions[0]);
    const [currentSort, setCurrentSort] = useState({col: "date", dir: "desc"});
    const [rowCount, setRowCount] = useState(0);

    // search
    const [search, setSearch] = useState("");
    const [searchTemp, setSearchTemp] = useState("");

    // sort options
    const [sortMenuItems, setSortMenuItems] = React.useState([
        {column: "date", ascending: false, selected: true, label: t("Date")},
        {column: "product_name", ascending: true, selected: false, label: t("Product")},
        {column: "category_name", ascending: true, selected: false, label: t("Category")},
        {column: "item_name", ascending: true, selected: false, label: t("Item")},
        {column: "entity_name", ascending: true, selected: false, label: t("Entity")},
        {column: "value", ascending: true, selected: false, label: t("Value")},
    ]);

    // rows selected
    const [selectedRows, setSelectedRows] = useState([]);

    // delete dialog state
    const [deleteDialog, setDeleteDialog] = React.useState(DELETE_DIALOG_INIT)

    // filters
    const {
        groups,
        products,
        categories,
        items,
        entities,
        tags,
        startDate,
        endDate,
        amountMin,
        amountMax,
        transactionTypes,
        amountTypes,
        productTypes,
        dateRange,
    } = props;

    // other specs
    const { height = null } = props;

    const paginationRef = useRef(null);
    const topRef = useRef(null);
    let sxContent = {};
    let sxInnerBox = {};

    if (height && paginationRef.current && topRef.current) {
        const remainHeight = height - paginationRef.current.offsetHeight - topRef.current.offsetHeight;
        sxContent = {height: remainHeight, overflow: "auto"};
        sxInnerBox = {padding: theme.spacing(1)};
    }

    // method to fetch data
    const fetch = (isDownload = false) => {
        apiTransactionList({
            pageNumber: isDownload ? null : pageNumber,
            pageSize: isDownload ? null : pageSize,
            sortCol: currentSort.col,
            sortDir: currentSort.dir,
            search,
            groups,
            products,
            categories,
            items,
            entities,
            tags,
            startDate,
            endDate,
            amountMin,
            amountMax,
            transactionTypes,
            amountTypes,
            productTypes,
            dateRange,
            includeDescriptions: false,
        }, (data, successFlag) => {
            if (successFlag > 0) {
                if (isDownload) {
                    downloadTransactions(data.transactions);
                }
                else {
                    setTransactions(data.transactions.map(element => {
                        return {
                            id: element.id,
                            date: element.date,
                            value: element.value,
                            description: element.description,
                            productGroupId: element.productGroupId,
                            productGroupName: element.productGroupName,
                            productType: element.productType,
                            productId: element.productId,
                            productName: element.productName,
                            categoryId: element.categoryId,
                            categoryName: element.categoryName,
                            itemId: element.itemId,
                            itemName: element.itemName,
                            entityId: element.entityId,
                            entityName: element.entityName,
                            amountType: element.amountType,
                            transactionType: element.transactionType,
                            tags: element.tags.map(tag => {
                                return {
                                    tagId: tag.tagId,
                                    tagName: tag.tagName
                                }
                            }),
                            files: element.files.map(file => {
                                return {
                                    fileId: file.fileId,
                                    originalFileName: file.originalFileName
                                }
                            }),
                        }
                    }));
                    setRowCount(data.totalRows);
                }
            }
        });
    };

    const deleteItem = (transaction) => {
        setDeleteDialog({
            open: true,
            transactions: [
                {
                    id: transaction.id,
                    date: transaction.date,
                    value: transaction.value,
                    productName: transaction.productName,
                }
            ]
        })
    }

    const transactionAction = (dialogData) => {

        if (dialogData.action === "submit") {

            let promises = [];
            dialogData.idList.forEach(id => {
                promises.push(
                    new Promise((resolve, reject) => {
                        apiTransactionDelete({ id }, (data, successFlag) => {
                            if (successFlag > 0) {
                                return resolve(true)
                            }
                            return reject(false);
                        });
                    })
                )
            });

            Promise.all(promises).then((result) => {

                const totResults = result.length;
                const success = result.filter(element => element).length === totResults;
                const error = result.filter(element => !element).length === totResults;
                fetch();
                if (success) {
                    toast.success(t("Transactions deleted successfuly"));
                }
                else if (error) {
                    toast.error(t("An error occurred while deleting the transactions"));
                }
                else {
                    toast.warning(t("Some transactions were not deleted"));
                }
            }, (err) => {
                console.log(err);
            });
        }

        setDeleteDialog(DELETE_DIALOG_INIT);
    }

    const onPageSizeChange = (event) => {
        setPageSize(parseInt(event.target.value));
        setPageNumber(0);
    };

    const onPageChange = (event, newPageNumber) => {
        setPageNumber(newPageNumber);
    };

    const onTransactionSelect = (e, transactionId) => {
        let newSelectedRows = selectedRows.slice();
        if (e.target.checked) {
            newSelectedRows.push(transactionId);
        }
        else {
            newSelectedRows = selectedRows.filter(element => element !== transactionId);
        }
        setSelectedRows(newSelectedRows);
    }

    const onShortChange = (column) => {
        const newSortItems = sortMenuItems.map(element => {
            if (column === element.column) {
                const newAscending = element.selected ? !element.ascending : true;
                setCurrentSort({col: column, dir: newAscending ? "asc": "desc"});
                return {...element, ascending: newAscending, selected: true};
            }
            else {
                return {...element, ascending: true, selected: false}
            }
        });
        setSortMenuItems(newSortItems);
    }

    const onDownloadClick = () => { fetch(true) }

    const onSelectionMenuItemClick = (menuId) => {

        if (menuId === "all") {
            setSelectedRows(transactions.map(element => element.id));
        }

        if (menuId === "deselect") {
            setSelectedRows([]);
        }

        if (menuId === "delete") {
            if (selectedRows.length > 0) {
                setDeleteDialog({
                    open: true,
                    transactions: transactions.filter(element => selectedRows.includes(element.id)).map(element => {
                        return {
                            id: element.id,
                            date: element.date,
                            value: element.value,
                            productName: element.productName,
                        }
                    })
                })
            }
        }
    }

    // search methods
    const onSearchSubmit = () => { setSearch(searchTemp); }
    const onSearchChange = (e) => { setSearchTemp(e.target.value); }
    const onSearchReset = () => { setSearchTemp(""); setSearch(""); }

    useEffect(
        () => { fetch(false) },
        [
            pageSize,
            pageNumber,
            currentSort,
            search,
            groups,
            products,
            categories,
            items,
            entities,
            tags,
            startDate,
            endDate,
            amountMin,
            amountMax,
            transactionTypes,
            amountTypes,
            productTypes,
            dateRange,
        ]
    );

    return (
        <React.Fragment>
            <div ref={topRef}>
                <SearchInput
                    submit={onSearchSubmit}
                    reset={onSearchReset}
                    onChange={onSearchChange}
                    value={searchTemp}
                    sx={{marginBottom: theme.spacing(1)}} />
                <TransactionCollectionActions
                    sortMenuItems={sortMenuItems}
                    onSortChange={onShortChange}
                    onDownloadClick={onDownloadClick}
                    onSelectionMenuItemClick={onSelectionMenuItemClick}
                    selected={selectedRows}
                    allSelected={selectedRows.length === transactions.length} />
            </div>
            <Box sx={sxContent}>
                <Box sx={sxInnerBox}>
                {
                    transactions.length === 0 ? (
                        <Box sx={{marginTop: theme.spacing(1)}}>
                            <Alert severity="info">{t("No transactions found")}</Alert>
                        </Box>
                    ) : transactions.map(element => {
                        return (
                            <Box key={`transaction-card-${element.id}`} sx={{marginTop: theme.spacing(1)}}>
                                <TransactionCard transaction={element} deleteMethod={deleteItem}
                                    onSelect={(e) => onTransactionSelect(e, element.id)}
                                    checked={selectedRows.includes(element.id)} />
                            </Box>
                        )
                    })
                }
                </Box>
            </Box>
            <div ref={paginationRef}>
                <Box sx={{marginTop: theme.spacing(1)}}>
                    <Pagination rowCount={rowCount} page={pageNumber} pageSize={pageSize}
                        onPageChange={onPageChange} onPageSizeChange={onPageSizeChange}
                        rowsPerPageOptions={pageSizeOptions} labelRowsPerPage={t("Page Size")} />
                </Box>
            </div>
            <DeleteTransactionDialog
                open={deleteDialog.open}
                transactions={deleteDialog.transactions}
                actionMethod={transactionAction}
            />
        </React.Fragment>
    );
}

export default TransactionCollection;
