import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Checkbox, DialogContentText, FormControlLabel } from "@mui/material";

import CancelButton from "../../components/input/button/CancelButton";
import SubmitButton from "../../components/input/button/SubmitButton";
import FormTextInput from "../../components/input/text/FormTextInput";

export const MemberDialog = (props) => {

    const { stateEmail, stateIsAdmin, stateCanUpdate, modalCloseCallback, formOpen, formType } = props;
    const { t } = useTranslation(["account"]);

    const [email, setEmail] = React.useState("");
    const [isAdmin, setIsAdmin] = React.useState(false);
    const [canUpdate, setCanUpdate] = React.useState(false);

    useEffect(() => {
        setEmail(stateEmail);
        setIsAdmin(stateIsAdmin);
        setCanUpdate(stateCanUpdate);
    }, [formOpen, stateEmail, stateIsAdmin, stateCanUpdate]);

    const handleEmail = (e) => { setEmail(e.target.value); }
    const handleIsAdmin = (e) => { const newValue = !isAdmin; setIsAdmin(newValue); }
    const handleCanUpdate = (e) => { const newValue = !canUpdate; setCanUpdate(newValue); }

    const handleCancel = (e) => {
        modalCloseCallback({ action: "cancel" });
    }

    const handleSubmit = (e) => {
        modalCloseCallback({ action: "submit", state: { email, isAdmin, canUpdate } });
    }

    const formTitle = () => {
        switch (formType) {
            case "edit":
                return t("Edit Member");
            case "create":
                return t("Create Member");
            case "delete":
                return t("Delete Member");
            default:
                return "";
        }
    }

    const submitText = () => {
        switch (formType) {
            case "edit":
                return t("Update");
            case "create":
                return t("Add");
            case "delete":
                return t("Delete");
            default:
                return "";
        }
    }

    return (
        <Dialog open={formOpen}>
            <DialogTitle>{formTitle()}</DialogTitle>
            <DialogContent>
                {
                    formType === "delete"
                    ? (
                        <DialogContentText>
                            {t("Are you sure you want to delete this member?")}
                        </DialogContentText>
                    )
                    : null
                }
                <FormTextInput
                    required={true}
                    id="email"
                    label={t("Email")}
                    disabled={formType !== "create"}
                    onChange={handleEmail}
                    value={email}
                    type="email"
                />
                <FormControlLabel control={<Checkbox checked={canUpdate} onClick={handleCanUpdate} />} label={t("Can Update")} disabled={formType === "delete"} />
                <FormControlLabel control={<Checkbox checked={isAdmin} onClick={handleIsAdmin} />} label={t("Is Admin")} disabled={formType === "delete"} />
            </DialogContent>
            <DialogActions>
                <CancelButton onClick={handleCancel}>{t("Cancel")}</CancelButton>
                <SubmitButton onClick={handleSubmit}>{submitText()}</SubmitButton>
            </DialogActions>
        </Dialog>
    );
}
