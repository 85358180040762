import React from "react";
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import DimensionChip from "./DimensionChip";
import { GroupChipColor } from "../../helpers/colors";

const GroupChip = (props) => {

    const { label, groupId, clickable = true } = props;

    const chipUrlParam = clickable ? {
        href: `/group/view/${groupId}`,
    } : null;

    return (
        <DimensionChip
            faIcon={faLayerGroup}
            label={label}
            textColor={GroupChipColor.textColor}
            regularColor={GroupChipColor.regularColor}
            hoverColor={GroupChipColor.onHoverColor}
            {...chipUrlParam}
        />
    );
}

export default GroupChip;
