import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { createEntity, updateEntity, deleteEntity } from "../../store/actions/entity";
import { apiEntityList } from "../../api/entity";
import DimensionCollection from "../../components/collection/DimensionCollection";


const EntityList = (props) => {

    const { t } = useTranslation(["dim"]);
    const { createMethod, updateMethod, deleteMethod } = props;

    return (
        <DimensionCollection
            createMethod={createMethod}
            updateMethod={updateMethod}
            deleteMethod={deleteMethod}
            listMethod={apiEntityList}
            dimensionTitle={t("Entity")}
            listResultAttribute="entities"
            urlMainPath="entity"
            spec={
                [
                    {id: "name", required: true, label: t("Name"), type: "text"},
                    {id: "identifier", required: false, label: t("Identifier"), type: "text"},
                    {id: "description", required: false, label: t("Description"), type: "area"}
                ]
            } />
    );
}

const mapStateToProps = (state) => {
    return {
        isMobile: state.appSettings.isMobile,
        canUpdateAccount: state.user.canUpdateAccount,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createMethod: ({ name, description, identifier }, callback) => dispatch(createEntity({ name, description, identifier }, callback)),
        updateMethod: ({ id, name, description, identifier }, callback) => dispatch(updateEntity({ id, name, description, identifier }, callback)),
        deleteMethod: ({ id }, callback) => dispatch(deleteEntity({ id }, callback)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EntityList);
