import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";

import Box from "@mui/material/Box";

import TransactionCollection from "../../components/collection/TransactionCollection"
import PageTitle from "../../components/typography/PageTitle";

const TransactionList = (props) => {

    const theme = useTheme();
    const { t } = useTranslation(["transaction"]);

    return (
        <Box margin={theme.spacing(1)}>
            <PageTitle text={t("Transactions")} />
            <Box sx={{marginTop: theme.spacing(1)}}>
                <TransactionCollection />
            </Box>
        </Box>
    );
}

export default TransactionList;
