import { USER_AUTH_INFO, USER_DEFAULT_ACCOUNT, USER_UPDATE } from "../actions/types";
import { ACCOUNT_CREATE, ACCOUNT_UPDATE, ACCOUNT_DELETE } from "../actions/types";
import { DIM_CREATE, DIM_UPDATE, DIM_DELETE, DIM_MOVE } from "../actions/types";
import { getIndexInArrayByAttr, getInitials } from "../../helpers/utils";

const initState = {
    uid: null,
    displayName: null,
    photoURL: null,
    email: null,
    providers: null,
    initials: null,
    defaultAccountId: null,
    canUpdateAccount: false,
    accounts: [],
    categories: [],
    items: [],
    entities: [],
    tags: [],
    groups: [],
    products: [],
    productTypes: [],
    transactionTypes: [],
    amountTypes: [],
    dateRangeOptions: [],
    widgetTypes: [],
};

const userReducer = (state = initState, action) => {

    switch (action.type) {

        case USER_AUTH_INFO:
            if (action.payload.userAuth) {
                return {
                    ...state,
                    uid: action.payload.userAuth.uid,
                    displayName: action.payload.userAuth.displayName,
                    photoURL: action.payload.userAuth.photoURL,
                    email: action.payload.userAuth.email,
                    providers: action.payload.userAuth.providerData.map((entry) => entry.providerId),
                    initials: getInitials(
                        action.payload.userAuth.displayName && action.payload.userAuth.displayName !== ""
                        ? action.payload.userAuth.displayName
                        : action.payload.userAuth.email
                    ),
                    defaultAccountId: action.payload.userData.default_account_id,
                    canUpdateAccount: action.payload.userData.can_update,
                    accounts: action.payload.userData.accounts,
                    categories: action.payload.userData.categories,
                    items: action.payload.userData.items,
                    entities: action.payload.userData.entities,
                    tags: action.payload.userData.tags,
                    products: action.payload.userData.products.map((entry) => {
                        return {
                            id: entry.id,
                            name: entry.name,
                            productType: entry.product_type,
                            productGroupId: entry.product_group_id,
                            productGroupName: entry.product_group_name,
                        }
                    }),
                    groups: action.payload.userData.groups,
                    productTypes: action.payload.userData.product_types,
                    transactionTypes: action.payload.userData.transaction_types,
                    amountTypes: action.payload.userData.amount_types,
                    dateRangeOptions: action.payload.userData.date_range_options,
                    widgetTypes: action.payload.userData.widget_types,
                };
            }
            else {
                return {
                    ...state,
                    uid: null,
                    displayName: null,
                    photoURL: null,
                    email: null,
                    providers: null,
                    initials: null,
                    defaultAccountId: null,
                    canUpdateAccount: false,
                    accounts: [],
                    categories: [],
                    items: [],
                    entities: [],
                    tags: [],
                    products: [],
                    groups: [],
                    productTypes: [],
                    amountTypes: [],
                    transactionTypes: [],
                    dateRangeOptions: [],
                    widgetTypes: [],
                };
            }

        case USER_UPDATE:
            return {
                ...state,
                displayName: action.payload.displayName,
                initials: getInitials(
                    action.payload.displayName && action.payload.displayName !== ""
                    ? action.payload.displayName
                    : state.email
                ),
            };

        case USER_DEFAULT_ACCOUNT:
            return {
                ...state,
                defaultAccountId: action.payload.default_account_id,
                canUpdateAccount: action.payload.can_update,
                accounts: action.payload.accounts,
                categories: action.payload.categories,
                items: action.payload.items,
                entities: action.payload.entities,
                tags: action.payload.tags,
                products: action.payload.products.map((entry) => {
                    return {
                        id: entry.id,
                        name: entry.name,
                        productType: entry.product_type,
                        productGroupId: entry.product_group_id,
                        productGroupName: entry.product_group_name,
                    }
                }),
                groups: action.payload.groups,
                productTypes: action.payload.product_types,
                transactionTypes: action.payload.transaction_types,
                amountTypes: action.payload.amount_types,
                dateRangeOptions: action.payload.date_range_options,
                widgetTypes: action.payload.widget_types,
            };

        case ACCOUNT_CREATE:
            let addAccount = state.accounts.slice();
            addAccount.push({ id: action.payload.id, name: action.payload.name });
            addAccount.sort((a, b) => a.name.localeCompare(b.name));
            return {
                ...state,
                accounts: addAccount,
            };

        case ACCOUNT_UPDATE:
            const updateAccount = state.accounts.map((row) => {
                if (row.id === action.payload.id) {
                    return {
                        id: action.payload.id,
                        name: action.payload.name,
                    }
                }
                return row;
            });
            updateAccount.sort((a, b) => a.name.localeCompare(b.name));
            return {
                ...state,
                accounts: updateAccount,
            };
    
        case ACCOUNT_DELETE:
            const deleteAccount = state.accounts.filter((obj) => (obj.id !== action.payload));
            const deleteEffect = (
                state.defaultAccountId === action.payload
                ? {
                    defaultAccountId: null,
                    canUpdateAccount: false,
                    accounts: deleteAccount,
                    categories: [],
                    items: [],
                    entities: [],
                    tags: [],
                    products: [],
                    groups: [],
                } : {}
            );
            return {
                ...state,
                ...deleteEffect,
            };

        case DIM_CREATE:
            let addDim = state[action.payload.attr].slice();
            let dimObjAdd = { id: action.payload.data.id, name: action.payload.data.name };
            if (action.payload.attr === "products") {
                dimObjAdd = {
                    ...dimObjAdd,
                    productType: action.payload.data.productType,
                    productGroupId: action.payload.data.productGroupId,
                    productGroupName: action.payload.data.productGroupName,
                };
            }
            if (action.payload.attr === "groups") {
                dimObjAdd = {
                    ...dimObjAdd,
                    order: action.payload.data.order,
                };
            }
            addDim.push(dimObjAdd);
            if (action.payload.attr === "products") {
                addDim.sort((a, b) => (a.productGroupName.localeCompare(b.productGroupName) || a.name.localeCompare(b.name)));
            }
            else if (action.payload.attr === "groups") {
                addDim.sort((a, b) => (a.order - b.order));
            }
            else {
                addDim.sort((a, b) => a.name.localeCompare(b.name));
            }
            return {
                ...state,
                [action.payload.attr]: addDim,
            };

        case DIM_UPDATE:
            const updateDim = state[action.payload.attr].map((row) => {
                if (row.id === action.payload.data.id) {
                    let dimObjUpd = {
                        id: action.payload.data.id,
                        name: action.payload.data.name,
                    }
                    if (action.payload.attr === "products") {
                        dimObjUpd = {
                            ...dimObjUpd,
                            productType: action.payload.data.productType,
                            productGroupId: action.payload.data.productGroupId,
                            productGroupName: action.payload.data.productGroupName,
                        };
                    }
                    if (action.payload.attr === "groups") {
                        dimObjUpd = {
                            ...dimObjUpd,
                            order: action.payload.data.order,
                        };
                    }
                    return dimObjUpd;
                }
                return row;
            });
            if (action.payload.attr === "products") {
                updateDim.sort((a, b) => (a.productGroupName.localeCompare(b.productGroupName) || a.name.localeCompare(b.name)));
            }
            else if (action.payload.attr === "groups") {
                updateDim.sort((a, b) => (a.order - b.order));
            }
            else {
                updateDim.sort((a, b) => a.name.localeCompare(b.name));
            }
            return {
                ...state,
                [action.payload.attr]: updateDim,
            };
    
        case DIM_DELETE:
            const deleteDim = state[action.payload.attr].filter((obj) => (obj.id !== action.payload.data));
            const newProducts = (
                action.payload.attr === "groups"
                ? state.products.filter((obj) => (obj.productGroupId !== action.payload.data))
                : state.products.slice()
            );
            return {
                ...state,
                [action.payload.attr]: deleteDim,
                products: newProducts,
            };

        case DIM_MOVE:
            const idxToMove = getIndexInArrayByAttr(state["groups"], "id", action.payload.id);
            let rowsOrder = state["groups"].slice();
            rowsOrder[idxToMove].order = rowsOrder[idxToMove].order + action.payload.incr;
            rowsOrder[idxToMove + action.payload.incr].order = rowsOrder[idxToMove + action.payload.incr].order - action.payload.incr;
            rowsOrder.sort((a, b) => (a.order - b.order));
            return {
                ...state,
                groups: rowsOrder,
            };

        default:
            return state;
    }
}

export default userReducer;
