import { axiosDelete, axiosGet, axiosPost, axiosPut } from "./instance";

export const apiTransactionList = (
    {
        pageNumber,
        pageSize,
        sortCol,
        sortDir,
        search,
        groups,
        products,
        categories,
        items,
        entities,
        tags,
        startDate,
        endDate,
        amountMin,
        amountMax,
        transactionTypes,
        amountTypes,
        productTypes,
        dateRange,
        includeDescriptions
    },
    callback
) => {
    axiosGet("transaction/list", {
        page_number: pageNumber,
        page_size: pageSize,
        sort_col: sortCol,
        sort_dir: sortDir,
        search: search,
        product_groups: groups,
        products: products,
        categories: categories,
        items: items,
        entities: entities,
        tags: tags,
        start_date: startDate,
        end_date: endDate,
        amount_min: amountMin,
        amount_max: amountMax,
        transaction_types: transactionTypes,
        amount_types: amountTypes,
        product_types: productTypes,
        date_range: dateRange,
        include_descriptions: includeDescriptions,
    }, callback);
}

export const apiTransactionDelete = ({ id }, callback) => {
    axiosDelete(`transaction/${id}`, callback);
}

export const apiGetFileUrl = ({ id }, callback) => {
    axiosGet(`transaction/file/${id}`, {}, callback);
}

export const apiTransactionCreate = (
    {
        date,
        amount,
        description,
        transactionType,
        amountType,
        productId,
        categoryId,
        itemId,
        entityId,
        tags,
        files = [],
    },
    callback
) => {
    const formParams = new FormData();
    formParams.append("date", date);
    formParams.append("amount", amount);
    formParams.append("description", description);
    formParams.append("transaction_type", transactionType);
    formParams.append("amount_type", amountType);
    formParams.append("product_id", productId);
    formParams.append("category_id", categoryId);
    formParams.append("item_id", itemId);
    formParams.append("entity_id", entityId);
    formParams.append("tags", tags);
    files.forEach(element => {
        formParams.append("files", element, element.name);
    });
    axiosPost("transaction/create", formParams, callback, undefined, true);
}
