import React from "react";
import { connect } from "react-redux";

import ButtonGroup from "@mui/material/ButtonGroup";
import Box from "@mui/material/Box";

const ButtonGroupWrapper = (props) => {
    const { isMobile, fullWidth = true } = props;
    return isMobile || !fullWidth ? (
        <Box sx={{display: "flex", justifyContent: "right"}}>
            {props.children}
        </Box>
    ) : (
        <ButtonGroup disableElevation variant="outlined" fullWidth>
            {props.children}
        </ButtonGroup>
    )
}

const mapStateToProps = (state) => {
    return {
        isMobile: state.appSettings.isMobile,
    }
}

export default connect(mapStateToProps)(ButtonGroupWrapper);
