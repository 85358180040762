import * as React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import IconButton from "@mui/material/IconButton";

const ActionButtonMenuTransactionSelection = (props) => {

    const { t } = useTranslation(["collection"]);
    const { handleMenuClick, isMobile, selected, allSelected } = props;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => { setAnchorEl(event.currentTarget) };
    const handleClose = () => { setAnchorEl(null) };

    const handleMenu = (menuId) => {
        handleClose();
        handleMenuClick(menuId);
    }

    const buttonCommonParams = {
        onClick: handleClick,
    }

    const actionsDisabled = selected.length === 0;

    const handleCopy = () => {
        selected.forEach(element => {
            const a = document.createElement("a");
            a.setAttribute("hidden", "");
            a.setAttribute("href", `/transaction/copy/${element}`);
            a.setAttribute("target", "_blank");
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        });
    }

    const icon = <CheckBoxIcon />;
    const menuItemMinWidth = {minWidth: "200px"};

    return (
        <React.Fragment>
            {
                isMobile ? (
                    <IconButton {...buttonCommonParams}>{icon}</IconButton>
                ) : (
                    <Button startIcon={icon} {...buttonCommonParams}>{t("Selection")}</Button>
                )
            }
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem sx={menuItemMinWidth} onClick={() => handleMenu(allSelected ? "deselect" : "all")}>{allSelected ? t("Clear Selection") : t("Select All")}</MenuItem>
                <MenuItem sx={menuItemMinWidth} disabled={actionsDisabled} onClick={() => handleMenu("delete")}>{t("Delete")}</MenuItem>
                <MenuItem sx={menuItemMinWidth} disabled={true} onClick={() => handleMenu("edit")}>{t("Edit (upcoming)")}</MenuItem>
                <MenuItem sx={menuItemMinWidth} disabled={actionsDisabled} onClick={handleCopy}>{t("Copy to New Tabs")}</MenuItem>
            </Menu>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        isMobile: state.appSettings.isMobile,
    }
}

export default connect(mapStateToProps)(ActionButtonMenuTransactionSelection);
