import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useTheme } from "@mui/material/styles";

import ReportItems from "./ReportItems";

const ReportEdition = (props) => {

    const theme = useTheme();
    const { t } = useTranslation(["report"]);
    const { canUpdateAccount } = props;

    const [reportId, setReportId] = React.useState(null);

    useEffect(() => {
        if (canUpdateAccount) {
            setReportId(props.match.params.id);
        }
        else {
            toast.error(t("Operation Not Allowed"));
        }
    }, [canUpdateAccount, props]);

    return (
        reportId ? <ReportItems reportId={reportId} editMode={true} /> : null
    )
}

const mapStateToProps = (state) => {
    return {
        canUpdateAccount: state.user.canUpdateAccount,
    }
}

export default connect(mapStateToProps)(ReportEdition);
