import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { useTheme, styled } from "@mui/material/styles";
import { deepOrange } from '@mui/material/colors';

import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { faWallet } from "@fortawesome/free-solid-svg-icons";
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import { faSitemap } from "@fortawesome/free-solid-svg-icons";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import { faPuzzlePiece } from "@fortawesome/free-solid-svg-icons";
import { faUniversity } from "@fortawesome/free-solid-svg-icons";
import { faExchangeAlt } from "@fortawesome/free-solid-svg-icons";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { faCreditCard } from "@fortawesome/free-regular-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faChartPie } from "@fortawesome/free-solid-svg-icons";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { faChartBar } from "@fortawesome/free-solid-svg-icons";

import { logout } from "../../helpers/firebase";
import { supportedLanguages } from "../../helpers/utils";


const NavBarIconButton = styled(IconButton)(({ theme }) => `
    margin: ${theme.spacing(0, 1, 0, 0)}
`);


const Navbar = (props) => {

    const theme = useTheme();
    const { t, i18n } = useTranslation(["navbar", "user"]);

    const [open, setOpen] = React.useState(false);
    const handleDrawerOpen = () => { setOpen(true); };
    const handleDrawerClose = () => { setOpen(false); };

    const [anchorLangEl, setAnchorLangEl] = React.useState(null);
    const handleOpenLangMenu = (event) => { setAnchorLangEl(event.currentTarget); };
    const handleCloseLangMenu = (lang) => {
        if (lang) {
            i18n.changeLanguage(lang);
        }
        setAnchorLangEl(null);
    };

    const [anchorUserEl, setAnchorUserEl] = React.useState(null);
    const handleOpenUserMenu = (event) => { setAnchorUserEl(event.currentTarget); };
    const handleCloseUserMenu = () => { setAnchorUserEl(null); };
    const handleSignOut = async () => {
        setAnchorUserEl(null);
        logout().then(() => {
            toast.info(t("User signed out with success", {ns: "user"}));
        })
    };

    return (
        <React.Fragment>
            <AppBar position="static">
                <Toolbar>
                    <IconButton edge="start" color="inherit" component={Link} to={"/"}>
                        <FontAwesomeIcon icon={faHome} />
                    </IconButton>
                    <Typography variant="h6" sx={{ flexGrow: 1 }}>Wallet AM</Typography>
                    <React.Fragment>
                        <React.Fragment>
                            <NavBarIconButton edge="end" onClick={handleOpenUserMenu}>
                                <Avatar sx={{
                                    color: theme.palette.getContrastText(deepOrange[900]),
                                    backgroundColor: deepOrange[900],
                                }}>{props.userInitials}</Avatar>
                            </NavBarIconButton>
                            <Menu anchorEl={anchorUserEl} keepMounted open={Boolean(anchorUserEl)}
                                onClose={() => handleCloseUserMenu(null)}>
                                {props.uid ? <MenuItem onClick={handleCloseUserMenu} component={Link} to={"/user/settings"}>{t("Settings")}</MenuItem> : null}
                                {props.uid ? <MenuItem onClick={handleSignOut}>{t("Sign Out")}</MenuItem> : null}
                                {props.uid === null ? <MenuItem onClick={handleCloseUserMenu} component={Link} to={"/user/login"}>{t("Sign In")}</MenuItem> : null}
                                {props.uid === null ? <MenuItem onClick={handleCloseUserMenu} component={Link} to={"/user/signup"}>{t("Sign Up")}</MenuItem> : null}
                            </Menu>
                        </React.Fragment>
                        <NavBarIconButton edge="end" color="inherit" onClick={handleOpenLangMenu}>
                            <FontAwesomeIcon icon={faGlobe} />
                        </NavBarIconButton>
                        <Menu anchorEl={anchorLangEl} keepMounted open={Boolean(anchorLangEl)}
                            onClose={() => handleCloseLangMenu(null)}>
                            {
                                Object.keys(supportedLanguages()).map((lang) => {
                                    return (
                                        <MenuItem key={lang} onClick={() => handleCloseLangMenu(lang)} selected={i18n.language === lang}>
                                            {supportedLanguages()[lang]}
                                        </MenuItem>
                                    );
                                })
                            }
                        </Menu>
                        {
                            props.uid
                            ? (
                                <NavBarIconButton edge="end" color="inherit" onClick={handleDrawerOpen}>
                                    <FontAwesomeIcon icon={faBars} />
                                </NavBarIconButton>
                            )
                            : null
                        }
                    </React.Fragment>
                </Toolbar>
            </AppBar>
            <Drawer anchor="right" open={open} onClose={handleDrawerClose} PaperProps={{sx: {width: "280px"}}}>
                <div role="presentation" onClick={handleDrawerClose}>
                    <List>
                        <ListItemButton key={"account"} component={Link} to="/account">
                            <ListItemIcon>
                                <FontAwesomeIcon icon={faWallet} size="lg" />
                            </ListItemIcon>
                            <ListItemText primary={t("Accounts")} />
                        </ListItemButton>
                        <ListItemButton key={"group"} component={Link} to="/group">
                            <ListItemIcon>
                                <FontAwesomeIcon icon={faLayerGroup} size="lg" />
                            </ListItemIcon>
                            <ListItemText primary={t("Groups")} />
                        </ListItemButton>
                        <ListItemButton key={"product"} component={Link} to="/product">
                            <ListItemIcon>
                                <FontAwesomeIcon icon={faCreditCard} size="lg" />
                            </ListItemIcon>
                            <ListItemText primary={t("Products")} />
                        </ListItemButton>
                        <ListItemButton key={"category"} component={Link} to="/category">
                            <ListItemIcon>
                                <FontAwesomeIcon icon={faSitemap} size="lg" />
                            </ListItemIcon>
                            <ListItemText primary={t("Categories")} />
                        </ListItemButton>
                        <ListItemButton key={"item"} component={Link} to="/item">
                            <ListItemIcon>
                                <FontAwesomeIcon icon={faPuzzlePiece} size="lg" />
                            </ListItemIcon>
                            <ListItemText primary={t("Items")} />
                        </ListItemButton>
                        <ListItemButton key={"entity"} component={Link} to="/entity">
                            <ListItemIcon>
                                <FontAwesomeIcon icon={faUniversity} size="lg" />
                            </ListItemIcon>
                            <ListItemText primary={t("Entities")} />
                        </ListItemButton>
                        <ListItemButton key={"tag"} component={Link} to="/tag">
                            <ListItemIcon>
                                <FontAwesomeIcon icon={faTag} size="lg" />
                            </ListItemIcon>
                            <ListItemText primary={t("Tags")} />
                        </ListItemButton>
                        <ListItemButton key={"transaction"} component={Link} to="/transaction">
                            <ListItemIcon>
                                <FontAwesomeIcon icon={faExchangeAlt} size="lg" />
                            </ListItemIcon>
                            <ListItemText primary={t("Transactions")} />
                        </ListItemButton>
                        <Divider />
                        <ListItemButton key={"search"} component={Link} to="/filter">
                            <ListItemIcon>
                                <FontAwesomeIcon icon={faSearch} size="lg" />
                            </ListItemIcon>
                            <ListItemText primary={t("Advanced Search")} />
                        </ListItemButton>
                        <ListItemButton key={"widget"} component={Link} to="/widget">
                            <ListItemIcon>
                                <FontAwesomeIcon icon={faChartPie} size="lg" />
                            </ListItemIcon>
                            <ListItemText primary={t("Widgets")} />
                        </ListItemButton>
                        <ListItemButton key={"layout"} component={Link} to="/report">
                            <ListItemIcon>
                                <FontAwesomeIcon icon={faFile} size="lg" />
                            </ListItemIcon>
                            <ListItemText primary={t("Report Layouts")} />
                        </ListItemButton>
                        <Divider />
                        <ListItemButton key={"dashboard"} component={Link} to="/dashboard">
                            <ListItemIcon>
                                <FontAwesomeIcon icon={faChartBar} size="lg" />
                            </ListItemIcon>
                            <ListItemText primary={t("Dashboard")} />
                        </ListItemButton>
                    </List>
                </div>
            </Drawer>
       </React.Fragment>
   );
}

const mapStateToProps = (state) => {
    return {
        uid: state.user.uid,
        userInitials: state.user.initials,
    }
}

export default connect(mapStateToProps, null)(Navbar);
