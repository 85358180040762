import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { toast } from "react-toastify";
import { useTheme } from "@mui/material/styles";
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton } from '@mui/x-data-grid';

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import { addMemberService, updateMemberService, deleteMemberService, getAccountService } from "../../service/account";
import { TooltipCell } from "../../components/table/TooltipCell";
import { MemberDialog } from "./MemberDialog";
import { handleFieldErrors } from "../../helpers/error";
import MobileListItem from "../../components/dim/MobileListItem";


const MemberWidget = (props) => {

    const theme = useTheme();
    const { t } = useTranslation(["account"]);

    const { isMobile } = props;
    const paramId = props.match.params.id;

    const [accountId, setAccountId] = React.useState("");
    const [accountName, setAccountName] = React.useState("");
    const [accountDescription, setAccountDescription] = React.useState("");
    const [rows, setRows] = React.useState([]);
    const [formMember, setFormMember] = React.useState({
        formOpen: false,
        formType: "",
        stateId: "",
        stateEmail: "",
        stateIsAdmin: false,
        stateCanUpdate: false,
    });

    const getFlexMobile = (flexVal) => (isMobile ? null : flexVal);

    const columns = [
        {
            field: "email",
            headerName: t("Email"),
            headerClassName: "table-header",
            flex: getFlexMobile(3),
            renderCell: (params) => <TooltipCell text={params.row.email} />,
            useRenderMethodOnMobile: false,
        },
        {
            field: "display_name",
            headerName: t("Name"),
            headerClassName: "table-header",
            flex: getFlexMobile(3),
            renderCell: (params) => <TooltipCell text={params.row.display_name} />,
            useRenderMethodOnMobile: false,
        },
        {
            field: "can_update",
            headerName: t("Can Update"),
            headerClassName: "table-header",
            flex: getFlexMobile(1),
            type: "boolean",
        },
        {
            field: "is_admin",
            headerName: t("Is Admin"),
            headerClassName: "table-header",
            flex: getFlexMobile(1),
            type: "boolean",
        },
        {
            field: "action",
            headerName: "Actions",
            align: "center",
            headerClassName: "table-header",
            flex: getFlexMobile(1),
            sortable: false,
            renderCell: (params) => (
                <React.Fragment>
                    <IconButton aria-label="Delete" onClick={(e) => {handleDelete(params.row)}} sx={{ marginRigh: theme.spacing(1) }}><DeleteIcon /></IconButton>
                    <IconButton aria-label="Edit"  onClick={(e) => {handleEdit(params.row)}}><EditIcon /></IconButton>
                </React.Fragment>
            ),
            type: "actions",
        },
    ];

    const fetchAccount = () => {
        getAccountService({ id: paramId }, (data, successFlag) => {
            if (successFlag > 0) {
                setRows(data.account.members.map((entry) => ({...entry, id: entry.member_id })));
                setAccountId(data.account.id);
                setAccountName(data.account.name);
                setAccountDescription(data.account.description);
            }
            else {
                setRows([]);
                setAccountId("");
                setAccountName("");
                setAccountDescription("");
            }
        });
    };

    const handleDelete = (row) => {
        setFormMember({
            formOpen: true,
            formType: "delete",
            stateId: row.member_id,
            stateEmail: row.email,
            stateIsAdmin: row.is_admin,
            stateCanUpdate: row.can_update,
        });
    };

    const handleEdit = (row) => {
        setFormMember({
            formOpen: true,
            formType: "edit",
            stateId: row.member_id,
            stateEmail: row.email,
            stateIsAdmin: row.is_admin,
            stateCanUpdate: row.can_update,
        });
    };

    const handleAdd = (e) => {
        setFormMember({
            formOpen: true,
            formType: "create",
            stateId: "",
            stateEmail: "",
            stateIsAdmin: false,
            stateCanUpdate: false,
        });
    }

    const handleModalResponse = ({ action, state }) => {

        if (action === "submit") {

            if (formMember.formType === "create") {
                addMemberService({id: accountId, email: state.email, isAdmin: state.isAdmin, canUpdate: state.canUpdate}, (data, successFlag) => {
                    if (successFlag > 0) {
                        setRows(data.account.members.map((entry) => ({...entry, id: entry.member_id })));
                        toast.success(t("Member added successfuly"));
                    }
                    else {
                        handleFieldErrors(successFlag, data, "account");
                    }
                });
            }

            if (formMember.formType === "edit") {
                updateMemberService({memberId: formMember.stateId, isAdmin: state.isAdmin, canUpdate: state.canUpdate}, (data, successFlag) => {
                    if (successFlag > 0) {
                        setRows(data.account.members.map((entry) => ({...entry, id: entry.member_id })));
                        toast.success(t("Member updated successfuly"));
                    }
                    else {
                        handleFieldErrors(successFlag, data, "account");
                    }
                });
            }

            if (formMember.formType === "delete") {
                deleteMemberService({ memberId: formMember.stateId }, (data, successFlag) => {
                    if (successFlag > 0) {
                        const newRows = rows.filter((obj) => (obj.member_id !== formMember.stateId));
                        setRows(newRows);
                        toast.success(t("Member deleted successfuly"));
                    }
                    else {
                        handleFieldErrors(successFlag, data, "account");
                    }
                });
            }

        }

        setFormMember({
            formOpen: false,
            formType: "",
            stateId: "",
            stateEmail: "",
            stateIsAdmin: false,
            stateCanUpdate: false,
        });
    }

    useEffect(() => {
        fetchAccount();
    }, []);

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer>
                <Grid container>
                    <Grid item>
                        <GridToolbarColumnsButton sx={{ marginRight: theme.spacing(1) }} />
                        <GridToolbarFilterButton />
                    </Grid>
                    <Grid item xs>
                        <Button variant="text" size="small" startIcon={<AddIcon />} onClick={handleAdd}>{t("Add")}</Button>
                    </Grid>
                </Grid>
            </GridToolbarContainer>
        );
    }

    const accountTitle = () => {
        return accountName + " - " + accountDescription;
    }

    return (
        <Container component="main" maxWidth="xl" sx={{ marginTop: theme.spacing(1) }}>
            <Typography component="h1" variant="h1">{t("Account Members")}</Typography>
            <Typography component="div" variant="subtitle1">{accountTitle()}</Typography>
            <Box component="div" sx={{
                marginTop: theme.spacing(1),
                '& .table-header': {
                    backgroundColor: "#eaeaff",
                    fontWeight: "bold", 
                },
            }}>
                {
                    isMobile
                    ? (
                        rows.map((row, idx) => {
                            return <MobileListItem key={`mobileRow${idx}`} dataItem={row}
                                mainAttribute="display_name" mainIndicator="can_update" columns={columns}
                                sx={{ marginTop: theme.spacing(1), padding: theme.spacing(1) }} />
                        })
                    )
                    : (
                        <DataGrid
                            sx={{
                                border: 0,
                                '& .MuiDataGrid-toolbarContainer': {
                                    display: "block",
                                    textAlign: "right",
                                },
                                '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                                    outline: 'none',
                                },
                            }}
                            rows={rows}
                            columns={columns}
                            autoHeight={true}
                            disableSelectionOnClick={true}
                            disableColumnMenu={true}
                            components={{ Toolbar: CustomToolbar, }}
                            density={isMobile ? "compact" : "standard" }
                        />
                    )
                }
            </Box>
            <MemberDialog {...formMember} modalCloseCallback={handleModalResponse} />
        </Container>
    );
}

const mapStateToProps = (state) => {
    return {
        isMobile: state.appSettings.isMobile,
    }
}

export default connect(mapStateToProps)(MemberWidget);
