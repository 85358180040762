import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import { toast } from "react-toastify";
import ErrorMessageToast from "../components/layout/ErrorMessageToast";
import { auth } from "../helpers/firebase";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_API_URL,
    timeout: 20000,
});

axiosInstance.interceptors.request.use(
    async config => {
        const userToken = localStorage.getItem("userToken");
        if (userToken) {
            config.headers["Authorization"] = userToken;
        }
        if (!config.headers["Content-Type"]) {
            config.headers["Content-Type"] = "application/json";
            config.headers["Accept"] = "application/json";
        }
        return config;
    },
    error => {
        Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => {
        // console.log("RESPONSE", response);
        return response;
    },
    async (error) => {
        if (error.response && error.response.status === 401) {
            const originalRequest = error.config;
            if (!originalRequest._retry)  {
                originalRequest._retry = true;
                const user = auth.currentUser;
                if (user) {
                    const userToken = await user.getIdToken();
                    localStorage.setItem("userToken", userToken);
                    axios.defaults.headers.common["Authorization"] = userToken;
                    return axiosInstance(originalRequest);
                }
            }
        }
        return Promise.reject(error);
    }
);

export const axiosGet = (url, body, callback, removeNonFieldErrors = true) => {
    trackPromise(
        axiosInstance.get(url, {params: body})
        .then(res => {
            if (res.data.success) {
                callback(res.data.content, 1);
            }
            else {
                const otherErrors = handleApiErrors(res.data.errors, removeNonFieldErrors);
                callback(otherErrors, 0);
            }
        })
        .catch(err => {
            toast.error(err.message);
            callback(err.message, -1);
        })
    );
}

export const axiosPost = (url, body, callback, removeNonFieldErrors = true, hasFiles = false) => {
    trackPromise(
        axiosInstance.post(url, body, hasFiles ? { headers: { "Content-Type": "multipart/form-data"} } : undefined)
        .then(res => {
            if (res.data.success) {
                callback(res.data.content, 1);
            }
            else {
                const otherErrors = handleApiErrors(res.data.errors, removeNonFieldErrors);
                callback(otherErrors, 0);
            }
        })
        .catch(err => {
            toast.error(err.message);
            callback(err.message, -1);
        })
    );
}

export const axiosPut = (url, body, callback, removeNonFieldErrors = true, hasFiles = false) => {
    trackPromise(
        axiosInstance.put(url, body, hasFiles ? { headers: { "Content-Type": "multipart/form-data"} } : undefined)
        .then(res => {
            if (res.data.success) {
                callback(res.data.content, 1);
            }
            else {
                const otherErrors = handleApiErrors(res.data.errors, removeNonFieldErrors);
                callback(otherErrors, 0);
            }
        })
        .catch(err => {
            toast.error(err.message);
            callback(err.message, -1);
        })
    );
}

export const axiosDelete = (url, callback, removeNonFieldErrors = true) => {
    trackPromise(
        axiosInstance.delete(url)
        .then(res => {
            if (res.data.success) {
                callback(res.data.content, 1);
            }
            else {
                const otherErrors = handleApiErrors(res.data.errors, removeNonFieldErrors);
                callback(otherErrors, 0);
            }
        })
        .catch(err => {
            toast.error(err.message);
            callback(err.message, -1);
        })
    );
}

const handleApiErrors = (errors, removeNonFieldErrors) => {
    errors.map((err) => {
        if (err.field_name === undefined || err.field_name === null) {
            toast.error(<ErrorMessageToast errorType={err.error_type} />);
        }
    });
    if (removeNonFieldErrors) {
        return errors.filter((err) => (err.field_name !== undefined && err.field_name !== null));
    }
    return errors;
}
