import React from "react";
import { connect } from "react-redux";
import { Redirect, Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import TextField from "@mui/material/TextField";
import Link from "@mui/material//Link";
import Alert from '@mui/material/Alert';
import Grid from "@mui/material//Grid";
import Paper from "@mui/material//Paper";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useTheme } from "@mui/material/styles";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import { AnonymousUserBox, UserAvatar } from "../../components/styled/user";
import { validateUserInput } from "../../helpers/user";
import { changeUserPassword, updateUserInfo } from "../../helpers/firebase";
import { updateUserStore } from "../../store/actions/user";
import SubmitButton from "../../components/input/button/SubmitButton";


const UserSettings = (props) => {

    const theme = useTheme();
    const { t } = useTranslation(["user"]);

    const { displayName, providers, updateUserStore } = props;

    const passwordProvider = providers.filter((entry) => entry === "password").length > 0;

    const [firstSubmitInfo, setFirstSubmitInfo] = React.useState(false);
    const [fieldValuesInfo, setFieldValuesInfo] = React.useState({name: displayName});
    const [fieldErrorsInfo, setFieldErrorsInfo] = React.useState({name: ""});

    const [firstSubmitPwd, setFirstSubmitPwd] = React.useState(false);
    const [fieldValuesPwd, setFieldValuesPwd] = React.useState({currentPassword: "", newPassword: "", passwordConfirm: ""});
    const [fieldErrorsPwd, setFieldErrorsPwd] = React.useState({currentPassword: "", newPassword: "", passwordConfirm: ""});

    const validateInputsInfo = () => {
        let errors = { ...fieldErrorsInfo };
        errors.name = validateUserInput("name", fieldValuesInfo.name, t);
        setFieldErrorsInfo({ ...errors });
        return Object.keys(errors).map(k => errors[k] === "").every(r => r);
    }

    const validateInputsPwd = () => {
        let errors = { ...fieldErrorsPwd };
        errors.currentPassword = validateUserInput("currentPassword", fieldValuesPwd.currentPassword, t);
        errors.newPassword = validateUserInput("newPassword", fieldValuesPwd.newPassword, t);
        errors.passwordConfirm = validateUserInput("passwordConfirm", fieldValuesPwd.passwordConfirm, t, fieldValuesPwd.password);
        setFieldErrorsPwd({ ...errors });
        return Object.keys(errors).map(k => errors[k] === "").every(r => r);
    }

    const handleChange = (e, setFieldValues, fieldValues, setFieldErrors, fieldErrors, firstSubmit) => {
        setFieldValues({
            ...fieldValues,
            [e.target.id]: e.target.value
        });
        if (firstSubmit) {
            setFieldErrors({
                ...fieldErrors,
                [e.target.id]: validateUserInput(e.target.id, e.target.value, t, fieldValues.password),
            })
        }
    }

    const handleSubmitInfo = (e) => {
        e.preventDefault();

        const submitUserInfo = async () => {
            const formValid = validateInputsInfo();
            if (formValid) {

                const res = await updateUserInfo(fieldValuesInfo.name);
                // TODO: ACTION TO UPDATE STORE
                if (res > 0) {
                    updateUserStore({ displayName: fieldValuesInfo.name }, () => {
                        toast.info(
                            t("User updated with success."),
                            {autoClose: 5000}
                        );
                    });
                }
                else if (res === 0) {
                    toast.error(t("Unexpected error occured when updating user information"), {autoClose: 5000});
                }
                else {
                    toast.error(
                        t("An unknown error occurred during registration"),
                        {autoClose: 4000}
                    );
                }
            }
            else {
                setFirstSubmitInfo(true);
                toast.error(t("Your information contains errors"), {autoClose: 5000});
            }
        }
        submitUserInfo();
    }

    const handleSubmitPwd = (e) => {
        e.preventDefault();

        const submitUserPwd = async () => {
            const formValid = validateInputsPwd();
            if (formValid) {

                const res = await changeUserPassword(fieldValuesPwd.currentPassword, fieldValuesPwd.newPassword);
                if (res > 0) {
                    toast.info(
                        t("Password updated with success."),
                        {autoClose: 5000}
                    );
                    setFieldValuesPwd({currentPassword: "", newPassword: "", passwordConfirm: ""});
                    setFieldErrorsPwd({currentPassword: "", newPassword: "", passwordConfirm: ""});
                }
                else if (res === 0) {
                    const errorPassword = t("You current password is not correct");
                    setFieldErrorsPwd({...fieldErrorsPwd, currentPassword: errorPassword});
                    toast.error(t("Your information contains errors"), {autoClose: 5000});
                }
                else {
                    toast.error(
                        t("An unknown error occurred. Please, try again later."),
                        {autoClose: 4000}
                    );
                }
            }
            else {
                setFirstSubmitPwd(true);
                toast.error(t("Your information contains errors"), {autoClose: 5000});
            }
        }
        submitUserPwd();
        
    }

    const RenderTextField = (name, label, autoComplete = "none", type = "text", autoFocus = false, formType = null, enabled = true) => {
        if (formType !== "info" && formType !== "pwd") {
            return null;
        }

        const setFieldErrors = formType === "info" ? setFieldErrorsInfo : setFieldErrorsPwd;
        const fieldErrors = formType === "info" ? fieldErrorsInfo : fieldErrorsPwd;
        const setFieldValues = formType === "info" ? setFieldValuesInfo : setFieldValuesPwd;
        const fieldValues = formType === "info" ? fieldValuesInfo : fieldValuesPwd;
        const firstSubmit = formType === "info" ? firstSubmitInfo : firstSubmitPwd;

        const errorText = fieldErrors[name];
        const hasError = errorText !== null && errorText !== "";
        return (
            <TextField variant="outlined" margin="normal" required fullWidth id={name} label={label}
                name={name} type={type} value={fieldValues[name]} error={hasError} helperText={errorText}
                autoComplete={autoComplete} autoFocus={autoFocus} disabled={!enabled}
                onChange={(e) => handleChange(e, setFieldValues, fieldValues, setFieldErrors, fieldErrors, firstSubmit)}
                onBlur={(e) => handleChange(e, setFieldValues, fieldValues, setFieldErrors, fieldErrors, firstSubmit)} />
        );
    }

    return (
        <Container component="main" maxWidth="xs">
            <AnonymousUserBox>
                <UserAvatar><LockOutlinedIcon /></UserAvatar>
                <Typography component="h1" variant="h5">{t("User Settings")}</Typography>
                {
                    !passwordProvider
                    ? (
                        <Alert severity="warning" sx={{ marginTop: theme.spacing(2) }}>
                            {t("Settings Disabled", {providers: providers.join(", ")})}
                        </Alert>
                    ) : null
                }
                <Paper sx={{ marginTop: theme.spacing(2), padding: theme.spacing(2) }} elevation={5}>
                    <Typography component="h2" variant="h6">{t("Personal Information")}</Typography>
                    {RenderTextField("name", t("Name"), "name", undefined, true, "info", passwordProvider)}
                    <SubmitButton fullWidth={true} disabled={!passwordProvider} marginTop={3} marginBottom={2} onClick={handleSubmitInfo}>
                        {t("Save Personal Information")}
                    </SubmitButton>
                </Paper>
                <Paper sx={{ marginTop: theme.spacing(2), padding: theme.spacing(2) }} elevation={5}>
                    <Typography component="h2" variant="h6">{t("Password")}</Typography>
                    {RenderTextField("currentPassword", t("Current Password"), undefined, "password", undefined, "pwd", passwordProvider)}
                    {RenderTextField("newPassword", t("New Password"), undefined, "password", undefined, "pwd", passwordProvider)}
                    {RenderTextField("passwordConfirm", t("Confirm New Password"), undefined, "password", undefined, "pwd", passwordProvider)}
                    <SubmitButton fullWidth={true} disabled={!passwordProvider} marginTop={3} marginBottom={2} onClick={handleSubmitPwd}>
                        {t("Change Password")}
                    </SubmitButton>
                </Paper>
            </AnonymousUserBox>
        </Container>
    );
}

const mapStateToProps = (state) => {
    return {
        displayName: state.user.displayName,
        providers: state.user.providers,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateUserStore: (user, callback) => dispatch(updateUserStore(user, callback)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSettings);
