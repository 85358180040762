import { axiosDelete, axiosGet, axiosPost, axiosPut } from "./instance";

export const apiWidgetList = (callback) => {
    axiosGet("widget/list", {}, callback);
}

export const apiWidgetCreate = ({
    name,
    type,
    primaryFilterId,
    secondaryFilterId,
    items,              // bar | pie
    dimension,          // bar | list | pie
    sortParcel,         // bar
    sortDirection,      // bar
    parcels,            // bar | totals
    labelTotal,         // list
    parcel,             // list | periodic | seasonal
    columns,            // list | totals
    frequency,          // periodic | timeseries
    hasProduct,         // periodic
    hasCategory,        // periodic
    hasItem,            // periodic
    hasEntity,          // periodic
    transactionType,    // pie
    numYears,           // seasonal
    lines,              // timeseries
    bars,               // timeseries
    recordType,         // transactions
}, callback) => {
    axiosPost("widget/create", {
        name: name,
        type: type,
        primary_filter_id: primaryFilterId,
        secondary_filter_id: secondaryFilterId,
        items: items,
        dimension: dimension,
        sort_parcel: sortParcel,
        sort_direction: sortDirection,
        parcels: parcels,
        label_total: labelTotal,
        parcel: parcel,
        columns: columns,
        frequency: frequency,
        has_product: hasProduct,
        has_category: hasCategory,
        has_item: hasItem,
        has_entity: hasEntity,
        transaction_type: transactionType,
        num_years: numYears,
        lines: lines,
        bars: bars,
        record_type: recordType,
    }, callback);
}

export const apiWidgetUpdate = ({
    id,
    name,
    type,
    primaryFilterId,
    secondaryFilterId,
    items,              // bar | pie
    dimension,          // bar | list | pie
    sortParcel,         // bar
    sortDirection,      // bar
    parcels,            // bar | totals
    labelTotal,         // list
    parcel,             // list | periodic | seasonal
    columns,            // list | totals
    frequency,          // periodic | timeseries
    hasProduct,         // periodic
    hasCategory,        // periodic
    hasItem,            // periodic
    hasEntity,          // periodic
    transactionType,    // pie
    numYears,           // seasonal
    lines,              // timeseries
    bars,               // timeseries
    recordType,         // transactions
}, callback) => {
    axiosPut(`widget/${id}`, {
        name: name,
        type: type,
        primary_filter_id: primaryFilterId,
        secondary_filter_id: secondaryFilterId,
        items: items,
        dimension: dimension,
        sort_parcel: sortParcel,
        sort_direction: sortDirection,
        parcels: parcels,
        label_total: labelTotal,
        parcel: parcel,
        columns: columns,
        frequency: frequency,
        has_product: hasProduct,
        has_category: hasCategory,
        has_item: hasItem,
        has_entity: hasEntity,
        transaction_type: transactionType,
        num_years: numYears,
        lines: lines,
        bars: bars,
        record_type: recordType,
    }, callback);
}

export const apiWidgetDelete = ({ id }, callback) => {
    axiosDelete(`widget/${id}`, callback);
}
