import * as React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";

const ActionButtonMenuProductFilter = (props) => {

    const { t } = useTranslation(["product"]);

    const { handleMenuClick, isMobile, title, records, selected = ["_ALL_"], icon } = props;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => { setAnchorEl(event.currentTarget) };
    const handleClose = () => { setAnchorEl(null) };

    const handleMenu = (menuId) => {
        handleClose();
        handleMenuClick(menuId);
    }

    const buttonCommonParams = { onClick: handleClick };
    const menuItemMinWidth = {minWidth: "200px"};

    return (
        <React.Fragment>
            {
                isMobile ? (
                    <IconButton size="small" {...buttonCommonParams}>{icon}</IconButton>
                ) : (
                    <Button startIcon={icon} {...buttonCommonParams}>{title}</Button>
                )
            }
            <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
                {
                    [{id: "_ALL_", label: t("All")}].concat(records).map(element => {
                        return (
                            <MenuItem
                                key={element.id}
                                sx={menuItemMinWidth}
                                onClick={() => handleMenu(element.id)}
                                selected={selected.includes(element.id)}>
                                {element.label}
                            </MenuItem>
                        )
                    })
                }
            </Menu>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        isMobile: state.appSettings.isMobile,
    }
}

export default connect(mapStateToProps)(ActionButtonMenuProductFilter);
