// Button component
import React from "react";
import { Switch as MuiSwitch, FormControlLabel, FormControl } from "@mui/material";

const Switch = (props) => {

    const { label, checked, onChange } = props;

    return <FormControl><FormControlLabel control={<MuiSwitch onChange={onChange} checked={checked} />} label={label} /></FormControl>
}

export default Switch;
