import { axiosDelete, axiosGet, axiosPost, axiosPut } from "./service";

export const getGroupsService = ({ search }, callback) => {
    axiosGet("group/list", { search: search }, callback);
}

export const createGroupService = ({ name, description }, callback) => {
    axiosPost("group/create", { name, description }, callback);
}

export const getGroupService = ({ id }, callback) => {
    axiosGet("group/" + id, {}, callback);
}

export const updateGroupService = ({ id, name, description }, callback) => {
    axiosPut("group/" + id, { name, description }, callback);
}

export const deleteGroupService = ({ id }, callback) => {
    axiosDelete("group/" + id, callback);
}

export const moveGroupUpService = ({ id }, callback) => {
    axiosPut("group/" + id + "/up", {}, callback);
}

export const moveGroupDownService = ({ id }, callback) => {
    axiosPut("group/" + id + "/down", {}, callback);
}
