import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { trackPromise } from "react-promise-tracker";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJ_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MSG_SEND_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEAS_ID
};

const app = firebase.initializeApp(firebaseConfig);
const auth = app.auth();
const secondaryApp = firebase.initializeApp(firebaseConfig);
const googleProvider = new firebase.auth.GoogleAuthProvider();

const signInWithGoogle = async () => {
    try {
        const res = await trackPromise(auth.signInWithPopup(googleProvider));
        return res.user;
    }
    catch (err) {
        console.log("[Firebase Error - Sign In With Google]", err);
        return null;
    }
};

const changeUserPassword = async (currentPassword, newPassword) => {
    try {
        const res = await trackPromise(new Promise(async (resolve, reject) => {
            const user = auth.currentUser;
            if (user) {
                const cred = firebase.auth.EmailAuthProvider.credential(user.email, currentPassword);
                const reauth = await user.reauthenticateWithCredential(cred);
                await user.updatePassword(newPassword);
                resolve(true);
            }
            else {
                resolve(false);
            }
        }));
        return res ? 1 : 0;
    }
    catch (err) {
        console.log("[Firebase Error - Change User Password]", err);
        return -1;
    }
};

const signInWithEmailAndPassword = async (email, password) => {
    try {
        const res = await trackPromise(auth.signInWithEmailAndPassword(email, password));
        return res.user;
    }
    catch (err) {
        console.log("[Firebase Error - Sign In With Email and Password]", err);
        return null;
    }
};

const registerWithEmailAndPassword = async (name, email, password) => {
    try {
        const res = await trackPromise(new Promise(async (resolve, reject) => {
            const methods = await secondaryApp.auth().fetchSignInMethodsForEmail(email);
            if (methods.length > 0) {
                resolve(false);
            }
            else {
                const register = await secondaryApp.auth().createUserWithEmailAndPassword(email, password);
                await register.user.sendEmailVerification();
                await register.user.updateProfile({displayName: name});
                await secondaryApp.auth().signOut();
                resolve(true);
            }
        }));
        return res ? 1 : 0;
    }
    catch (err) {
        console.log("[Firebase Error - Register]", err);
        return -1;
    }
};

const updateUserInfo = async (name) => {
    try {
        const res = await trackPromise(new Promise(async (resolve, reject) => {
            const user = auth.currentUser;
            if (user) {
                user.updateProfile({displayName: name});
                resolve(true);
            }
            else {
                resolve(false);
            }
        }));
        return res ? 1 : 0;
    }
    catch (err) {
        console.log("[Firebase Error - Update Profile]", err);
        return -1;
    }
}

const sendPasswordResetEmail = async (email) => {
    try {
        await trackPromise(auth.sendPasswordResetEmail(email));
        return 1;
    }
    catch (err) {
        console.log("[Firebase Error - Send Password Reset]", err);
        return -1;
    }
};

const logout = async () => {
    await trackPromise(auth.signOut());
};

export {
    auth,
    signInWithGoogle,
    signInWithEmailAndPassword,
    registerWithEmailAndPassword,
    sendPasswordResetEmail,
    logout,
    changeUserPassword,
    updateUserInfo,
};
