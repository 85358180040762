import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import SyncIcon from '@mui/icons-material/Sync';
import LinkIcon from '@mui/icons-material/Link';

import TextDialog from "../dialog/TextDialog";
import { Avatar, CardHeader, Tooltip } from "@mui/material";
import ParcelCardItem from "./ParcelCardItem";

const ProductCard = (props) => {

    const { t } = useTranslation(["product"]);
    const theme = useTheme();
    const { canUpdateAccount } = props;

    const { productId, name, description, identifier, productType, productGroup, balance, interests, updates } = props;
    const { to, handleEdit, handleDelete, handleExternal } = props;

    const [dialogState, setDialogState] = React.useState({open: false, text: null});
    const descriptionDisabled = !canUpdateAccount || description === null || description === "" || description === undefined;

    const ActionButton = () => {

        if (productType === "FIN") {
            return (
                <IconButton disabled={!canUpdateAccount} onClick={(e) => {handleExternal(productId)}}>
                    <TrendingUpIcon />
                </IconButton>
            )
        }

        if (productType === "CRY") {
            return (
                <IconButton disabled={!canUpdateAccount} onClick={(e) => {handleExternal(productId)}}>
                    <CurrencyExchangeIcon />
                </IconButton>
            )
        }

        return <IconButton disabled={true}><DoNotDisturbIcon /></IconButton>
    }

    return (
        <React.Fragment>
            <Card sx={{display: "flex", flexDirection: "column"}}>
                <CardHeader
                    avatar={
                        <Tooltip title={t(productType)}>
                            <Avatar sx={{ bgcolor: "red", fontSize: "80%" }} aria-label="recipe">
                                {productType}
                            </Avatar>
                        </Tooltip>
                    }
                    title={name}
                    subheader={t(productGroup)}
                    action={<ActionButton />}
                    />
                <CardContent sx={{ textAlign: "center", display: "flex", flexDirection: "column", flex: 1}}>
                    <Grid container spacing={theme.spacing(1)}>
                        <Grid item xs={12}><ParcelCardItem label={t("Balance")} amount={balance} /></Grid>
                        <Grid item xs={6}><ParcelCardItem label={t("Interests")} amount={interests} /></Grid>
                        <Grid item xs={6}><ParcelCardItem label={t("Updates and Valuations")} amount={updates} /></Grid>
                    </Grid>
                </CardContent>
                <CardActions disableSpacing={true}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <IconButton disabled={!canUpdateAccount} onClick={handleDelete}><DeleteIcon /></IconButton>
                            {identifier ? <IconButton onClick={() => setDialogState({open: true, text: identifier})} sx={{marginLeft: theme.spacing(1)}}><FingerprintIcon /></IconButton> : null}
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <IconButton disabled={!canUpdateAccount} onClick={handleEdit}><EditIcon /></IconButton>
                            <IconButton disabled={descriptionDisabled} onClick={() => setDialogState({open: true, text: description})} sx={{marginLeft: theme.spacing(1)}}><TextSnippetIcon /></IconButton>
                            <IconButton disabled={!canUpdateAccount} component={Link} to={to} sx={{marginLeft: theme.spacing(1)}}><InfoRoundedIcon /></IconButton>
                        </Box>
                    </Grid>
                </CardActions>
            </Card>
            <TextDialog open={dialogState.open} text={dialogState.text} onClose={() => setDialogState({open: false, text: null})} />
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        isMobile: state.appSettings.isMobile,
        canUpdateAccount: state.user.canUpdateAccount,
    }
}

export default connect(mapStateToProps)(ProductCard);
