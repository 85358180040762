import React from "react";
import { Link } from "react-router-dom";

import CollectionAddButton from "../input/button/CollectionAddButton";
import { GridToolbarContainer, GridToolbarColumnsButton } from '@mui/x-data-grid';

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";

export const getCustomTableToolbar = (handleAdd = () => {}, addText = "Add", to = null) => {
    return (
        <GridToolbarContainer>
            <Grid container>
                <Grid item>
                    <GridToolbarColumnsButton />
                </Grid>
                {
                    handleAdd === undefined && to === undefined ? null : (
                        <Grid item xs>
                            <CollectionAddButton handleAdd={handleAdd} to={to} addText={addText} />
                        </Grid>
                    ) 
                }
            </Grid>
        </GridToolbarContainer>
    );
}
