import { DIM_CREATE, DIM_UPDATE, DIM_DELETE } from "./types";
import { apiCategoryCreate, apiCategoryUpdate, apiCategoryDelete } from "../../api/category";


export const createCategory = ({ name, description }, callback) => {
    return (dispatch, getState) => {
        apiCategoryCreate({ name, description }, (data, successFlag) => {
            if (successFlag > 0) {
                dispatch({ type: DIM_CREATE, payload: { data: data.category, attr: "categories" } });
            }
            callback(data, successFlag);
        });
    }
};


export const updateCategory = ({ id, name, description }, callback) => {
    return (dispatch, getState) => {
        apiCategoryUpdate({ id, name, description }, (data, successFlag) => {
            if (successFlag > 0) {
                dispatch({ type: DIM_UPDATE, payload: { data: data.category, attr: "categories" } });
            }
            callback(data, successFlag);
        });
    }
};


export const deleteCategory = ({ id }, callback) => {
    return (dispatch, getState) => {
        apiCategoryDelete({ id }, (data, successFlag) => {
            if (successFlag > 0) {
                dispatch({ type: DIM_DELETE, payload: { data: id, attr: "categories" } });
            }
            callback(data, successFlag);
        });
    }
};
