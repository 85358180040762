import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useTheme } from "@mui/material/styles";

import Box from "@mui/material/Box";
import TablePagination from "@mui/material/TablePagination";

import { handleFieldErrors } from "../../helpers/error";
import SearchInput from "../input/text/SearchInput";
import PageTitle from "../typography/PageTitle";
import Grid from "@mui/material/Grid";
import DimensionCard from "../card/DimensionCard";
import DimensionFormDialog from "../dialog/DimensionFormDialog";
import DimensionCollectionActions from "../nav/DimensionCollectionActions";
import { downloadDimensions } from "../../helpers/download";


const DimensionCollection = (props) => {

    const { isMobile, canUpdateAccount } = props;
    const { createMethod, updateMethod, deleteMethod, listMethod } = props;
    const { dimensionTitle, listResultAttribute, urlMainPath, spec } = props;

    const initState = {
        open: false,
        type: null,
        id: null,
        spec: spec,
    };

    const pageSizeOptions = [20, 50, 100];
    const theme = useTheme();
    const { t } = useTranslation(["dim", "amount_type"]);

    const [rows, setRows] = React.useState([]);
    const [rowCount, setRowCount] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(pageSizeOptions[0]);
    const [pageNumber, setPageNumber] = React.useState(0);
    const [sortModel, setSortModel] = React.useState({ field: "name", sort: "asc" });
    const [searchInput, setSearchInput] = React.useState("");
    const [searchFetch, setSearchFetch] = React.useState("");
    const [dimensionState, setDimensionState] = React.useState(initState);

    const fetchData = () => {
        listMethod({
            pageNumber: pageNumber,
            pageSize: pageSize,
            sortDir: sortModel.sort,
            sortCol: sortModel.field,
            search: searchFetch,
        }, (data, successFlag) => {
            if (successFlag > 0) {
                setRows(data[listResultAttribute]);
                setRowCount(data.totalRows);
            }
        });
    };

    const pageSizeHandlerMobile = (event) => {
        setPageSize(parseInt(event.target.value, 20));
        setPageNumber(0);
    };

    const pageHandlerMobile = (e, newPageNumber) => {
        setPageNumber(newPageNumber);
    };

    const sortHandler = (sortCol) => {
        const dir = sortCol === sortModel.field ? (
            sortModel.sort === "asc" ? "desc" : "asc"
        ) : "asc";
        setSortModel({field: sortCol, sort: dir});
    };

    const handleSubmitSearch = (e) => {
        setSearchFetch(searchInput);
    };

    const handleSearchChange = (e) => {
        setSearchInput(e.target.value);
    };

    const handleResetSearch = () => {
        setSearchFetch("");
        setSearchInput("");
    };

    const handleDelete = (row) => {
        setDimensionState({
            open: true,
            type: "delete",
            id: row.id,
            spec: spec.map(element => {
                return {...element, value: row[element.id]}
            }),
        });
    };

    const handleEdit = (row) => {
        setDimensionState({
            open: true,
            type: "edit",
            id: row.id,
            spec: spec.map(element => {
                return {...element, value: row[element.id]}
            }),
        });
    };

    const handleAdd = () => {
        setDimensionState({
            open: true,
            type: "create",
            id: null,
            spec: spec.map(element => {
                return {...element, value: null}
            }),
        });
    }

    const handleModalResponse = (action, state) => {

        let closeModal = true;

        if (action === "submit") {

            if (dimensionState.type === "create") {
                const argCreate = {name: state.name, description: state.description};
                if (state.identifier) {
                    argCreate.identifier = state.identifier;
                }
                createMethod(argCreate, (data, successFlag) => {
                    if (successFlag > 0) {
                        toast.success(dimensionTitle + " " + t("created successfuly"));
                        fetchData();
                    }
                    else {
                        closeModal = false;
                        handleFieldErrors(successFlag, data, "dim");
                    }
                });
            }

            if (dimensionState.type === "edit") {
                const argUpdate = {id: dimensionState.id, name: state.name, description: state.description};
                if (state.identifier) {
                    argUpdate.identifier = state.identifier;
                }
                updateMethod(argUpdate, (data, successFlag) => {
                    if (successFlag > 0) {
                        toast.success(dimensionTitle + " " + t("updated successfuly"));
                        fetchData();
                    }
                    else {
                        closeModal = false;
                        handleFieldErrors(successFlag, data, "dim");
                    }
                });
            }

            if (dimensionState.type === "delete") {
                deleteMethod({ id: dimensionState.id }, (data, successFlag) => {
                    if (successFlag > 0) {
                        toast.success(dimensionTitle + " " + t("deleted successfuly"));
                        fetchData();
                    }
                    else {
                        closeModal = false;
                        handleFieldErrors(successFlag, data, "dim");
                    }
                });
            }
        }

        if (closeModal) {
            setDimensionState(initState);
        }
    }

    const sortItems = spec.map(element => {
        return {
            selected: sortModel.field === element.id,
            ascending: sortModel.sort === "asc",
            column: element.id,
            label: element.label,
        }
    }).concat([
        {
            selected: sortModel.field === "bal",
            ascending: sortModel.sort === "asc",
            column: "bal",
            label: t("Balance"),
        }
    ]);

    const handleDownload = () => {
        listMethod({
            sortDir: sortModel.sort,
            sortCol: sortModel.field,
            search: searchFetch,
        }, (data, successFlag) => {
            if (successFlag > 0) {
                downloadDimensions(data[listResultAttribute], listResultAttribute);
            }
        });
    }

    useEffect(() => {
        fetchData();
    }, [pageNumber, pageSize, sortModel, searchFetch]);

    return (
        <Box sx={{margin: theme.spacing(1)}}>
            <PageTitle text={dimensionTitle} />
            <SearchInput
                value={searchInput}
                submit={handleSubmitSearch}
                reset={handleResetSearch}
                onChange={handleSearchChange}
                sx={{marginTop: theme.spacing(1)}} />
            <Box sx={{marginTop: theme.spacing(1)}}>
                <DimensionCollectionActions
                    onSortChange={sortHandler}
                    sortMenuItems={sortItems}
                    onDownloadClick={handleDownload}
                    handleAdd={handleAdd} />
            </Box>
            <Grid container sx={{marginTop: theme.spacing(1)}} spacing={theme.spacing(2)}>
                {
                    rows.map(row => {
                        return (
                            <Grid item xs={12} sm={4} md={3} key={row.id}>
                                <DimensionCard
                                    name={row.name}
                                    description={row.description}
                                    identifier={row.identifier}
                                    balance={row.bal}
                                    to={`/${urlMainPath}/view/${row.id}`}
                                    handleEdit={() => handleEdit(row)}
                                    handleDelete={() => handleDelete(row)} />
                            </Grid>
                        )
                    })
                }
            </Grid>
            {
                rowCount > 0
                ? (
                    <TablePagination component="div" count={rowCount} page={pageNumber}
                        onPageChange={pageHandlerMobile} rowsPerPage={pageSize}
                        onRowsPerPageChange={pageSizeHandlerMobile} rowsPerPageOptions={pageSizeOptions} />
                ) : null
            }
            <DimensionFormDialog open={dimensionState.open} title={dimensionTitle}
                type={dimensionState.type} actionMethod={handleModalResponse}
                spec={dimensionState.spec} />
        </Box>
    );
}

const mapStateToProps = (state) => {
    return {
        isMobile: state.appSettings.isMobile,
        canUpdateAccount: state.user.canUpdateAccount,
    }
}

export default connect(mapStateToProps)(DimensionCollection);
