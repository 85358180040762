import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { apiCategoryGet } from "../../api/category";
import { updateCategory } from "../../store/actions/category";
import DimensionPage from "../../layout/DimensionPage";

const CategoryView = (props) => {
    const { t } = useTranslation(["dimension"]);
    const { updateMethod } = props;
    return (
        <DimensionPage dimension="category" title={t("Category")} instanceId={props.match.params.id}
            getMethod={apiCategoryGet} dataKey="categories" updateMethod={updateMethod}
            spec={
                [
                    {id: "name", required: true, label: t("Name"), type: "text", value: null},
                    {id: "description", required: false, label: t("Description"), type: "area", value: null},
                ]
            }
        />
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateMethod: ({ id, name, description }, callback) => dispatch(updateCategory({ id, name, description }, callback)),
    }
}

export default connect(null, mapDispatchToProps)(CategoryView);
