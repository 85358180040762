import { axiosDelete, axiosGet, axiosPost, axiosPut } from "./instance";

export const apiItemList = (
    {
        pageNumber,
        pageSize,
        sortCol,
        sortDir,
        search
    },
    callback
) => {
    axiosGet(
        "item/list",
        {
            page_number: pageNumber,
            page_size: pageSize,
            sort_col: sortCol,
            sort_dir: sortDir,
            search: search
        },
        callback
    );
}

export const apiItemCreate = ({ name, description }, callback) => {
    axiosPost("item/create", { name, description }, callback);
}

export const apiItemGet = ({ id }, callback) => {
    axiosGet("item/" + id, {}, callback);
}

export const apiItemUpdate = ({ id, name, description }, callback) => {
    axiosPut("item/" + id, { name, description }, callback);
}

export const apiItemDelete = ({ id }, callback) => {
    axiosDelete("item/" + id, callback);
}
