import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { toast } from "react-toastify";

import Grid from "@mui/material/Grid";

import Select from "../../components/input/select/Select";
import GenericDialog from "./GenericDialog";
import FormNumericInput from "../input/text/FormNumericInput";
import { SatelliteTwoTone, Sort } from "@mui/icons-material";
import FormTextInput from "../input/text/FormTextInput";
import Switch from "../input/checkbox/Switch";
import SelectableLabelInput from "../input/composed/SelectableLabelInput";
import { Box } from "@mui/material";

const WidgetFormDialog = (props) => {

    const theme = useTheme();
    const { t } = useTranslation(["search", "product"]);

    // widget types
    const types = [
        {id: "BAR", label: t("Bar Chart"), hasSecondaryFilter: true},
        {id: "LIST", label: t("List"), hasSecondaryFilter: false},
        {id: "PERIODIC", label: t("Summary Table"), hasSecondaryFilter: false},
        {id: "PIE", label: t("Pie Chart"), hasSecondaryFilter: false},
        {id: "SEASONAL", label: t("Seasonal"), hasSecondaryFilter: false},
        {id: "TIMESERIES", label: t("Timeseries"), hasSecondaryFilter: true},
        {id: "TOTALS", label: t("Amount Cards"), hasSecondaryFilter: true},
        {id: "TRANSACTIONS", label: t("Transactions"), hasSecondaryFilter: false},
    ];
    types.sort((a, b) => {
        if (a.label < b.label) { return -1; }
        if (a.label > b.label) { return 1; }
        return 0;
    });

    // dimensions
    const dimensions = [
        {id: "product", label: t("Product")},
        {id: "category", label: t("Category")},
        {id: "item", label: t("Item")},
        {id: "entity", label: t("Entity")},
        {id: "tag", label: t("Tag")},
    ];

    // amount parcels
    const amountParcels = [
        {id: "cre", label: "Credit"},
        {id: "deb", label: "Debit"},
        {id: "val", label: "Result"},
        {id: "bal", label: "Balance"},
        {id: "reg", label: "Regular"},
        {id: "cap", label: "Capital"},
        {id: "int", label: "Interests"},
        {id: "fee", label: "Fees and Commissions"},
        {id: "upd", label: "Updates"},
    ];

    // frequencies
    const frequencies = [
        {id: "ymd", label: t("Daily")},
        {id: "ym", label: t("Monthly")},
        {id: "y", label: t("Annual")},
    ];

    const relabelParcels = (idPrefix, labelPrefix) => {
        return amountParcels.map(element => {
            const newId = idPrefix ? element.id.charAt(0).toUpperCase() + element.id.slice(1) : element.id;
            return {
                id: `${idPrefix}${newId}`,
                label: t(`${labelPrefix}${element.label}`),
                base: t(element.label),
            }
        })
    }

    const allParcels = relabelParcels("", "Period ").concat(relabelParcels("cumsum", "Cumulative "))

    const [state, setState] = React.useState({
        generic: {
            type: null,
            primaryFilterId: null,
            secondaryFilterId: null,
        },
        bar: {},
        list: {},
        periodic: {},
        pie: {},
        seasonal: {},
        timeseries: {},
        totals: {},
        transactions: {},
    });
    const { open, editState, actionMethod } = props;
    const { filters } = props;

    const secondaryFilterAvailable = () => {
        return state.generic.type ? types.filter(element => element.id === state.generic.type)[0].hasSecondaryFilter : false;
    }

    const handleChange = (group, field, e) => {
        setState({
            ...state,
            [group]: {
                ...state[group],
                [field]: e.target.value,
            },
        });
    }

    const handleSwitch = (group, field, e) => {
        setState({
            ...state,
            [group]: {
                ...state[group],
                [field]: e.target.checked,
            },
        });
    }

    const handleAction = (action) => {
        if (action === "submit") {

            let validForm = true;

            if (!state.generic.type) {
                validForm = false;
                toast.error(t("Please, choose a widget type"));
            }

            if (!state.generic.primaryFilterId) {
                validForm = false;
                toast.error(t("Please, choose a primary filter to apply to the widget"));
            }

            if (state.generic.type === "BAR") {
                if (!state.bar.items) {
                    validForm = false;
                    toast.error(t("Please, choose a valid number of items"));
                }
                if (!state.bar.dimension) {
                    validForm = false;
                    toast.error(t("Please choose the dimension to show on the chart"));
                }
                if (state.bar.parcels.lenght === 0) {
                    validForm = false;
                    toast.error("Please, add at least one parcel to show on the cards");
                }
                if (!state.bar.sortParcel) {
                    validForm = false;
                    toast.error("Please, set the parcel to use for sorting the bars");
                }
                if (!state.bar.sortDirection) {
                    validForm = false;
                    toast.error("Please, set the sort direction");
                }
            }

            if (state.generic.type === "LIST") {
                if (!state.list.parcel) {
                    validForm = false;
                    toast.error("Please choose the amount type to show on the list");
                }
                if (!state.list.dimension) {
                    validForm = false;
                    toast.error(t("Please choose the dimension to show on the list"));
                }
            }

            if (state.generic.type === "PERIODIC") {
                if (!state.periodic.frequency) {
                    validForm = false;
                    toast.error("Please select a frequency");
                }
                if (!state.periodic.parcel) {
                    validForm = false;
                    toast.error("Please choose the amount type to show on the list");
                }
            }

            if (state.generic.type === "PIE") {
                if (!state.pie.items) {
                    validForm = false;
                    toast.error(t("Please, choose a valid number of items"));
                }
                if (!state.pie.transactionType) {
                    validForm = false;
                    toast.error(t("Please choose a transaction type"));
                }
                if (!state.pie.dimension) {
                    validForm = false;
                    toast.error(t("Please choose the dimension to show on the chart"));
                }
            }

            if (state.generic.type === "SEASONAL") {
                if (!state.seasonal.parcel) {
                    validForm = false;
                    toast.error("Please choose the amount type to show on the chart");
                }
                if (!state.seasonal.numYears || state.seasonal.numYears < 2 || state.seasonal.numYears > 10) {
                    validForm = false;
                    toast.error("Please insert a valid number of years");
                }
            }
            
            if (state.generic.type === "TIMESERIES") {
                if (!state.timeseries.frequency) {
                    validForm = false;
                    toast.error("Please select a frequency");
                }

                if (state.timeseries.lines.lenght === 0 && state.timeseries.bars.lenght === 0) {
                    validForm = false;
                    toast.error("Please, select at lease one parcel for lines or bars");
                }
            }

            if (state.generic.type === "TOTALS") {
                if (state.totals.parcels.lenght === 0) {
                    validForm = false;
                    toast.error("Please, add at least one parcel to show on the cards");
                }
            }

            if (state.generic.type === "TRANSACTIONS") {
                if (!state.transactions.recordType) {
                    validForm = false;
                    toast.error(t("Please, choose the transaction widget type"));
                }
            }

            if (validForm) {
                actionMethod(action, state);
            }
        }
        else {
            actionMethod(action, state);
        }
    }

    const handleGenericSelect = (field, e, newValue) => {
        const val = newValue ? newValue.id : null;
        setState({
            ...state,
            generic: {
                ...state.generic,
                [field]: val,
            },
        });
    }

    const handleSingleSelect = (group, field, e, newValue) => {
        const val = newValue ? newValue.id : null;
        setState({
            ...state,
            [group]: {
                ...state[group],
                [field]: val,
            },
        });
    }

    const handleSelectableLabel = (group, field, idx, action, selectedOption, labelInput) => {
        let newList = state[group][field].slice();

        if (action === "save") {
            if (selectedOption && labelInput) {
                const newElem = (selectedOption ?? "") + "|" + (labelInput ?? "");
                if (idx >= 0) {
                    newList = newList.map((element, i) => idx === i ? newElem : element);
                }
                else {
                    newList.push(newElem);
                }
            }
            else {
                toast.error("Both fields are required to add a new parcel");
            }
        }

        if (action === "delete") {
            newList = newList.filter((element, i) => i !== idx);
        }

        setState({
            ...state,
            [group]: {
                ...state[group],
                [field]: newList,
            }
        });
    }

    useEffect(() => {
        setState(editState);
    }, [editState]);

    const secondaryFilterDisabled = !secondaryFilterAvailable();

    return (
        <GenericDialog
            open={open}
            title={t("Widget")}
            actionMethod={handleAction}
            cancelLabel={t("Cancel")}
            submitLabel={t("Apply")}
            fullScreen={true}
        >
            <Grid container sx={{marginTop: theme.spacing(1)}} spacing={2}>

                <Grid item xs={12}>
                    <Select options={types.map((entry) => ({id: entry.id, label: entry.label}))}
                        id="type" selected={state.generic.type} label={t("Widget Type")}
                        onChange={(e, v) => handleGenericSelect("type", e, v)} />
                </Grid>

                <Grid item xs={6}>
                    <Select options={filters.map((entry) => ({id: entry.id, label: entry.name}))}
                        id="primaryFilterId" selected={state.generic.primaryFilterId} label={t("Primary Filter")}
                        onChange={(e, v) => handleGenericSelect("primaryFilterId", e, v)} />
                </Grid>

                <Grid item xs={6}>
                    <Select options={filters.map((entry) => ({id: entry.id, label: entry.name}))}
                        id="secondaryFilterId" selected={state.generic.secondaryFilterId} label={t("Secondary Filter")}
                        onChange={(e, v) => handleGenericSelect("secondaryFilterId", e, v)}
                        disabled={secondaryFilterDisabled} />
                </Grid>

                {
                    state.generic.type === "BAR" ? (
                        <React.Fragment>
                            <Grid item xs={12} sm={3}>
                                <Select options={dimensions} id="barDimension" selected={state.bar.dimension}
                                    label={t("Dimension")} onChange={(e, v) => handleSingleSelect("bar", "dimension", e, v)} />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <FormNumericInput id="barItems" label={t("# Items")} value={state.bar.items}
                                    onChange={(e) => handleChange("bar", "items", e)} minValue={3} />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Select options={relabelParcels("", "")}
                                    id="barSortParcel" selected={state.bar.sortParcel} label={t("Sort Criteria")}
                                    onChange={(e, v) => handleSingleSelect("bar", "sortParcel", e, v)} />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Select options={[{id: "ASC", label: t("Ascending")}, {id: "DESC", label: t("Descending")}]}
                                    id="barSortDirection" selected={state.bar.sortDirection} label={t("Sort Direction")}
                                    onChange={(e, v) => handleSingleSelect("bar", "sortDirection", e, v)} />
                            </Grid>
                            <Grid item xs={12}>
                                {
                                    state.bar.parcels.map((element, idx) => (
                                        <Box key={`barParcelsBox${idx}`} sx={{marginBottom: theme.spacing(2)}}>
                                            <SelectableLabelInput key={`barParcels${idx}`}
                                                state={element}
                                                id={`barParcels${idx}`}
                                                options={relabelParcels("", "")}
                                                selectLabel={t("Parcel")}
                                                handleAction={(a, s, l) => {
                                                    handleSelectableLabel("bar", "parcels", idx, a, s, l)
                                                }} />
                                        </Box>
                                    ))
                                }
                                <SelectableLabelInput id="barParcelsNew" options={relabelParcels("", "")} selectLabel={t("Parcel")}
                                    handleAction={(a, s, l) => {
                                        handleSelectableLabel("bar", "parcels", -1, a, s, l)
                                    }} />
                            </Grid>
                        </React.Fragment>
                    ) : null
                }

                {
                    state.generic.type === "LIST" ? (
                        <React.Fragment>
                            <Grid item xs={6} sm={3}>
                                <Select options={amountParcels.map((entry) => ({id: entry.id, label: t(entry.label)}))}
                                    id="listParcel" selected={state.list.parcel} label={t("Amount Type")}
                                    onChange={(e, v) => handleSingleSelect("list", "parcel", e, v)} />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Select options={dimensions} id="listDimension" selected={state.list.dimension}
                                    label={t("Dimension")} onChange={(e, v) => handleSingleSelect("list", "dimension", e, v)} />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <FormTextInput id="listLabelTotal" label={t("Label")} value={state.list.label}
                                    onChange={(e) => handleChange("list", "labelTotal", e)} />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <FormNumericInput id="listColumns" label={t("# Columns")} value={state.list.columns}
                                    onChange={(e) => handleChange("list", "columns", e)} minValue={1} maxValue={4} />
                            </Grid>
                        </React.Fragment>
                    ) : null
                }

                {
                    state.generic.type === "PERIODIC" ? (
                        <React.Fragment>
                            <Grid item xs={6} sm={4}>
                                <Select options={frequencies} id="periodicFrequency" selected={state.periodic.frequency}
                                    label={t("Frequency")} onChange={(e, v) => handleSingleSelect("periodic", "frequency", e, v)} />
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <Select options={amountParcels.map((entry) => ({id: entry.id, label: t(entry.label)}))}
                                    id="periodicParcel" selected={state.periodic.parcel} label={t("Amount Type")}
                                    onChange={(e, v) => handleSingleSelect("periodic", "parcel", e, v)} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Box display="flex" alignItems="center" gap={4} justifyContent="center" height="100%">
                                    <Switch onChange={(e) => handleSwitch("periodic", "hasProduct", e)} label={t("Product")} checked={state.periodic.hasProduct} />
                                    <Switch onChange={(e) => handleSwitch("periodic", "hasCategory", e)} label={t("Category")} checked={state.periodic.hasCategory} />
                                    <Switch onChange={(e) => handleSwitch("periodic", "hasItem", e)} label={t("Item")} checked={state.periodic.hasItem} />
                                    <Switch onChange={(e) => handleSwitch("periodic", "hasEntity", e)} label={t("Entity")} checked={state.periodic.hasEntity} />
                                </Box>
                            </Grid>
                        </React.Fragment>
                    ) : null
                }

                {
                    state.generic.type === "PIE" ? (
                        <React.Fragment>
                            <Grid item xs={6} sm={3}>
                                <FormNumericInput id="pieItems" label={t("# Items")} value={state.pie.items}
                                    onChange={(e) => handleChange("pie", "items", e)} minValue={3} />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Select options={[{id: "deb", label: t("Debit")}, {id: "cre", label: t("Credit")}]}
                                    id="pieTransactionType" selected={state.pie.transactionType} label={t("Transaction Type")}
                                    onChange={(e, v) => handleSingleSelect("pie", "transactionType", e, v)} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Select options={dimensions} id="pieDimension" selected={state.pie.dimension}
                                    label={t("Dimension")} onChange={(e, v) => handleSingleSelect("pie", "dimension", e, v)} />
                            </Grid>
                        </React.Fragment>
                    ) : null
                }

                {
                    state.generic.type === "SEASONAL" ? (
                        <React.Fragment>
                            <Grid item xs={6}>
                                <Select options={allParcels} id="seasonalParcel" selected={state.seasonal.parcel}
                                    label={t("Amount Type")} onChange={(e, v) => handleSingleSelect("seasonal", "parcel", e, v)} />
                            </Grid>
                            <Grid item xs={6}>
                                <FormNumericInput id="seasonalNumYears" label={t("# Years")} value={state.seasonal.numYears}
                                    onChange={(e) => handleChange("seasonal", "numYears", e)} minValue={2} maxValue={10} />
                            </Grid>
                        </React.Fragment>
                    ) : null
                }

                {
                    state.generic.type === "TOTALS" ? (
                        <React.Fragment>
                            <Grid item xs={12}>
                                {
                                    state.totals.parcels.map((element, idx) => (
                                        <Box key={`totalsParcelsBox${idx}`} sx={{marginBottom: theme.spacing(2)}}>
                                            <SelectableLabelInput key={`totalsParcels${idx}`}
                                                state={element}
                                                id={`totalsParcels${idx}`}
                                                options={allParcels} selectLabel={t("Parcel")}
                                                handleAction={(a, s, l) => {
                                                    handleSelectableLabel("totals", "parcels", idx, a, s, l)
                                                }} />
                                        </Box>
                                    ))
                                }
                                <SelectableLabelInput id="totalsParcelsNew" options={allParcels} selectLabel={t("Parcel")}
                                    handleAction={(a, s, l) => {
                                        handleSelectableLabel("totals", "parcels", -1, a, s, l)
                                    }} />
                            </Grid>
                            <Grid item xs={12}>
                                <FormNumericInput id="totalsColumns" label={t("# Columns")} value={state.totals.columns}
                                    onChange={(e) => handleChange("totals", "columns", e)} minValue={1} maxValue={4} />
                            </Grid>
                        </React.Fragment>
                    ) : null
                }

                {
                    state.generic.type === "TIMESERIES" ? (
                        <React.Fragment>
                            <Grid item xs={12}>
                                <Select options={frequencies} id="timeseriesFrequency" selected={state.timeseries.frequency}
                                    label={t("Frequency")} onChange={(e, v) => handleSingleSelect("timeseries", "frequency", e, v)} />
                            </Grid>
                            <Grid item xs={12}>
                                {
                                    state.timeseries.lines.map((element, idx) => (
                                        <Box key={`timeseriesLineBox${idx}`} sx={{marginBottom: theme.spacing(2)}}>
                                            <SelectableLabelInput
                                                state={element}
                                                id={`timeseriesLine${idx}`}
                                                options={allParcels} selectLabel={t("Line Parcel")}
                                                handleAction={(a, s, l) => {
                                                    handleSelectableLabel("timeseries", "lines", idx, a, s, l)
                                                }} />
                                        </Box>
                                    ))
                                }
                                <SelectableLabelInput id="timeseriesLineNew" options={allParcels} selectLabel={t("Line Parcel")}
                                    handleAction={(a, s, l) => {
                                        handleSelectableLabel("timeseries", "lines", -1, a, s, l)
                                    }} />
                            </Grid>
                            <Grid item xs={12}>
                                {
                                    state.timeseries.bars.map((element, idx) => (
                                        <Box key={`timeseriesBarBox${idx}`} sx={{marginBottom: theme.spacing(2)}}>
                                            <SelectableLabelInput
                                                state={element}
                                                id={`timeseriesBar${idx}`}
                                                options={allParcels} selectLabel={t("Bar Parcel")}
                                                handleAction={(a, s, l) => {
                                                    handleSelectableLabel("timeseries", "bars", idx, a, s, l)
                                                }} />
                                        </Box>
                                    ))
                                }
                                <SelectableLabelInput id="timeseriesBarNew" options={allParcels} selectLabel={t("Bar Parcel")}
                                    handleAction={(a, s, l) => {
                                        handleSelectableLabel("timeseries", "bars", -1, a, s, l)
                                    }} />
                            </Grid>
                        </React.Fragment>
                    ) : null
                }

                {
                    state.generic.type === "TRANSACTIONS" ? (
                        <Grid item xs={12}>
                            <Select options={[{id: "card", label: t("Cards")}, {id: "table", label: t("Table")}]}
                                id="transactionsRecordType" selected={state.transactions.recordType} label={t("Transaction Widget")}
                                onChange={(e, v) => handleSingleSelect("transactions", "recordType", e, v)} />
                        </Grid>
                    ) : null
                }

            </Grid>
        </GenericDialog>
    );
}

export default WidgetFormDialog;
