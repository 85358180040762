import React from "react";
import { connect } from "react-redux";
import { useTranslation, getI18n } from "react-i18next";
import { apiEntityGet } from "../../api/entity";
import { updateEntity } from "../../store/actions/entity";
import DimensionPage from "../../layout/DimensionPage";

const EntityView = (props) => {
    const { t } = useTranslation(["dimension"]);
    const { updateMethod } = props;
    return (
        <DimensionPage dimension="entity" title={t("Entity")} instanceId={props.match.params.id}
            getMethod={apiEntityGet} dataKey="entities" updateMethod={updateMethod}
            spec={
                [
                    {id: "name", required: true, label: t("Name"), type: "text", value: null},
                    {id: "identifier", required: false, label: t("Identifier"), type: "text", value: null},
                    {id: "description", required: false, label: t("Description"), type: "area", value: null},
                ]
            }
        />
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateMethod: ({ id, name, description, identifier }, callback) => dispatch(updateEntity({ id, name, description, identifier }, callback)),
    }
}

export default connect(null, mapDispatchToProps)(EntityView);
