import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { DialogContentText } from "@mui/material";

import CancelButton from "../../components/input/button/CancelButton";
import SubmitButton from "../../components/input/button/SubmitButton";
import FormTextInput from "../../components/input/text/FormTextInput";
import FormTextAreaInput from "../input/text/FormTextArea";

export const DimDialog = (props) => {

    const { stateName, stateDescription, modalCloseCallback, formOpen, formType, showDescription = true } = props;
    const { titleEdit, titleCreate, titleDelete, dialogDeleteText, isMobile } = props;
    const { t } = useTranslation(["dim"]);

    const [state, setState] = React.useState({
        name: "",
        description: "",
    });

    useEffect(() => {
        setState({
            name: stateName,
            description: stateDescription ?? "",
        });
    }, [formOpen, stateName, stateDescription]);

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.id]: e.target.value
        });
    }

    const handleCancel = (e) => {
        modalCloseCallback({ action: "cancel" });
    }

    const handleSubmit = (e) => {
        modalCloseCallback({ action: "submit", state: state });
    }

    const formTitle = () => {
        switch (formType) {
            case "edit":
                return titleEdit;
            case "create":
                return titleCreate;
            case "delete":
                return titleDelete;
            default:
                return "";
        }
    }

    const submitText = () => {
        switch (formType) {
            case "edit":
                return t("Update");
            case "create":
                return t("Create");
            case "delete":
                return t("Delete");
            default:
                return "";
        }
    }

    return (
        <Dialog open={formOpen} fullWidth={true} fullScreen={isMobile}>
            <DialogTitle>{formTitle()}</DialogTitle>
            <DialogContent>
                {
                    formType === "delete"
                    ? (
                        <DialogContentText>{dialogDeleteText}</DialogContentText>
                    )
                    : null
                }
                <FormTextInput
                    required={true}
                    id="name"
                    label={t("Name")}
                    disabled={formType === "delete"}
                    onChange={handleChange}
                    value={state.name}
                />
                {
                    showDescription ? (
                        <FormTextAreaInput
                            id="description"
                            label={t("Description")}
                            disabled={formType === "delete"}
                            name="description"
                            onChange={handleChange}
                            value={state.description}
                        />
                    ) : null
                }
            </DialogContent>
            <DialogActions>
                <CancelButton onClick={handleCancel}>{t("Cancel")}</CancelButton>
                <SubmitButton onClick={handleSubmit}>{submitText()}</SubmitButton>
            </DialogActions>
        </Dialog>
    );
}
