import React from "react";
import { useTranslation } from "react-i18next";
import GenericDialog from "./GenericDialog";

const DeleteDialog = (props) => {

    const { t } = useTranslation(["misc"]);
    const { open, actionMethod, title = t("Delete"), text } = props;

    const stateAction = (action) => {
        actionMethod(action);
    }

    return (
        <GenericDialog
            open={open}
            title={title}
            text={text}
            cancelLabel={t("Cancel")}
            submitLabel={t("Delete")}
            actionMethod={stateAction} />
    );
}

export default DeleteDialog;
