import React from "react";
import TablePagination from '@mui/material/TablePagination';

const Pagination = (props) => {

    const { rowCount, page, pageSize, onPageChange, onPageSizeChange, rowsPerPageOptions, labelRowsPerPage } = props;
    return (
        <React.Fragment>
            {
                rowCount > 0
                ? (
                    <TablePagination component="div" count={rowCount} page={page} onPageChange={onPageChange}
                        rowsPerPage={pageSize} onRowsPerPageChange={onPageSizeChange} rowsPerPageOptions={rowsPerPageOptions}
                        labelRowsPerPage={labelRowsPerPage} />
                ) : null
            }
        </React.Fragment>
    );
}

export default Pagination;
  