import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import TransactionCollection from "../collection/TransactionCollection";
import PageMainItem from "../typography/PageMainItem";
import { Alert } from "@mui/material";
import PieWidget from "./PieWidget";
import AmountCardCollection from "../collection/AmountCardCollection";
import TotalsCard from "../card/TotalsCard";
import SeasonalWidget from "./SeasonalWidget";
import BarChartWidget from "./BarChartWidget";
import SummaryTableWidget from "./SummaryTableWidget";
import { unpackLabeledParcel } from "../../helpers/format";
import TimeseriesWidget from "./TimeseriesWidget";

const WidgetContainer = (props) => {
    const theme = useTheme();
    const { t } = useTranslation(["widget"]);

    const { isMobile } = props;
    const { title, type, primaryFilter, secondaryFilter, params, height } = props;

    const returnWidget = () => {

        if (type === "BAR") {
            const parcels = params.parcels.map(element => { return {...unpackLabeledParcel(element), isCurrency: true}});
            return <BarChartWidget parcels={parcels} height={height} dimension={params.dimension} items={params.items}
                sortParcel={params.sortParcel} sortDirection={params.sortDirection}
                primaryFilter={primaryFilter.data} secondaryFilter={secondaryFilter ? secondaryFilter.data : null}
                primaryLabel={primaryFilter.name} secondaryLabel={secondaryFilter ? secondaryFilter.name : null} />
        }

        if (type === "LIST") {
            return <AmountCardCollection dimension={params.dimension} filter={primaryFilter.data} height={height}
                parcel={params.parcel} labelTotal={params.labelTotal} columns={isMobile ? 1 : params.columns} />
        }

        if (type === "PERIODIC") {
            return <SummaryTableWidget parcel={params.parcel} frequency={params.frequency} height={height}
                hasProduct={params.hasProduct} hasCategory={params.hasCategory} hasItem={params.hasItem}
                hasEntity={params.hasEntity} filter={primaryFilter.data} />
        }

        if (type === "PIE") {
            return <PieWidget filter={primaryFilter.data} dimension={params.dimension}
                transactionType={params.transactionType} items={params.items} height={height} />
        }
    
        if (type === "SEASONAL") {
            return <SeasonalWidget parcel={params.parcel} filter={primaryFilter.data}
                numYears={params.numYears} height={height} />
        }

        if (type === "TIMESERIES") {
            const lines = params.lines.map(element => { return {...unpackLabeledParcel(element), isCurrency: true} });
            const bars = params.bars.map(element => { return {...unpackLabeledParcel(element), isCurrency: true} });
            return <TimeseriesWidget lines={lines} bars={bars} frequency={params.frequency} height={height}
                primaryFilter={primaryFilter.data} secondaryFilter={secondaryFilter ? secondaryFilter.data : null} 
                primaryLabel={primaryFilter.name} secondaryLabel={secondaryFilter ? secondaryFilter.name : null} />
        }

        if (type === "TOTALS") {
            const totalsParcels = params.parcels.map(element => {
                return {...unpackLabeledParcel(element), isCurrency: true}
            });
            const itemSize = isMobile ? 12 : (
                params.columns === 4 ? 3 : (
                    params.columns === 3 ? 4 : (
                        params.columns === 2 ? 6 : 12
                    )
                )
            )
            return <TotalsCard parcels={totalsParcels} mainFilter={primaryFilter.data}
                secondaryFilter={secondaryFilter ? secondaryFilter.data : null}
                itemSize={isMobile ? 12 : itemSize} height={height} />
        }

        if (type === "TRANSACTIONS") {
            return (
                <TransactionCollection {...primaryFilter.data} height={height} />
            )
        }

        return null;
    }

    return (
        <React.Fragment>
            {
                title ? (
                    <Box sx={{marginBottom: theme.spacing(1)}}>
                        <PageMainItem text={title} />
                    </Box>
                ) : null
            }
            {
                returnWidget() ?? (
                    <Alert severity="info">{t("Widget settings are not valid! Please check your widget parameters.")}</Alert>
                )
            }
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        isMobile: state.appSettings.isMobile,
    }
}

export default connect(mapStateToProps)(WidgetContainer);
