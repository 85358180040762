// Button component
import React from "react";
// import { PropTypes } from "prop-types";
import Button from "./Button";

const SubmitButton = (props) => {
    const {
        onClick,
        children,
        marginTop = 0,
        marginLeft = 0,
        marginRight = 0,
        marginBottom = 0,
        fullWidth = false,
        disabled = false,
    } = props;

    return (
        <Button
            variant="contained"
            color="primary"
            onClick={onClick}
            marginTop={marginTop}
            marginLeft={marginLeft}
            marginRight={marginRight}
            marginBottom={marginBottom}
            fullWidth={fullWidth}
            disabled={disabled}
        >
            {children}
        </Button>
    )
}

export default SubmitButton;
