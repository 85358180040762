import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { useTheme } from "@mui/material/styles";

import { Line } from "recharts";
import { XAxis } from "recharts";
import { YAxis } from "recharts";
import { Brush } from "recharts";
import { CartesianGrid } from "recharts";
import { Tooltip } from "recharts";
import { ResponsiveContainer } from "recharts";
import { ComposedChart } from 'recharts';
import { Bar } from 'recharts';

import { mergeArrays } from "../../helpers/utils";
import { sequentialPallete } from "../../helpers/colors";
import { apiDataseries } from "../../api/aggregations";
import { Box, Slider } from "@mui/material";
import { unpackLabeledParcel, formatNumberAmount } from "../../helpers/format";


const TimeseriesWidget = (props) => {
    const { t } = useTranslation(["widget"]);
    const theme = useTheme();

    const { frequency, bars = [], lines = [], primaryFilter, secondaryFilter, primaryLabel, secondaryLabel, height } = props;

    const [widgetData, setWidgetData] = React.useState([]);
    const [widgetParcels, setWidgetParcels] = React.useState([]);

    const [startIndex, setStartIndex] = React.useState(undefined);
    const [endIndex, setEndIndex] = React.useState(undefined);

    const convertKey = (filterLabel, parcelLabel) => {
        return filterLabel ? `${filterLabel} - ${parcelLabel}` : parcelLabel;
    }

    React.useEffect(() => {

        const getParcels = (list, spec, filterLabel) => {
            return list.map(element => {
                let newElement = {
                    formatDate: element.formatDate,
                }
                spec.forEach(parcel => {
                    const lbl = convertKey(filterLabel, parcel.label);
                    newElement[lbl] = element[parcel.key];
                });
                return newElement;
            })
        }

        const doRequest = (filter) => {

            return new Promise((resolve, reject) => {
                apiDataseries(
                    {...filter, dateAggrType: frequency}, (data, successFlag) => {
                        if (successFlag > 0) {
    
                            const dateSpec = {
                                ymd: {momentToStr: "YYYY-MM-DD", momentFreq: "D", momentEnd: "day"},
                                ym: {momentToStr: "YYYY-MM", momentFreq: "M", momentEnd: "month"},
                                y: {momentToStr: "YYYY", momentFreq: "Y", momentEnd: "year"},
                            };
                            const end = dateSpec[frequency].momentEnd;
                            const strFormat = dateSpec[frequency].momentToStr;
    
                            const dataseries = data.dataseries.map(element => {
                                return {
                                    ...element,
                                    formatDate: (
                                        frequency === "ymd"
                                        ? moment(element.date)
                                        : (
                                            frequency === "ym"
                                            ? moment([element.year, element.month - 1, 1])
                                            : moment([element.year, 0, 1])
                                        ).endOf(end)
                                    ).format(strFormat),
                                }
                            });
                            return resolve(dataseries);
                        }
                        return reject(successFlag);
                    }
                )
            });
        }

        var promises = [];
        promises.push(doRequest(primaryFilter));
        if (secondaryFilter) {
            promises.push(doRequest(secondaryFilter));
        }

        Promise.all(promises).then((result) => {

            const parcels = lines.map(element => { return {...element, type: "line"} }).concat(
                bars.map(element => { return {...element, type: "bar"} })
            );
            setWidgetParcels(parcels);

            // get results from promises (main and secondary)
            const primaryData = getParcels(result[0], parcels, secondaryFilter ? primaryLabel : null);
            const secondaryData = result.length > 1 ? getParcels(result[1], parcels, secondaryLabel) : null;

            const mrgData = (
                secondaryData
                ? mergeArrays(primaryData, secondaryData, (a, b) => a.formatDate === b.formatDate, true)
                : primaryData.slice()
            );
            mrgData.sort((a, b) => a.formatDate > b.formatDate ? 1 : (a.formatDate < b.formatDate ? -1 : 0));
            setWidgetData(mrgData);

            const allDates = mrgData.map((element, idx) => ({label: "", value: idx, date: element.formatDate}));
            const lastValue = {ymd: moment().format("YYYY-MM-DD"), ym: moment().format("YYYY-MM"), y: moment().format("YYYY")};
            const idxFilter = allDates.map((element, idx) => {
                if (element.date <= lastValue[frequency]) { return idx; }
                return -1;
            });
            const lastIdx = idxFilter.length > 0 ? Math.max(...idxFilter) : allDates.length - 1;
            const maxBackBrush = frequency === "ymd" ? 31 : (frequency === "ym" ? 13 : (frequency === "y" ? 10 : allDates.length));
            setStartIndex(allDates[Math.max(lastIdx - maxBackBrush, 0)].value);
            setEndIndex(allDates[lastIdx].value);

        }, (err) => {
            console.log(err);
        });
    }, [
        primaryFilter,
        secondaryFilter,
        bars,
        lines,
        frequency,
        primaryLabel,
        secondaryLabel,
    ]);

    const filterListIterator = secondaryFilter ? [primaryLabel, secondaryLabel] : [primaryLabel];

    return (
        <ResponsiveContainer width="100%" height={height}>
            <ComposedChart data={widgetData} margin={{ top: 10, right: 0, bottom: 10, left: 0 }}>
                <CartesianGrid strokeDasharray="3 3"  />
                <XAxis dataKey="formatDate" />
                <YAxis yAxisId="yline" orientation="right" tickCount={5} />
                <YAxis yAxisId="ybar" orientation="left" tickCount={5} />
                <Tooltip formatter={(value, name, props) => formatNumberAmount(value)} />
                {
                    widgetParcels.map((element, idx) => {

                        return filterListIterator.map((filterLabel, idxFilter) => {

                            const color = sequentialPallete(idx, filterLabel === secondaryLabel);
                            const dataKey = convertKey(secondaryLabel ? filterLabel : null, element.label);

                            if (element.type === "bar") {
                                return (
                                    <Bar
                                        yAxisId="ybar"
                                        dataKey={dataKey}
                                        barSize={30}
                                        fill={color}
                                        key={`bar-primary-${idx}-${idxFilter}`} />
                                )
                            }

                            if (element.type === "line") {
                                return (
                                    <Line
                                        yAxisId="yline"
                                        type="monotone"
                                        dataKey={dataKey}
                                        stroke={color}
                                        activeDot={{ r: 3 }}
                                        strokeWidth={3}
                                        key={`line-${idx}-${idxFilter}`} />
                                )
                            }

                            return null;
                        })
                    })
                }
                <Brush startIndex={startIndex} endIndex={endIndex} dataKey="formatDate" />
            </ComposedChart>
        </ResponsiveContainer>
    )
}

export default TimeseriesWidget;
