import React from "react";

import ActionButtonMenuSort from "../input/button/ActionButtonMenuSort";
import ActionButtonMenuDownload from "../input/button/ActionButtonMenuDownload";
import ActionButtonMenuAdd from "../input/button/ActionButtonMenuAdd";
import ActionButtonMenuTransactionSelection from "../input/button/ActionButtonMenuTransactionSelection";
import ButtonGroupWrapper from "./ButtonGroupWrapper";

const TransactionCollectionActions = (props) => {

    const { onSortChange, sortMenuItems, onDownloadClick, onSelectionMenuItemClick, selected, allSelected } = props;

    return (
        <ButtonGroupWrapper>
            <ActionButtonMenuSort
                handleMenuClick={onSortChange}
                menuItems={sortMenuItems} />
            <ActionButtonMenuTransactionSelection
                handleMenuClick={onSelectionMenuItemClick}
                selected={selected}
                allSelected={allSelected} />
            <ActionButtonMenuDownload onClick={onDownloadClick} />
            <ActionButtonMenuAdd href="/transaction/edit/0" />
        </ButtonGroupWrapper>
    );
}

export default TransactionCollectionActions;
