import React from "react";
import { faCreditCard } from "@fortawesome/free-regular-svg-icons";
import DimensionChip from "./DimensionChip";
import { ProductChipColor } from "../../helpers/colors";

const ProductChip = (props) => {

    const { label, productId, clickable = true } = props;

    const chipUrlParam = clickable ? {
        href: `/product/view/${productId}`,
    } : null;

    return (
        <DimensionChip
            faIcon={faCreditCard}
            label={label}
            textColor={ProductChipColor.textColor}
            regularColor={ProductChipColor.regularColor}
            hoverColor={ProductChipColor.onHoverColor}
            {...chipUrlParam}
        />
    );
}

export default ProductChip;
