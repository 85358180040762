import { axiosDelete, axiosGet, axiosPost, axiosPut } from "./instance";

export const apiGroupList = ({ search }, callback) => {
    axiosGet("group/list", { search: search }, callback);
}

export const apiGroupCreate = ({ name, description }, callback) => {
    axiosPost("group/create", { name, description }, callback);
}

export const apiGroupGet = ({ id }, callback) => {
    axiosGet("group/" + id, {}, callback);
}

export const apiGroupUpdate = ({ id, name, description }, callback) => {
    axiosPut("group/" + id, { name, description }, callback);
}

export const apiGroupDelete = ({ id }, callback) => {
    axiosDelete("group/" + id, callback);
}

export const apiGroupUp = ({ id }, callback) => {
    axiosPut("group/" + id + "/up", {}, callback);
}

export const apiGroupDown = ({ id }, callback) => {
    axiosPut("group/" + id + "/down", {}, callback);
}
