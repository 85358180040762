import React from "react";
import Chip from "@mui/material/Chip";
import Link from "@mui/material/Link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DimensionChip = (props) => {

    const { faIcon, label, href, textColor, chipColor, regularColor, hoverColor, onClick, variant = "filled" } = props;
    const clickable = Boolean(href) || Boolean(onClick);
    const component = href ? Link : undefined;

    const hoverSx = clickable ? (
        {
            ":hover": {
                backgroundColor: hoverColor,
                color: textColor
            }
        }
    ) : {};
    const sx = (regularColor && textColor) ? {
        backgroundColor: regularColor,
        color: textColor,
        ...hoverSx
    } : undefined;

    const icon = faIcon ? <FontAwesomeIcon icon={faIcon} color={textColor} /> : null;

    return (
        <Chip
            variant={variant}
            icon={icon}
            sx={sx}
            label={label}
            component={component}
            href={href}
            clickable={clickable}
            onClick={onClick}
            color={chipColor}
        />
    );
}

export default DimensionChip;
