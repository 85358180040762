import { camelToSnake } from "./utils";

export const redirectUrlDownload = (url, fileName) => {
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

export const arrayToCsv = (headers, data, separator = ",") => {
    const csvRows = [];
    csvRows.push(headers.join(separator));
    data.map(entry => {
        const row = headers.map(h => {
            if (typeof entry[h] === "string") {
                return `"${entry[h].replace("\"", "'")}"`;
            }
            return entry[h];
        });
        csvRows.push(row.join(separator));
    })
    return csvRows.join("\r\n");
}

export const downloadRecords = (records, columns, filename) => {
    const data = records.map(entry => {
        return columns.reduce((obj, column) => {
            obj[column] = entry[camelToSnake(column)];
            return obj;
        }, {});
    })
    const csvContent = arrayToCsv(columns, data);
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    redirectUrlDownload(url, `${filename}.csv`);
}

export const downloadTransactions = (transactions) => {
    const csvContent = arrayToCsv(
        [
            "id",
            "date",
            "signal",
            "value",
            "type",
            "product",
            "product_description",
            "category",
            "category_description",
            "item",
            "item_description",
            "entity",
            "entity_description",
            "amount_type",
            "tags",
            "description",
        ],
        transactions.map((entry) => {
            return {
                id: entry.id,
                date: entry.date,
                signal: entry.transactionType,
                value: entry.value,
                type: entry.productType,
                product: entry.productName,
                product_description: entry.productDescription,
                category: entry.categoryName,
                category_description: entry.categoryDescription,
                item: entry.itemName,
                item_description: entry.itemDescription,
                entity: entry.entityName,
                entity_description: entry.entityDescription,
                amount_type: entry.amountType,
                tags: entry.tags.map(tag => tag.tagName).join("|"),
                files: entry.files.map(f => f.url).join(" | "),
                description: entry.description,
            }
        })
    );
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    redirectUrlDownload(url, "walletify.csv")
}


export const downloadDimensions = (records, filename) => {
    const csvContent = arrayToCsv(
        [
            "id",
            "name",
            "description",
            "balance",
        ],
        records.map((entry) => {
            return {
                id: entry.id,
                name: entry.name,
                description: entry.description,
                balance: entry.balance,
            }
        })
    );
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    redirectUrlDownload(url, `${filename}.csv`)
}

export const downloadProducts = (records) => {
    downloadRecords(
        records,
        [
            "name",
            "description",
            "productGroupName",
            "productType",
            "balance",
            "interests",
            "updates",
            "identifier",
        ],
        "products.csv"
    )
}
